import Select from "react-select";

const SelectBrand = ({
  allStates,
  defaultState,
  setDefaultState,
  setIsSubmit,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#3E5056" : "#3E5056",
      backgroundColor: "#1C2427",
      fontSize: "var(--regular-font-size)",
      boxShadow: "none",

      "&:hover": {
        borderColor: "#3E5056", // Change to the desired hover color
      },

      height: "26px",
      width: "200px",
      "min-height": "26px",
      // display: "flex",
      // alignItems: "center"
    }),

    menu: (provided) => ({
      ...provided,
      //   width: 400,
      borderRadius: 8,
      backgroundColor: "#3e5056",
      color: "white",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      marginTop: -6,
    }),

    valueContainer: (provided) => ({
      ...provided,
      // background: "blue", // Example background color
      // color: "red", // Example text color
      padding: "0 6px", // Example padding
      height: "22px",
      // display: "flex",
      // alignItems: "center"
      marginTop: -10,
    }),

    option: (provided, state) => ({
      ...provided,
      //   width: 400,
      backgroundColor: state.isSelected ? "#d6ff41" : "#3e5056",
      color: state.isSelected ? "black" : "white",
      // Custom style for disabled option
      opacity: state.isDisabled ? 0.7 : 1,
      cursor: state.isDisabled ? "not-allowed" : "default",
      ":hover": {
        backgroundColor: "#334155", // Change to the desired hover color
      },
      fontSize: "var(--regular-font-size)",
      // height: "25px",
      display: "flex",
      alignItems: "center",
    }),

    singleValue: (base) => ({
      ...base,
      background: "#1C2427",
      // background: "red",
      color: "white",
      // display: "flex",
      // marginTop: -10,
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
      display: "flex",
      // marginTop: -7,
    }),
  };

  return (
    <>
      <Select
        options={allStates}
        value={allStates.find((option) => option.value === defaultState)}
        isSearchable
        styles={customStyles}
        onChange={(selelctedState) => {
          setDefaultState(selelctedState.value);
          setIsSubmit(false);
        }}
      />
    </>
  );
};

export default SelectBrand;
