import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


const RHistogram = ({ data, setClickedCatName }) => {
  useEffect(() => {
    // Provided data
    const providedData = data;

    // Create root element

    const root = am5.Root.new("chartdiv2");

    root._logo.dispose();

    // Set themes
    root.setThemes([
      am5themes_Animated.default.new(root), // Instantiate the theme object
    ]);

    // Create chart
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        innerRadius: am5.percent(20),
        startAngle: 0,
        endAngle: 360,
        tooltip: am5.Tooltip.new(root, {
          getFillFromSprite: false,
          autoTextColor: false,
          background: am5.Rectangle.new(root, {
            fill: am5.color(0x000000), // Set the background color to black
          }),
        }),
      })
    );

    chart.zoomOutButton.setAll({
      background: am5.RoundedRectangle.new(root, {
        fill: am5.color("#d6ff41"), // Set background color to yellow
      }),
      icon: am5.Graphics.new(root, {
        stroke: am5.color(0x000000), // Set icon color to black
        svgPath: "M5 15 H20", // Custom SVG path for minus icon (as an example)
      }),
    });
    // chart.zoomOutButton.set("forceHidden", true);

    // Add cursor
    const cursor = chart.set(
      "cursor",
      am5radar.RadarCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);


    // Create axes
    const xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 30,
    });
    xRenderer.grid.template.set("forceHidden", true);

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
      })
    );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {});
    yRenderer.grid.template.setAll({
      stroke: am5.color("#FFFFFF"),
      fill: am5.color("#000000"),
      strokeWidth: 2,
    });
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
        forceHidden: true,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 15,
      cursorOverStyle: "pointer",
      fill: am5.color("#ffffff"), // Set label color to red
    });

    // Add series
    const series = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
      })
    );

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadius: 5,
      tooltipText: "{categoryX}: {valueY} {units}",
      tooltip: am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0x000000), // Set the background color to black
        }),
      }),
    });


    
    

    // Make each column to be of a different color
    // series.columns.template.adapters.add("fill", function (fill, target) {
    //   return chart.get("colors").getIndex(series.columns.indexOf(target));
    // });

    // series.columns.template.adapters.add("stroke", function (stroke, target) {
    //   return chart.get("colors").getIndex(series.columns.indexOf(target));
    // });

    series.columns.template.adapters.add("fill", function (fill, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        return am5.color(dataItem.dataContext.color);
      }
      return fill;
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        return am5.color(dataItem.dataContext.color);
      }
      return stroke;
    });

    // Set data
    xAxis.data.setAll(providedData);
    series.data.setAll(providedData);

    // Add click event listener to columns
    series.columns.template.events.once("click", function (ev) {
      const category = ev.target._dataItem.dataContext.category;
      const value = ev.target._dataItem.dataContext.value;

      console.log("category name", category, ",value", value);
      // alert("Clicked on category: " + category);
      // setClickedCatName(category);
    });

    

    // Make stuff animate on load
    series.appear(1000);
    chart.appear(1000, 100);

    // Clean up function
    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div
      id="chartdiv2"
      style={{ width: "100%", height: "500px", backgroundColor: "#1c2427" }}
    ></div>
  );
};

export default RHistogram;
