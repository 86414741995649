import React from 'react'

const Channel = (props) => {


    const GetRowSpan = (ListData, Level) => {
        let count = 0
        for (var i = 0; i < ListData.length; i++) {
            const MyLevel = Level + "|" + ListData[i].mykey
            if (props.Levels.includes(MyLevel)) {
                count += GetRowSpan(ListData[i].subchannel, MyLevel)
            }
            count += 1
        }
        return count
    }

    const getSuggestedDiff = (item, ele) => {
        const sugg = ele.spends;
        const prev = item.spends;
        const diff = sugg - prev;
        const color = (diff > 0) ? '#03ff03' : 'red';
        const per = (diff === 0 || prev === 0) ? 0 : (diff / prev) * 100;

        return (
            <span style={(per !== 0) ? { color: color } : {}}>
                {per.toFixed(1)}% ({diff.toFixed(1)})
            </span >
        );
    }

    const GetNewFixedDataIndex = (item, NewFixedData) => {
        for (var i = 0; i < NewFixedData.length; i++) {
            if (NewFixedData[i].mykey === item.mykey) {
                return NewFixedData[i]
            }
        }
        return ''
    }

    const GetNewDataIndex = (item, NewData) => {
        for (var i = 0; i < NewData.length; i++) {
            if (NewData[i].mykey === item.mykey) {
                return NewData[i]
            }
        }
        return ''
    }

    const GetChannelData = (ListData, NewFixedData, NewData, Level) => {
        
        return ListData.map((item, index) => {
            // console.log("item.mykey: ", item.mykey);
            const MyLevel = Level + "|" + item.mykey

            const NewFixedDataLevel = GetNewFixedDataIndex(item, NewFixedData)
            // console.log("NewFixedDataLevel: ", NewFixedDataLevel);

            const NewDataLevel = GetNewDataIndex(item, NewData);
            // console.log("NewDataLevel: ", NewDataLevel);
            const diff = getSuggestedDiff(item, NewFixedDataLevel)
            // console.log(NewFixedDataLevel)
            // console.log(item)
             const colorList = [
                "rgba(38,50,56, 0)", 
                "rgba(40,60,80, 0.8)", 
                "rgba(60,80,100, 0.2)",
                "rgba(38,50,56, 0.3)",
                "rgba(38,50,56, 0.4)",
                "rgba(38,50,56, 0.5)",
                "rgba(38,50,56, 0.6)",
                "rgba(38,50,56, 0.7)",
                "rgba(38,50,56, 0.8)",
                "rgba(38,50,56, 0.9)",
            ]
            const colors = localStorage.getItem("CurrentTheme") === "Dark" ?
                ["transparent",
                    "#263238",
                    "#37474F",
                    "#455A64",
                    "#546E7A",
                    "#607D8B",
                    "#78909C",
                    "#90A4AE",
                    '#B0BEC5'] :
                    // colorList :
                ["transparent",
                    '#FFFFFF',
                    '#F0F0F0',
                    '#EAEAEA',
                    '#D3D3D3',
                    '#C0C0C0',
                    '#A9A9A9',
                    '#808080',
                    '#696969']
            return (
                <React.Fragment key={index}>
                    {/* <tr className={Level.includes("|") ? "sub-table" : ""}> */}
                    <tr style={{ backgroundColor: colors[MyLevel.split("|").length - 2]}}>
                        {
                            !Level.includes("|") && index === 0 ? <td
                                className="digital-title"
                                rowSpan={GetRowSpan(props.Summary.channels[props.channel], props.channel)}
                            >
                                <p style={{ borderRight: '1px solid ' + props.Summary.channels[props.channel][0].color, color: props.Summary.channels[props.channel][0].color }}><span>{props.channel}</span></p>
                            </td> : null
                        }
                        <td className="text-center">
                            {
                                ('subchannel' in item) ? !props.Levels.includes(MyLevel) ? <span className="text-white" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => props.AddLevel(MyLevel)}>+</span> : <span className="text-white" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => props.RemoveLevel(MyLevel)}>-</span> : null
                            }
                        </td>
                        <td className="text-left" style={{ color: item.color, opacity: (!props.AllowEdit) ? 1 : 0.7, pointerEvents: (!props.AllowEdit) ? 'all' : 'none' }}>
                            {item.name}
                        </td>
                        <td className="text-center text-white" style={{ opacity: (!props.AllowEdit) ? 1 : 0.7, pointerEvents: (!props.AllowEdit) ? 'all' : 'none' }}>
                            {item.spends}
                        </td>
                        <td className="text-center" style={{ opacity: (!props.AllowEdit) ? 1 : 0.7, pointerEvents: (!props.AllowEdit) ? 'all' : 'none' }}>
                            <span>{diff}</span>
                        </td>
                        <td className="text-center text-white" style={{ color: "var(--bs-theme-color)", opacity: (!props.AllowEdit) ? 1 : 0.7, pointerEvents: (!props.AllowEdit) ? 'all' : 'none' }}>
                            {NewFixedDataLevel.spends}
                        </td>
                        {/* input */}
                        <td className="text-center d-flex" style={{ opacity: (props.AllowEdit) ? 1 : 0.7, pointerEvents: (props.AllowEdit) && NewFixedDataLevel.fillflag ? 'all' : 'none' }}>
                            {
                            //   console.log(props.flattened[MyLevel.split("|").pop()][7])
                            }
                            {
                                NewDataLevel.spends !== NewFixedDataLevel.spends ? <span className="mx-2 cursor-pointer primary-text" onClick={() => {
                                    // console.log("key while refresh: ", NewDataLevel.mykey);
                                    props.setUserInputKey(NewDataLevel.mykey);
                                    props.traverse(MyLevel, 0, false);
                                    props.EditNewSpends(MyLevel, NewFixedDataLevel.spends);
                                    props.setIsExecuted(true);
                                }}>&#x21bb;</span> : <span className="mx-2">&nbsp;&nbsp;</span>
                            }

                            <input type="text" className={NewDataLevel.spends !== NewFixedDataLevel.spends ? "m-0 edit-spends primary-text input-active" : "m-0 edit-spends primary-text"}
                                value={NewDataLevel.spends}
                                // value={props.flattened[MyLevel.split("|").pop()][7]}
                                onChange={(e) => {
                                    // console.log(e.target.value)
                                    // console.log(MyLevel)
                                    // console.log(props.NewFixedData.channels)
                                    // props.EditNewSpends(props.channel, ChannelData.index, e.target.value);
                                    props.EditNewSpends(MyLevel, e.target.value);
                                    
                                }}
                                onBlur={(e) => {
                                    // props.EditNewSpendsOnBlur(props.channel, ChannelData.index, e.target.value);
                                    if(NewDataLevel.spends !== parseFloat(e.target.value)) {
                                        props.EditNewSpendsOnBlur(MyLevel, parseFloat(e.target.value));
                                    }
                                    
                                }}
                            />
                        </td>
                        {/* range input */}
                        <td style={{ opacity: (props.AllowEdit) ? 1 : 0.5, pointerEvents: (props.AllowEdit) && NewFixedDataLevel.fillflag ? 'all' : 'none', minWidth: '200px' }}>
                            <div className="w-100">
                                <input
                                    type="range"
                                    className="form-range"
                                    min={NewDataLevel.min_val}
                                    max={NewDataLevel.max_val}
                                    step={0.1}
                                    value={NewDataLevel.spends}
                                    onChange={(e) => {
                                        // props.EditNewSpends(props.channel, ChannelData.index, e.target.value);
                                        props.EditNewSpends(MyLevel, e.target.value);
                                        // console.log("item.mykey: ", item.mykey);
                                        // console.log("Channel Type: ", Level);
                                        
                                    }}

                                    onBlur={(e) => {
                                        // props.EditNewSpendsOnBlur(props.channel, ChannelData.index, e.target.value);
                                        props.EditNewSpendsOnBlur(MyLevel, parseFloat(e.target.value));
                                        console.log("item.fillflag: ", item.mykey, item.fillflag);
                                        
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="m-0" style={{ fontSize: "11px" }}>{NewDataLevel.min_val}</p>
                                    <p className="m-0" style={{ fontSize: "11px" }}>{NewDataLevel.max_val}</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {
                        ('subchannel' in item && props.Levels.includes(MyLevel)) ? GetChannelData(item.subchannel, NewFixedDataLevel.subchannel, NewDataLevel.subchannel, MyLevel) : null
                    }
                </React.Fragment>
            )
        })

    }

    return (
        <>{GetChannelData(props.Summary.channels[props.channel], props.NewFixedData.channels[props.channel], props.NewData.channels[props.channel], props.channel)}</>
    )
}

export default Channel