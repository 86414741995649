import React from "react";

import configDriven from "../configDriven";

const Piechart = (props) => {
   
    const getHeight = (AllocationData, y) => {
        var Total = 0
        for (var i = 0; i < AllocationData.length; i++) {
            Total += AllocationData[i].y
        }
      
        return y / Total * 100
    }

    function getFormattedString(id, obj) {
        // console.log("id: ", id);
        if (configDriven.show_display_name) {
          // Split the id by hyphens
          const keys = id.split("-");
    
          // Map the keys to their corresponding values in the object
          const values = keys.map((key) => obj[key]);
    
          // Join the values with hyphens and return the result
          return values.join("-");
        } else {
          return id;
        }
      }
    
      const displayNamesChart = JSON.parse(
        localStorage.getItem("displayNamesChart")
      );


    return (
        <>
            <div className="gd text-center mt-4 px-4" style={{ height: "90%"}}>
                <div className="bar-chart d-flex justify-content-center row" style={{ height: "100%"}}>
                    <div className="col-sm-2" style={{height: "100%"}}>
                        <div className="custom-allcation-layout" style={{height: "100%"}} >
                            {
                                props.Channel.map((item, index) => {
                                    return (
                                        <div
                                            className="channel"
                                            key={index}
                                            title={item.name}
                                            style={{
                                                height: getHeight(props.Channel, item.y) + '%',
                                                background: item.color
                                            }}
                                            onMouseEnter={() => {
                                                // console.log(item);
                                            }}
                                        ><span></span></div>
                                    )
                                })
                            }

                        </div>

                    </div>
                    <div className="col-sm-10" style={{height: "100%"}}>
                        <div className="custom-allcation-layout gd-bar-lable w-100" style={{height: "100%"}}>
                            {
                                props.Channel.map((item, index) => {
                                    return (
                                        <div
                                            className="channel"
                                            key={index}
                                            title={item.name}
                                            style={{
                                                height: getHeight(props.Channel, item.y) + '%',
                                            }}

                                        ><h6 className="m-0" >{parseFloat(item.y).toFixed(2)+ "%"} : {getFormattedString(item.name, displayNamesChart)}</h6></div>
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default Piechart;
