import React, { useEffect, useState, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import ItemWise from "./ItemWise";
import AggregateTotalView from "./AggregateTotalView";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from "../../images/download-icon.png";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import AllHeirarchyFilter from "../AllHeirarchyFilter";
import AllCampaignFilter from "../AllCampaignFilter";
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../../images/icons8-info.svg'
import axiosChartDownload from "../../axiosChartDownload";

import LightThemeHexCode from "../LightThemeHexCode";
import downloadFlag from "../downloadFlag";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [Tabs, setTabs] = useState([]);
  const [ActiveTab, setActiveTab] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  
  const [Summary, setSummary] = useState([]);
  const [ChartHeight, setChartHeight] = useState('');
  const [Notes, setNotes] = useState("");
  
  const [FooterNotes, setFooterNotes] = useState('');

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [FetchCall, setFetchCall] = useState(false);
  const IsAllEnabled = 0;

  //Filters
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const CampaignAnalyticFilter = JSON.parse(localStorage.getItem("CampaignAnalyticFilter"));
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [Chartdata, setChartdata] = useState({});

  //Analytical filters state
  const [AllFilters, setAllFilters] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const Fixed = localStorage.getItem("AnalyticFilter") ? AnalyticFilter.Fixed : [];

  //Comp filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  const [FilterTabShow, setFilterTabShow] = useState(false);

  const [ShowSummary, setShowSummary] = useState(0)
  const [ResetFilterFlag, setResetFilterFlag] = useState(false)
  const [categoryDataFetched, setCategoryDataFetched] = useState(false);
  // console.log("categoryDataFetched: ", categoryDataFetched);


  const userName = localStorage.getItem("NameSkewb");

  // New states..............
  const [xAxis, setXAxis] = useState([]);
  // console.log("xAxis: ", xAxis);
  const [yAxis, setYAxis] = useState([]);
  const [xAxisTitle, setXAxisTitle] = useState("");
  const [yAxisTitle, setYAxisTitle] = useState("");
  const [chartTitle, setChartTitle] = useState("");
  const [legends, setLegends] = useState([]);
  const [showOpenLevel, setShowOpenLevel] = useState(0);

  // console.log("showOpenLevel: ", showOpenLevel);



  

  useEffect(() => {
    const url = window.location.href;
    const text = url.split('/');
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse])

  useEffect(() => {
    if (AnalyticFilterFlag && AnalyticFilter && CampaignAnalyticFilter) {
      setAllFilters(AnalyticFilter.AllFilters)
      setActiveFilters(AnalyticFilter.ActiveFilters)
      setCompActiveFilters(CampaignAnalyticFilter.CompActiveFilters);
      setCompAllFilters(CampaignAnalyticFilter.CompAllFilters)
      setAnalyticFilterFlag(false)
    }
  }, [AnalyticFilter, CampaignAnalyticFilter, AnalyticFilterFlag])

  useEffect(() => {
    axios
      .post("get_synergy_tabs/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTabs(response.data.data.tabs);
          if (response.data.data.tabs.length > 0) {
            setActiveTab(response.data.data.tabs[0].tab);
          }
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    axios
      .post("get_timeframes/", {
        // page: ActiveTab !== "AggregateTotalView" ? "synergy_item" : "synergy_aggr",
        page: "synergy_item",
        brand: Analyse.Brand,
        kpi: Analyse.KPI
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          // setFetchCall(true)
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);


  useEffect(() => {
    if (timeframe) {
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          page: "synergy",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            } else {
              setChannelCategory('');
            }
            setFetchCall(true);
            setCategoryDataFetched(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
        // setFetchCall(true);
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, timeframe])


  // old data api call.............................................
  useEffect(() => {
    setLoader(true);
    // console.log("inside useeffect synergy effects")
    if (timeframe && ActiveTab && FetchCall && categoryDataFetched && JSON.stringify(Chartdata) !== "{}") {
      // console.log("inside api call synergy effects")
      axios
        .post("get_synergy_effects/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          // tab: ActiveTab !== "AggregateTotalView" ? "item" : "aggr",
          tab: ActiveTab,
          timeframe: timeframe,
          category: ChannelCategory,
          chartdata: Chartdata,
          is_all_enabled: IsAllEnabled,
          theme: localStorage.getItem("CurrentTheme"),
          LightThemeColorCode: [localStorage.getItem("ColorCode") === null ? "" : localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

        })
        .then((response) => {
          // console.log(response);
          if (response.data.error !== 1) {
            setXAxis(response.data.data.xaxis);
            setYAxis(response.data.data.yaxis);
            setXAxisTitle(response.data.data.xtitle);
            setYAxisTitle(response.data.data.ytitle);
            setChartTitle(response.data.data.charttitle);
            setLegends(response.data.data.legends);
            setFooterNotes(response.data.notes);
            setSummary(response.data.data.summary);
            setShowSummary(response.data.data.show_summary);
            setShowOpenLevel(response.data.data.show_level);
          } else {
            toast.error(response.data.erroMsg);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [categoryDataFetched, Analyse.Brand, Analyse.KPI, ActiveTab, timeframe, ChannelCategory, FetchCall, Chartdata, IsAllEnabled]);

  // New api call start.....................................................
  // useEffect(() => {
  //   const fetchData = async () => {
  //      try {
  //       setLoader(true);
  //       const response = await axios.post("get_synergy_effects/", { 
  //         brand: Analyse.Brand,
  //         kpi: Analyse.KPI,
  //         tab: ActiveTab,
  //         timeframe: timeframe,
  //         category: ChannelCategory,
  //         chartdata: Chartdata,
  //         is_all_enabled: IsAllEnabled,
  //         theme: localStorage.getItem("CurrentTheme"),
  //         LightThemeColorCode: [localStorage.getItem("ColorCode") === null ? "" : localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],
  
  //        });
  //       //  console.log("response.data.data: ", response.data.data);
  //        setXAxis(response.data.data.xaxis);
  //        setYAxis(response.data.data.yaxis);
  //        setXAxisTitle(response.data.data.xtitle);
  //        setYAxisTitle(response.data.data.ytitle);
  //        setChartTitle(response.data.data.charttitle);
  //        setLegends(response.data.data.legends);
  //        setFooterNotes(response.data.notes);
  //        setSummary(response.data.data.summary);
  //        setShowSummary(response.data.data.show_summary);
  //        setLoader(false);
  //      } catch {
  //         setLoader(false);
  //      }       
  //   }


  //   if(timeframe && ActiveTab && FetchCall && categoryDataFetched && JSON.stringify(Chartdata) !== "{}") {
  //     fetchData();
  //   }
  // }, [categoryDataFetched, Analyse.Brand, Analyse.KPI, ActiveTab, timeframe, ChannelCategory, FetchCall, Chartdata, IsAllEnabled])
  // New api call end.......................................................
  const DownloadData = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("synergy/", {
        // data: {
        //   IndChannels: IndChannels,
        //   DirectChannels: DirectChannels,
        //   summary: Summary,
        //   Legends: Legends,
        // },
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        tab: ActiveTab,
        timeframe: timeframe,
        category: ChannelCategory,
        chartdata: Chartdata,
        is_all_enabled: IsAllEnabled,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const DownloadPDF = async (e) => {
    e.preventDefault();
    setLoader(true);
      try {
        const response = await axiosDownload.post("synergy/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          tab: ActiveTab,
          timeframe: timeframe,
          category: ChannelCategory,
          chartdata: Chartdata,
          is_all_enabled: IsAllEnabled,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });

        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: [],
          document: {
            table1_data: response.data.table1_data,
            titlename: "Cross Channel Synergy",
            footer_content: "Private & Confidential",
            table_heading1: "Cross Channel Synegry",
            username: userName,
            timeframe: timeframe,
            start_date: "",
            end_date: "",
            category: "",
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            chartdata: Chartdata,
            tabname: "synergy"
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
    }
  };

  // When Activefilter change : prepare chartdata for input of get api call
  useEffect(() => {
    const chartdata = {};
    if (Fixed.length !== 0) {
      for (var r = 0; r < Fixed.length; r++) {
        const Filter = [];
        Filter.push(Fixed[r].value)
        chartdata[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };
      }
    }

    if (ActiveFilters.length !== 0) {
      for (var i = 0; i < ActiveFilters.length; i++) {
        const selection = [];
        if ("Selected" in ActiveFilters[i]) {

          if (ActiveFilters[i]["Selected"].length > 0) {
            setResetFilterFlag(true)
          }

          for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
            var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
            selection.push(temp_arr[temp_arr.length - 1]);
          }
        }

        // chartdata[ActiveFilters[i].name] = {
        //   selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
        //   // Filter: selection,
        //   Filter: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].Filter : [],
        // };

        chartdata[ActiveFilters[i].name] = {
          selection: [],
          // Filter: selection,
          Filter: [],
        };
      }
    }

    for (var k = 0; k < CompActiveFilters.length; k++) {
      const selection = [];
      if ("Selected" in CompActiveFilters[k]) {
        if (CompActiveFilters[k]["Selected"].length > 0) {
          setResetFilterFlag(true)
        }
        for (var l = 0; l < CompActiveFilters[k].Selected.length; l++) {
          selection.push(CompActiveFilters[k].Selected[l].value);
        }
      }
      chartdata[CompActiveFilters[k].name] = {
        selection: [],
        Filter: selection,
      };
    }

    setChartdata(chartdata);
    setFetchCall(true);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesC([]);
  }, [ActiveFilters, CompActiveFilters]);

  //HEIRARCHY FILTERS 
  const GetOption = (current_level, selection, destination_index) => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown_options/", {
        hirarchy: Analyse.Brand,
        current_level: current_level,
        old_selection: selection !== "all" ? selection : ""
      })
      .then((response) => {
        const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
        var sort_arr = [...AllFilters];
        for (var i = 0; i < sort_arr.length; i++) {
          if (parseInt(sort_arr[i].level) === current_level) {
            var obj = sort_arr[i];
            obj['filters'] = response.data.data.options;
            obj['Selected'] = [];

            const temp_index = sort_arr.indexOf(sort_arr[i]);
            if (temp_index > -1) { // only splice array when item is found
              sort_arr.splice(temp_index, 1); // 2nd parameter means remove one item only
            }

            ActiveFiltersTemp.splice(
              destination_index,
              0,
              obj
            );
          }
        }
        let data = [...ActiveFiltersTemp];
        for (let k = 0; k < ActiveFiltersTemp.length; k++) {
          if (destination_index < k) {
            data[k]['filters'] = [];
            data[k]['Selected'] = [];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  }

  const AddHeirarchyFilter = (index) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let selection_value = [];
    var i = ((ActiveFiltersCompTemp.length) - 1);
    while (i >= 0) {
      if (ActiveFilters[i]['Selected'].length !== 0) {
        for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
          selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
        }
      }
      i--;
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    GetOption(parseInt(AllFilters[index].level), selection, ActiveFiltersCompTemp.length)

    let filter = AllFilters[index];
    filter.Selected = [];

    // setActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);
  };

  const onChangeFilter = (selected, index, data) => {

    const ActiveFiltersTemp = data ? data : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = selected;

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        "selection": selection !== "all" ? selection : "",
        "next_level": next_level
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp]
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {

          if (response.data.data[data[i].level]) {
            data[i]['Selected'] = []
            data[i]['filters'] = response.data.data[data[i].level];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {

      let selection_value = [];

      var i = ((result.destination.index) - 1);
      while (i >= 0) {
        if (ActiveFilters[i]['Selected'].length !== 0) {
          for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
            selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
          }
        }
        i--;
      }
      const selection = selection_value.toString().replaceAll(",", ";");
      GetOption(parseInt(result.draggableId), selection, result.destination.index)
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);
    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));

      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );

      onChangeFilter(ActiveFiltersTemp[result.destination.index].Selected, result.destination.index, ActiveFiltersTemp);

    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }

  };

  const RemoveHeirarchyFilter = (item) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  //CHANNEL FILTERS
  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  const IsInAllCompFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  const onCompDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersCompTemp = JSON.parse(
        JSON.stringify(CompActiveFilters)
      );
      ActiveFiltersCompTemp.splice(result.source.index, 1);
      ActiveFiltersCompTemp.splice(
        result.destination.index,
        0,
        CompActiveFilters[result.source.index]
      );
      setCompActiveFilters(ActiveFiltersCompTemp);

      //Set campaign filter data in localstorage
      const CampaignAnalyticFilter = {
        CompAllFilters: CompAllFilters,
        CompActiveFilters: ActiveFiltersCompTemp
      };

      localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
    }
  };

  const onCompChangeFilter = (selected, index) => {
    const CompActiveFiltersTemp = JSON.parse(JSON.stringify(CompActiveFilters));

    CompActiveFiltersTemp[index].Selected = selected;
    setCompActiveFilters(CompActiveFiltersTemp);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: CompActiveFiltersTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [FetchCall])

  const ResetFilters = () => {
    var active_filter = []
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj['Selected'] = [];
      active_filter.push(obj)
    }

    var comp_active_filter = []
    for (let k = 0; k < CompActiveFilters.length; k++) {
      var obj1 = CompActiveFilters[k];
      obj1['Selected'] = [];
      comp_active_filter.push(obj1)
    }

    setResetFilterFlag(false)
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: comp_active_filter
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));

    setActiveFilters(active_filter)
    setCompActiveFilters(comp_active_filter)
  }


  const [HFilters, setHFilters] = useState([]);
  console.log("HFilters: ", HFilters);
  console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeys = Object.keys(Chartdata).filter(key => !key.startsWith('C'));



  useEffect(() => {
    const fetchHierarchyFilters = async () => {

      try {
        const response = await axios.post(
          "hieracrhy_data/",
          { hierarchy_list: filteredKeys, brand: Analyse.Brand }
        );
  
        const data = response.data;
        // console.log(data);
        setHFilters(data.data);
        // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };
    fetchHierarchyFilters();
  }, [Chartdata, Analyse.Brand])

  const [CFilters, setCFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxesC, setSelectedCheckboxesC] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);

  const filteredKeysC = Object.keys(Chartdata).filter(key => !key.startsWith('H'));



  useEffect(() => {
    const fetchChannelFilters = async () => {
      try {
        const response = await axios.post(
          "channel_hierarcy/",
          { channel_list: filteredKeysC, brand: Analyse.Brand }
        );
  
        const data = response.data;
        // console.log(data);
        setCFilters(data.data);
        // localStorage.setItem("channelFilters", JSON.stringify(data.data));
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };
    fetchChannelFilters();
  }, [Chartdata, Analyse.Brand])





  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w pt-0">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Effectiveness Drivers */}
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                        return "/" + item.key === location.pathname
                          ? item.value
                          : null;
                      })
                      : null}
                  </h1>
                </div>
              </div>
              <div className="row pr-0 mt-1">
                <AllHeirarchyFilter OnlyHeirarchyFilter={false} Fixed={Fixed} ActiveFilters={ActiveFilters} AllFilters={AllFilters} AddHeirarchyFilter={AddHeirarchyFilter} RemoveHeirarchyFilter={RemoveHeirarchyFilter} IsInAllHeirarchyFilters={IsInAllHeirarchyFilters} Chartdata={Chartdata} setChartdata={setChartdata} setFetchCall={setFetchCall}  HFilters={HFilters}
                  setActiveFilters={setActiveFilters}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}  />
                <AllCampaignFilter CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} AddCompFilter={AddCompFilter} RemoveCompFilter={RemoveCompFilter} IsInAllCompFilters={IsInAllCompFilters} Chartdata={Chartdata} setChartdata={setChartdata} setFetchCall={setFetchCall}
                  setCompActiveFilters={setCompActiveFilters}
                  CFilters={CFilters}
                  selectedCheckboxesC={selectedCheckboxesC}
                  setSelectedCheckboxesC={setSelectedCheckboxesC}
                />
                {false && <div className="col-xl-2 pr-0">
                  <div className="card my-1">
                    <div className="card-body cursor-pointer d-flex align-items-center justify-content-center custom-selected-filter" onClick={() => {
                      setFilterTabShow(true);
                    }} >
                      <h6 className="m-0 text-center primary-text"><i className="fa fa-filter" aria-hidden="true"></i> Selected Filter</h6>
                      {ResetFilterFlag ?
                        <h6 className="m-0 text-center primary-text cursor-pointer custom-reset" >
                          <p className="mx-2 m-0" title="Reset Filter" onClick={() => {
                            ResetFilters();
                          }}> &#8635; </p>
                        </h6>
                        : null
                      }
                    </div>
                  </div>
                </div>}
              </div>

            </div>
          </div>
          <div
            className="container-fluid main-content-w pt-1"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              {
                false ?
                  <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={false} onCompChangeFilter={onCompChangeFilter} onChangeFilter={onChangeFilter} onDragEnd={onDragEnd} ActiveFilters={ActiveFilters} onCompDragEnd={onCompDragEnd} setFilterTabShow={setFilterTabShow} CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} Fixed={Fixed}
                    HFilters={HFilters}
                    setActiveFilters={setActiveFilters}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                  />
                  : null
              }
              <div className={parseInt(ShowSummary) === 1 ? "col-xl-9" : "col-xl-12"}>
                <div className="card card-min-height synergy-table-height">
                  <div className="card-body brand-card ef-drivers pb-1" ref={refGraphDiv}>
                    <div className="row align-items-center justify-content-sm-between pb-3 mt-2">
                      <div className="col-sm-2">
                        <select
                          className="form-select custom-input-box"
                          value={timeframe}
                          onChange={(e) => setTimeframe(e.target.value)}
                        >
                          {TimeFramesAll.map((item) => {
                            return (
                              <option key={item.key} value={item.key}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {AllChannelCategory.length !== 0 && AllChannelCategory.length > 1 ? (
                        <div className="col-sm-2">
                          <select
                            className="form-select custom-input-box"
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                            }}
                            value={ChannelCategory}
                          >
                            {AllChannelCategory.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}

                      <div className="col-sm-5">
                        <p className="card-title-lable" style={{
                          paddingLeft: "95px"
                        }}>{chartTitle}</p>
                      </div>

                      <div className="col-sm-3 d-flex align-items-center justify-content-sm-end">
                        {Tabs.map((item) => {
                          return (
                            <p
                              key={item.tab}
                              // style={{ cursor: "pointer" }}
                              className={
                                ActiveTab === item.tab
                                  ? "m-0 px-2 mx-2 lt-st custom-input-box"
                                  : "m-0 px-2 mx-2 text-lable custom-input-box"
                              }
                              // onClick={() => {
                              //   setActiveTab(item.tab);
                              //   setXAxis([]);
                              //   setYAxis([]);
                              //   setXAxisTitle("");
                              //   setYAxisTitle("");
                              //   setChartTitle("");
                              // }}
                            >
                              {item.tab_name}
                            </p>
                          );
                        })}

                        {
                          Notes !== "" ?
                            <>
                              <p className="m-0 px-2" style={{ cursor: "pointer" }}>
                                <svg src={InfoIcon} id="notes" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-info-circle" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                              </p>

                              <Tooltip anchorSelect="#notes" clickable>
                                {/* <div className="api-notes">
                                  {Notes}
                                </div> */}
                               <div
                                 className="api-notes"
                                 dangerouslySetInnerHTML={{ __html: Notes }}
                                 >
                               </div>
                              </Tooltip>
                            </> : null
                        }

                        {/* <p className="m-0 px-2" title="Download"
                          onClick={() => DownloadData()} style={{ cursor: "pointer" }}>
                          <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                          </svg>
                        </p> */}

                        {/* download start */}
                        { (downloadFlag.data || downloadFlag.pdf) &&<span className="flex-shrink-0 dropdown">
                              <Link
                                to="#"
                                className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                                id="dropdownUser2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <p className="m-0 px-2">
                                  <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </p>
                              </Link>
                              <ul
                                className="dropdown-menu text-small shadow"
                                aria-labelledby="dropdownUser2"
                              >

                                {downloadFlag.data && <li onClick={(e) => { }}>
                                  <Link className="dropdown-item" onClick={(e) => DownloadData(e)} >Download Data</Link>
                                </li>}
                                {downloadFlag.pdf && <li onClick={(e) => { }}>
                                  <Link className="dropdown-item" onClick={(e) => DownloadPDF(e)} >Download PDF</Link>
                                </li>}
                              </ul>
                            </span>}
                        {/* download end */}
                      </div>
                    </div>

                    {ActiveTab === "item" ? (
                      <ItemWise
                        height={ChartHeight}
                        xAxis={xAxis} //
                        yAxis={yAxis}
                        xAxisTitle={xAxisTitle}
                        yAxisTitle={yAxisTitle}
                        chartTitle={chartTitle}
                        legends={legends}
                        showOpenLevel={showOpenLevel}
                        setShowOpenLevel={setShowOpenLevel}
                      />
                    ) : (
                      ""
                    )}
                    {ActiveTab === "aggr" ? (
                      <AggregateTotalView
                        height={ChartHeight}
                        xAxis={xAxis} //
                        yAxis={yAxis}
                        xAxisTitle={xAxisTitle}
                        yAxisTitle={yAxisTitle}
                        chartTitle={chartTitle}
                        legends={legends}
                        showOpenLevel={showOpenLevel}
                        setShowOpenLevel={setShowOpenLevel}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {
                parseInt(ShowSummary) === 1 ?
                  <div className="col-xl-3">
                    <div className="card card-min-height synergy-table-height">
                      <div className="card-body brand-card">
                        <div className="row align-items-center justify-content-sm-between">
                          <h3 className="data-spends my-2">Summary</h3>
                          <div className="spends-list my-2">
                            <ol>
                              {Summary ? Summary.map((item, index) => {
                                return (
                                  <li key={index} className="spends-list-li">
                                    {item}
                                  </li>
                                );
                              }) : ""
                              }
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {
                  FooterNotes !== "" ?
                    <>
                      <i
                        className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                        id="clickable"
                      ></i>
                      <Tooltip anchorSelect="#clickable" clickable>
                        {/* <div className="footer-notes">
                          {FooterNotes}
                        </div> */}
                        <div
                           className="footer-notes"
                           dangerouslySetInnerHTML={{ __html: FooterNotes }}
                           >
                        </div>
                      </Tooltip>
                    </>
                    : null
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
