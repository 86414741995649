import React, { useState, useEffect, useRef } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const Cascader = ({
  data,
  searchValue,
  setSearchValue,
  selectedPath,
  setSelectedPath,
  setIdList,
  pathName,
  selectedCheckboxes,
  setSelectedCheckboxes,
  flattenedData,
  searchResults,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedExpandIcon, setSelectedExpandIcon] = useState(null);

  // Handle click on category button
  const handleCategoryButtonClick = (categoryId) => {
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
  };

  // Handle click on expand icon (+ or -)
  const handleExpandIconClick = (categoryId) => {
    if (categoryId === selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
    } else {
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    }

    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );

    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });

      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
  };

  // const handleSearchResultCheckboxClickWrapper = (result)=>{
  //   handleSearchResultCheckboxClick(result);
  // };

  const selectedIds = Object.keys(selectedCheckboxes)
    .filter((id) => selectedCheckboxes[id] === true)
    .filter((id) => {
      return !id.endsWith("-all") && id !== "all";
    });

  // useEffect(() => {
  //   console.log("Selected IDs:", selectedIds);
  // }, [selectedIds]);

  //   useEffect(() => {
  //   console.log("SearchResults: ", searchResults);
  // }, [searchResults]);

  // const handleCheckboxClick = (categoryId) => {
  //   const pathSegments = categoryId.split("-");
  //   const newPath = pathSegments.map((segment, index) =>
  //     pathSegments.slice(0, index + 1).join("-")
  //   );

  //   if (categoryId !== selectedCategory) {
  //     setSelectedCategory(null);
  //     setSelectedExpandIcon(null);
  //     setSelectedPath(newPath);
  //     setSelectedCategory(categoryId);
  //     setSelectedExpandIcon(categoryId);
  //   } else {
  //     setSelectedPath((prevSelectedPath) => {
  //       if (prevSelectedPath.length >= newPath.length) {
  //         return newPath.slice(0, pathSegments.length);
  //       }
  //       return newPath;
  //     });

  //     setSelectedCategory((prevSelectedCategory) => {
  //       const prevPathSegments = prevSelectedCategory
  //         ? prevSelectedCategory.split("-")
  //         : [];
  //       if (prevPathSegments.length >= pathSegments.length) {
  //         return pathSegments.join("-");
  //       }
  //       return prevSelectedCategory;
  //     });
  //   }
  //   // setSearchValue("");
  //   setSelectedCheckboxes((prev) => {
  //     const isChecked = prev[categoryId];
  //     const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };

  //     const updateChildrenCheckboxes = (parentId, parentChecked) => {
  //       const children = flattenedData.filter(
  //         (item) => item.parentId === parentId
  //       );
  //       children.forEach((child) => {
  //         updatedCheckboxes[child.id] = parentChecked;
  //         updateChildrenCheckboxes(child.id, parentChecked);
  //       });
  //     };

  //     const updateParentCheckboxes = (childId) => {
  //       const childItem = flattenedData.find((item) => item.id === childId);
  //       if (childItem) {
  //         const parentId = childItem.parentId;
  //         if (parentId) {
  //           const siblings = flattenedData.filter(
  //             (item) => item.parentId === parentId && !item.id.endsWith("-all")
  //           );
  //           const allSiblingsChecked = siblings.every(
  //             (sibling) => updatedCheckboxes[sibling.id] === true
  //           );
  //           const someSiblingsChecked = siblings.some(
  //             (sibling) => updatedCheckboxes[sibling.id] === true
  //           );
  //           const anySiblingsIndeterminate = siblings.some(
  //             (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
  //           );

  //           if (allSiblingsChecked) {
  //             updatedCheckboxes[parentId] = true;
  //           } else if (anySiblingsIndeterminate || someSiblingsChecked) {
  //             updatedCheckboxes[parentId] = "indeterminate";
  //           } else {
  //             updatedCheckboxes[parentId] = false;
  //           }

  //           if (parentId.endsWith("-all")) {
  //             updatedCheckboxes[parentId.replace("-all", "")] =
  //               allSiblingsChecked;
  //           } else {
  //             updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
  //           }

  //           if (parentId !== "all") {
  //             updateParentCheckboxes(parentId);
  //           } else {
  //             const rootLevelItems = flattenedData.filter(
  //               (item) => !item.parentId
  //             );
  //             const allRootItemsUnchecked = rootLevelItems.every(
  //               (item) => updatedCheckboxes[item.id] === false
  //             );
  //             const someRootItemsChecked = rootLevelItems.some(
  //               (item) => updatedCheckboxes[item.id] === true
  //             );
  //             const anyRootItemsIndeterminate = rootLevelItems.some(
  //               (item) => updatedCheckboxes[item.id] === "indeterminate"
  //             );

  //             if (allRootItemsUnchecked) {
  //               updatedCheckboxes["all"] = false;
  //             } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
  //               updatedCheckboxes["all"] = "indeterminate";
  //             } else {
  //               updatedCheckboxes["all"] = true;
  //             }
  //           }
  //         }
  //       }
  //     };

  //     updateChildrenCheckboxes(categoryId, !isChecked);

  //     if (categoryId === "all") {
  //       flattenedData.forEach((item) => {
  //         updatedCheckboxes[item.id] = !isChecked;
  //         updateChildrenCheckboxes(item.id, !isChecked);
  //       });
  //     } else if (categoryId.endsWith("-all")) {
  //       const categoryLevel = categoryId.replace("-all", "");
  //       flattenedData.forEach((item) => {
  //         if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
  //           updatedCheckboxes[item.id] = !isChecked;
  //           updateChildrenCheckboxes(item.id, !isChecked);
  //         }
  //       });

  //       flattenedData.forEach((item) => {
  //         if (item.id.endsWith("-all")) {
  //           const levelPrefix = item.id.replace("-all", "");
  //           const levelItems = flattenedData.filter(
  //             (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
  //           );
  //           const allLevelItemsChecked = levelItems.every(
  //             (i) => updatedCheckboxes[i.id] === true
  //           );
  //           const someLevelItemsChecked = levelItems.some(
  //             (i) => updatedCheckboxes[i.id] === true
  //           );
  //           const anyLevelItemsIndeterminate = levelItems.some(
  //             (i) => updatedCheckboxes[i.id] === "indeterminate"
  //           );

  //           if (allLevelItemsChecked) {
  //             updatedCheckboxes[item.id] = true;
  //           } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
  //             updatedCheckboxes[item.id] = "indeterminate";
  //           } else {
  //             updatedCheckboxes[item.id] = false;
  //           }
  //         }
  //       });
  //     }

  //     if (categoryId === "all") {
  //       const rootLevelItems = flattenedData.filter((item) => !item.parentId);
  //       const allRootItemsUnchecked = rootLevelItems.every(
  //         (item) => updatedCheckboxes[item.id] === false
  //       );

  //       if (allRootItemsUnchecked) {
  //         updatedCheckboxes["all"] = false;
  //       }
  //     }
  //     updateParentCheckboxes(categoryId);

  //     return updatedCheckboxes;
  //   });
  // };

  // const handleCheckboxClick = (categoryId) => {
  //   const pathSegments = categoryId.split("-");
  //   const newPath = pathSegments.map((segment, index) =>
  //     pathSegments.slice(0, index + 1).join("-")
  //   );
 
  //   if (categoryId !== selectedCategory) {
  //     setSelectedCategory(null);
  //     setSelectedExpandIcon(null);
  //     setSelectedPath(newPath);
  //     setSelectedCategory(categoryId);
  //     setSelectedExpandIcon(categoryId);
  //   } else {
  //     setSelectedPath((prevSelectedPath) => {
  //       if (prevSelectedPath.length >= newPath.length) {
  //         return newPath.slice(0, pathSegments.length);
  //       }
  //       return newPath;
  //     });
 
  //     setSelectedCategory((prevSelectedCategory) => {
  //       const prevPathSegments = prevSelectedCategory
  //         ? prevSelectedCategory.split("-")
  //         : [];
  //       if (prevPathSegments.length >= pathSegments.length) {
  //         return pathSegments.join("-");
  //       }
  //       return prevSelectedCategory;
  //     });
  //   }
  //   // setSearchValue("");
  //   setSelectedCheckboxes((prev) => {
  //     const isChecked = prev[categoryId];
  //     const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };
 
  //     const updateParentCheckboxes = (childId) => {
  //       const childItem = flattenedData.find((item) => item.id === childId);
  //       if (childItem) {
  //         const parentId = childItem.parentId;
  //         if (parentId) {
  //           const siblings = flattenedData.filter(
  //             (item) => item.parentId === parentId && !item.id.endsWith("-all")
  //           );
  //           const allSiblingsChecked = siblings.every(
  //             (sibling) => updatedCheckboxes[sibling.id] === true
  //           );
  //           const someSiblingsChecked = siblings.some(
  //             (sibling) => updatedCheckboxes[sibling.id] === true
  //           );
  //           const anySiblingsIndeterminate = siblings.some(
  //             (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
  //           );
 
  //           if (allSiblingsChecked) {
  //             updatedCheckboxes[parentId] = true;
  //           } else if (anySiblingsIndeterminate || someSiblingsChecked) {
  //             updatedCheckboxes[parentId] = "indeterminate";
  //           } else {
  //             updatedCheckboxes[parentId] = false;
  //           }
 
  //           if (parentId.endsWith("-all")) {
  //             updatedCheckboxes[parentId.replace("-all", "")] =
  //               allSiblingsChecked;
  //           } else {
  //             updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
  //           }
 
  //           if (parentId !== "all") {
  //             updateParentCheckboxes(parentId);
  //           } else {
  //             const rootLevelItems = flattenedData.filter(
  //               (item) => !item.parentId
  //             );
  //             const allRootItemsUnchecked = rootLevelItems.every(
  //               (item) => updatedCheckboxes[item.id] === false
  //             );
  //             const someRootItemsChecked = rootLevelItems.some(
  //               (item) => updatedCheckboxes[item.id] === true
  //             );
  //             const anyRootItemsIndeterminate = rootLevelItems.some(
  //               (item) => updatedCheckboxes[item.id] === "indeterminate"
  //             );
 
  //             if (allRootItemsUnchecked) {
  //               updatedCheckboxes["all"] = false;
  //             } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
  //               updatedCheckboxes["all"] = "indeterminate";
  //             } else {
  //               updatedCheckboxes["all"] = true;
  //             }
  //           }
  //         }
  //       }
  //     };
 
  //     if (categoryId === "all") {
  //       flattenedData.forEach((item) => {
  //         updatedCheckboxes[item.id] = !isChecked;
  //       });
  //     } else if (categoryId.endsWith("-all")) {
  //       const categoryLevel = categoryId.replace("-all", "");
  //       flattenedData.forEach((item) => {
  //         if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
  //           updatedCheckboxes[item.id] = !isChecked;
  //         }
  //       });
 
  //       flattenedData.forEach((item) => {
  //         if (item.id.endsWith("-all")) {
  //           const levelPrefix = item.id.replace("-all", "");
  //           const levelItems = flattenedData.filter(
  //             (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
  //           );
  //           const allLevelItemsChecked = levelItems.every(
  //             (i) => updatedCheckboxes[i.id] === true
  //           );
  //           const someLevelItemsChecked = levelItems.some(
  //             (i) => updatedCheckboxes[i.id] === true
  //           );
  //           const anyLevelItemsIndeterminate = levelItems.some(
  //             (i) => updatedCheckboxes[i.id] === "indeterminate"
  //           );
 
  //           if (allLevelItemsChecked) {
  //             updatedCheckboxes[item.id] = true;
  //           } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
  //             updatedCheckboxes[item.id] = "indeterminate";
  //           } else {
  //             updatedCheckboxes[item.id] = false;
  //           }
  //         }
  //       });
  //     }
 
  //     updateParentCheckboxes(categoryId);
 
  //     return updatedCheckboxes;
  //   });
  // };
  const handleCheckboxClick = (categoryId) => {
    setSearchValue(categoryId);
    const pathSegments = categoryId.split("-");
    const newPath = pathSegments.map((segment, index) =>
      pathSegments.slice(0, index + 1).join("-")
    );
 
    if (categoryId !== selectedCategory) {
      setSelectedCategory(null);
      setSelectedExpandIcon(null);
      setSelectedPath(newPath);
      setSelectedCategory(categoryId);
      setSelectedExpandIcon(categoryId);
    } else {
      setSelectedPath((prevSelectedPath) => {
        if (prevSelectedPath.length >= newPath.length) {
          return newPath.slice(0, pathSegments.length);
        }
        return newPath;
      });
 
      setSelectedCategory((prevSelectedCategory) => {
        const prevPathSegments = prevSelectedCategory
          ? prevSelectedCategory.split("-")
          : [];
        if (prevPathSegments.length >= pathSegments.length) {
          return pathSegments.join("-");
        }
        return prevSelectedCategory;
      });
    }
    // setSearchValue("");
    setSelectedCheckboxes((prev) => {
      const isChecked = prev[categoryId];
      const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };
 
      const updateParentCheckboxes = (childId) => {
        const childItem = flattenedData.find((item) => item.id === childId);
        if (childItem) {
          const parentId = childItem.parentId;
          if (parentId) {
            const siblings = flattenedData.filter(
              (item) => item.parentId === parentId && !item.id.endsWith("-all")
            );
            const allSiblingsChecked = siblings.every(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const someSiblingsChecked = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === true
            );
            const anySiblingsIndeterminate = siblings.some(
              (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
            );
 
            // Updated logic to prevent setting parent to true if all children are true
            if (allSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else if (anySiblingsIndeterminate || someSiblingsChecked) {
              updatedCheckboxes[parentId] = "indeterminate";
            } else {
              updatedCheckboxes[parentId] = false;
            }
 
            if (parentId.endsWith("-all")) {
              updatedCheckboxes[parentId.replace("-all", "")] = allSiblingsChecked;
            } else {
              updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
            }
 
            if (parentId !== "all") {
              updateParentCheckboxes(parentId);
            } else {
              const rootLevelItems = flattenedData.filter(
                (item) => !item.parentId
              );
              const allRootItemsUnchecked = rootLevelItems.every(
                (item) => updatedCheckboxes[item.id] === false
              );
              const someRootItemsChecked = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === true
              );
              const anyRootItemsIndeterminate = rootLevelItems.some(
                (item) => updatedCheckboxes[item.id] === "indeterminate"
              );
 
              if (allRootItemsUnchecked) {
                updatedCheckboxes["all"] = false;
              } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
                updatedCheckboxes["all"] = "indeterminate";
              } else {
                updatedCheckboxes["all"] = true;
              }
            }
          }
        }
      };
 
      if (categoryId === "all") {
        flattenedData.forEach((item) => {
          updatedCheckboxes[item.id] = !isChecked;
        });
      } else if (categoryId.endsWith("-all")) {
        const categoryLevel = categoryId.replace("-all", "");
        flattenedData.forEach((item) => {
          if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
            updatedCheckboxes[item.id] = !isChecked;
          }
        });
 
        flattenedData.forEach((item) => {
          if (item.id.endsWith("-all")) {
            const levelPrefix = item.id.replace("-all", "");
            const levelItems = flattenedData.filter(
              (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
            );
            const allLevelItemsChecked = levelItems.every(
              (i) => updatedCheckboxes[i.id] === true
            );
            const someLevelItemsChecked = levelItems.some(
              (i) => updatedCheckboxes[i.id] === true
            );
            const anyLevelItemsIndeterminate = levelItems.some(
              (i) => updatedCheckboxes[i.id] === "indeterminate"
            );
 
            // Updated logic to prevent setting parent to true if all children are true
            if (allLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
              updatedCheckboxes[item.id] = "indeterminate";
            } else {
              updatedCheckboxes[item.id] = false;
            }
          }
        });
      }
 
      updateParentCheckboxes(categoryId);
 
      return updatedCheckboxes;
    });
  };


  const getCheckboxIcon = (categoryId) => {
    if (selectedCheckboxes[categoryId] === "indeterminate") {
      return (
        <IndeterminateCheckBoxIcon
          sx={{ color: "#d6ff41", fontSize: "16px" }}
        />
      );
    }
    return selectedCheckboxes[categoryId] ? (
      <CheckBoxIcon sx={{ color: "#d6ff41", fontSize: "16px" }} />
    ) : (
      <CheckBoxOutlineBlankIcon sx={{ color: "#d6ff41", fontSize: "16px" }} />
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          padding: "8px 0",
          // height: "385px",
          backgroundColor: "#1C2427",
        }}
      >
        {data.map((category) => (
          <div style={{ marginBottom: "4px", width: "100%" }} key={category.id}>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  color: selectedPath.includes(category.id) ? "black" : "white",
                  padding: "0 10px",
                  fontSize: "14px",
                  cursor: "pointer",
                  backgroundColor: selectedPath.includes(category.id)
                    ? "#4A808080"
                    : selectedExpandIcon === category.id
                    ? "#4A808080"
                    : "#1C2427",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => handleCategoryButtonClick(category.id)}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxClick(category.id);
                  }}
                  style={{ marginRight: "8px" }}
                >
                  {getCheckboxIcon(category.id)}
                </div>
                <span
                  style={{ flexGrow: 1, textAlign: "left", color: "white" }}
                >
                  {category.name}
                </span>

                {category?.children?.length>0 && (
                  <span
                    style={{
                      marginLeft: "4px",
                      cursor: "pointer",
                      textAlign: "right",
                      color: "#D6FF41",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExpandIconClick(category.id);
                    }}
                  >
                    {selectedExpandIcon === category.id ? "−" : "+"}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        {data.map(
          (category) =>
            selectedCategory === category.id &&
            category.children && (
              <div key={category.id}>
                <Cascader
                  data={category.children}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedPath={selectedPath}
                  setSelectedPath={setSelectedPath}
                  setIdList={setIdList}
                  pathName={pathName}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                  flattenedData={flattenedData}
                  searchResults={searchResults}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

const CascaderWrapper = ({ data,placeholder,setChannelData }) => {
  const [searchValue, setSearchValue] = useState("");
  const [isCascaderVisible, setIsCascaderVisible] = useState(false);
  const [selectedPath, setSelectedPath] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const cascaderRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchListIconRef = useRef(null);
  const toggleCascaderRef = useRef(null);
  const [idList, setIdList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [pathName, setPathName] = useState("");
  const searchResultsContainerRef = useRef(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [SelectAllIcon, setSelectAllIcon] = useState(true);
  const [checkedList, setCheckedList] = useState([]);

  const selectedIds = Object.keys(selectedCheckboxes)
    .filter((id) => selectedCheckboxes[id] === true)
    .filter((id) => !id.endsWith("-all") && id !== "all");

  useEffect(() => {
    setCheckedList(selectedIds);
  }, [selectedCheckboxes]);

  useEffect(() => {
    console.log("checkedList :", checkedList);
    setChannelData(checkedList)
  }, [checkedList]);

  useEffect(() => {
    console.log("selectedCheckboxes :", selectedCheckboxes);
  }, [selectedCheckboxes]);
  

  //  console.log(selectedIds);

  // useEffect(() => {
    
  // }, [selectedCheckboxes]);
  // // setCheckedList(selectedIds);

  function flattenDataToObject(data) {
    const result = [];

    const traverse = (items, parentId = null) => {
      items?.forEach((item) => {
        const { id, name, children } = item;
        result.push({ id, name, parentId });

        if (children && children.length > 0) {
          traverse(children, id);
        }
      });
    };

    traverse(data);
    return result;
  }

  const flattenedData = flattenDataToObject(data);
  // console.log(flattenedData);
  // function constructCategoryPath(newPath, flattenedData) {
  //   return newPath.map((key) => flattenedData[key]);
  // }

  function getTeaList(idList, flattenedData) {
    return idList.map((key) => flattenedData[key]);
  }

  useEffect(() => {
    const result = getTeaList(idList, flattenedData);
    setNameList(result);
  }, [idList]);

  useEffect(() => {
    if (nameList) {
      const names = nameList.join(" / ");
      setPathName(names);
      setSearchValue(names);
    } else {
      setPathName("");
      setSearchValue("");
    }
  }, [nameList]);

  // useEffect(() => {
  //   const pathSegments = match.split("-");
  //   const newPath = pathSegments.map((segment, index) =>
  //     pathSegments.slice(0, index + 1).join("-")
  //   );
  //   // setSelectedPath(newPath);
  //   // console.log(newPath);
  //   // const result = getTeaList(newPath, flattenedData);
  //   // setNameList(result);

  //   // if (nameList) {
  //   //   const names = nameList.join(" / ");
  //   //   setPathName(names);
  //   //   //setSearchValue(names);
  //   // } else {
  //   //   setPathName("");
  //   //   setSearchValue("");
  //   // }
  // }, []);

  // Toggle visibility of cascader
  const toggleCascaderVisibility = (event) => {
    event.stopPropagation();
    setIsCascaderVisible((prevState) => !prevState);
  };

  // Handle search input change
  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);
    if (searchTerm.trim() === "") {
      setSearchResults([]);
      setSearchValue("");
      setIsDropDownVisible(false);
    } else {
      const matchingResults = findMatchingResults(data, searchTerm);
      setSearchResults(matchingResults);
      setIsDropDownVisible(true);
    }
  };

  // const handleSearchResultClick = (result) => {
  //   const pathSegments = [];
  //   const idSegments = result.id.trim().toLowerCase().split("-");
  //   for (let i = idSegments.length; i > 0; i--) {
  //     pathSegments.push(idSegments.slice(0, i).join("-"));
  //   }

  //   result.path.forEach((segment) => {
  //     const segments = segment.trim().toLowerCase().split("-");
  //     for (let i = segments.length; i > 0; i--) {
  //       pathSegments.push(segments.slice(0, i).join("-"));
  //     }
  //   });

  //   const n = pathSegments.length / 2;
  //   pathSegments.splice(-n);

  //   const pathName = result.path.join(" / ");
  //   setSearchValue(pathName);
  //   setSelectedPath(pathSegments);
  //   setIsDropDownVisible(false);
  // };

  // Find matching results based on search term
  const findMatchingResults = (data, searchTerm) => {
    const matchingResults = [];
    const traverse = (children, path) => {
      children.forEach((item) => {
        const currentPath = path ? `${path} / ${item.name}` : item.name;
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          matchingResults.push({ path: currentPath.split(" / "), id: item.id });
        }
        if (item.children) {
          traverse(item.children, currentPath);
        }
      });
    };
    traverse(data, "");
    return matchingResults;
  };

  // console.log("searchResults ", searchResults);
  // function for handling the click on the All option present in the SearchResult List

  // const getIdsNotInSearchResults = (selectedCheckBoxes, searchResults) => {
  //   const searchResultsIds = searchResults.map((result) => result.id);
  //   const selectedCheckBoxesIds = Object.keys(selectedCheckBoxes);

  //   return selectedCheckBoxesIds.filter((id) => !searchResultsIds.includes(id));
  // };

  const handleSearchResultAllClick = (event) => {
    event.stopPropagation(); 
    searchResults.forEach((result) => {
      if (!checkedList.includes(result.id)) {
        handleSearchResultCheckboxClick(event, result); 
      }
    });
  };

  const handleSetSelectedCheckBoxes = (categoryId) => {
    
      setSelectedCheckboxes((prev) => {
        const isChecked = prev[categoryId];
        const updatedCheckboxes = { ...prev, [categoryId]: !isChecked };
   
        const updateParentCheckboxes = (childId) => {
          const childItem = flattenedData.find((item) => item.id === childId);
          if (childItem) {
            const parentId = childItem.parentId;
            if (parentId) {
              const siblings = flattenedData.filter(
                (item) => item.parentId === parentId && !item.id.endsWith("-all")
              );
              const allSiblingsChecked = siblings.every(
                (sibling) => updatedCheckboxes[sibling.id] === true
              );
              const someSiblingsChecked = siblings.some(
                (sibling) => updatedCheckboxes[sibling.id] === true
              );
              const anySiblingsIndeterminate = siblings.some(
                (sibling) => updatedCheckboxes[sibling.id] === "indeterminate"
              );
   
              // Updated logic to prevent setting parent to true if all children are true
              if (allSiblingsChecked) {
                updatedCheckboxes[parentId] = "indeterminate";
              } else if (anySiblingsIndeterminate || someSiblingsChecked) {
                updatedCheckboxes[parentId] = "indeterminate";
              } else {
                updatedCheckboxes[parentId] = false;
              }
   
              if (parentId.endsWith("-all")) {
                updatedCheckboxes[parentId.replace("-all", "")] = allSiblingsChecked;
              } else {
                updatedCheckboxes[`${parentId}-all`] = allSiblingsChecked;
              }
   
              if (parentId !== "all") {
                updateParentCheckboxes(parentId);
              } else {
                const rootLevelItems = flattenedData.filter(
                  (item) => !item.parentId
                );
                const allRootItemsUnchecked = rootLevelItems.every(
                  (item) => updatedCheckboxes[item.id] === false
                );
                const someRootItemsChecked = rootLevelItems.some(
                  (item) => updatedCheckboxes[item.id] === true
                );
                const anyRootItemsIndeterminate = rootLevelItems.some(
                  (item) => updatedCheckboxes[item.id] === "indeterminate"
                );
   
                if (allRootItemsUnchecked) {
                  updatedCheckboxes["all"] = false;
                } else if (anyRootItemsIndeterminate || someRootItemsChecked) {
                  updatedCheckboxes["all"] = "indeterminate";
                } else {
                  updatedCheckboxes["all"] = true;
                }
              }
            }
          }
        };
   
        if (categoryId === "all") {
          flattenedData.forEach((item) => {
            updatedCheckboxes[item.id] = !isChecked;
          });
        } else if (categoryId.endsWith("-all")) {
          const categoryLevel = categoryId.replace("-all", "");
          flattenedData.forEach((item) => {
            if (item.id.startsWith(categoryLevel) && !item.id.includes("-all")) {
              updatedCheckboxes[item.id] = !isChecked;
            }
          });
   
          flattenedData.forEach((item) => {
            if (item.id.endsWith("-all")) {
              const levelPrefix = item.id.replace("-all", "");
              const levelItems = flattenedData.filter(
                (i) => i.parentId === levelPrefix && !i.id.endsWith("-all")
              );
              const allLevelItemsChecked = levelItems.every(
                (i) => updatedCheckboxes[i.id] === true
              );
              const someLevelItemsChecked = levelItems.some(
                (i) => updatedCheckboxes[i.id] === true
              );
              const anyLevelItemsIndeterminate = levelItems.some(
                (i) => updatedCheckboxes[i.id] === "indeterminate"
              );
   
              // Updated logic to prevent setting parent to true if all children are true
              if (allLevelItemsChecked) {
                updatedCheckboxes[item.id] = "indeterminate";
              } else if (anyLevelItemsIndeterminate || someLevelItemsChecked) {
                updatedCheckboxes[item.id] = "indeterminate";
              } else {
                updatedCheckboxes[item.id] = false;
              }
            }
          });
        }
   
        updateParentCheckboxes(categoryId);
   
        return updatedCheckboxes;
      });
    
  };

  // function for handling the check box in the searchResult
  const handleSearchResultCheckboxClick = (event, result) => {
    // console.log("selectedIds", selectedIds);
    event.stopPropagation();
    //  setSearchValue("");

    const updatedResults = searchResults.map((r) =>
      r.id === result.id ? { ...r, checked: !r.checked } : r
    );
    setSearchResults(updatedResults);

    // Update selectedCheckboxes state for cascader

    const categoryId = result.id;
    handleSetSelectedCheckBoxes(categoryId);
  };

  // Handle click outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleCascaderRef.current &&
        toggleCascaderRef.current.contains(event.target)
      ) {
        if (isCascaderVisible) {
          setIsCascaderVisible(false);
        } // Toggle cascader visibility
        return;
      }

      // Check if the click is on the cascader container
      if (cascaderRef.current && cascaderRef.current.contains(event.target)) {
        setSearchValue("");
        setIsDropDownVisible(false);
        return;
      }

      // Check if the click is on the search bar
      if (searchBarRef.current && searchBarRef.current.contains(event.target)) {
        return;
      }

      // Check if the click is on the dropdown
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return;
      }

      // Close both cascader and dropdown if clicking outside
      setSearchValue("");
      setIsCascaderVisible(false);
      setIsDropDownVisible(false);
    };

    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (cascaderRef.current) {
      cascaderRef.current.scrollLeft = cascaderRef.current.scrollWidth;
    }
  }, [data, searchValue, selectedPath, selectedCheckboxes, searchResults]);   

  // console.log("searchResults: ", searchResults);

  return (
    <div style={{ position: "absolute" }}>
      <div  ref={cascaderRef}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative", display: "flex" }}>
            <input
              ref={searchBarRef}
              id="search-bar"
              type="text"
              value={searchValue}
              onChange={handleSearchInputChange}
              autoComplete="off"
              placeholder={placeholder}
              style={{
                flexGrow: 2,
                position: "relative",
                border: "1px solid #3E5056",
                borderRadius: "8px",                
                backgroundColor: "#1C2427",
                autocomplete:"off",
                color: "white",
                fontSize:'12px',
                padding:'5px',
                outline:'none',
              }}
            />
            <button
              ref={toggleCascaderRef}
              onClick={toggleCascaderVisibility}
              className="cascader-toggle-button"
              style={{
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent",
                border: "none",
                color: "#D6FF41",
                cursor: "pointer",
                fontSize:'17px'
              }}
            >
              {isCascaderVisible ? "▲" : "▼"}
            </button>
            {searchResults.length > 0 && isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "black",
                  // border: "1px solid #3E5056",
                  // border: "1px solid red",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  color: "white",
                }}
              >
                <div className="flex mx-1 my-1">
                   <div onClick={handleSearchResultAllClick}>
                    {SelectAllIcon ? (
                      <CheckBoxOutlineBlankIcon
                        sx={{
                          color: "#d6ff41",
                          fontSize: "16px",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <CheckBoxIcon
                        sx={{
                          color: "#d6ff41",
                          fontSize: "16px",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div> 
                   <div
                    style={{
                      fontSize: "15px",

                      text: "bold",
                    }}
                  >
                    Select All
                  </div> 
                </div>
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    ref={searchResultsContainerRef}
                    style={{
                      padding: "4px",
                      cursor: "pointer",
                      display: "flex",
                      className: "search-result",
                      // border: "1px solid red"
                    }}
                    // onClick={() => handleSearchResultClick(result)}
                  >
                    <div
                      ref={searchListIconRef}
                      className="search-result-icon"
                      onClick={(event) =>
                        handleSearchResultCheckboxClick(event, result)
                      }
                    >
                      {selectedIds.includes(result.id) ? (
                        <CheckBoxIcon
                          sx={{ color: "#d6ff41", fontSize: "16px" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          sx={{ color: "#d6ff41", fontSize: "16px" }}
                        />
                      )}
                    </div>
                    <div style={{ marginLeft: "4px", fontSize: "14px" }}>
                      {result.path.map((part, index) => {
                        const regex = new RegExp(`(${searchValue})`, "gi");
                        const parts = part.split(regex);
                        return (
                          <span key={index}>
                            {parts.map((p, i) => (
                              <span
                                key={i}
                                style={{
                                  color:
                                    p.toLowerCase() ===
                                    searchValue.toLowerCase()
                                      ? "#D6FF41"
                                      : "white",
                                  fontWeight:
                                    p.toLowerCase() ===
                                    searchValue.toLowerCase()
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {p}
                              </span>
                            ))}
                            {index < result.path.length - 1 && " / "}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            ) : searchValue &&
              searchResults.length === 0 &&
              isDropDownVisible ? (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: "calc(100% + 4px)",
                  left: 0,
                  backgroundColor: "#1C2427",
                  border: "1px solid #3E5056",
                  borderRadius: "4px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 10,
                  width: "100%",
                  maxHeight: "200px",
                  overflowY: "auto",
                  color: "white",
                  fontSize:"12px"
                }}
              >
                No data
              </div>
            ) : null}
          </div>
        </div>

        {isCascaderVisible && (
          <div 
          style={
           { 
            // width:'25rem',
            
            maxWidth:'25rem',
            
            overflowX:'scroll',
            padding:"4px",
            border:"2px solid #3e5056",
            backgroundColor:"#1c2427",
            Width:'min-content',

           }

          }
          

          ref={cascaderRef}  >
            <Cascader
              data={data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedPath={selectedPath}
              setSelectedPath={setSelectedPath}
              setIdList={setIdList}
              pathName={pathName}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              flattenedData={flattenedData}
              searchResults={searchResults}
            />
          </div>
        )}
      </div>
      
    </div>
  );
};

export default CascaderWrapper;
