import React, { useEffect, useState, useRef } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import "react-multi-date-picker/styles/colors/purple.css"; // Ensure this path is correct
import "./calender.css";

// import '../../public/assets/css/cal.css';

const Calender = ({
  setDefaultStartDate,
  setDefaultEndDate,
  DefaultStartDate,
  DefaultEndDate,
  startDate,
  endDate,
}) => {
  //   console.log("DefaultEndDate: ", DefaultEndDate);
  //   console.log("DefaultStartDate: ", DefaultStartDate);

  function splitDate(dateString) {
    // Split the date string by the '-' character
    const dateParts = dateString.split("-");

    // Convert the month and day to integers to remove leading zeros
    dateParts[1] = parseInt(dateParts[1], 10).toString();
    dateParts[2] = parseInt(dateParts[2], 10).toString();

    // Return the array with the year, month, and day
    return dateParts;
  }

  function getEndOfMonth(dateString) {
    // Split the date string into year, month, and day
    const [year, month, day] = dateString.split("-").map(Number);

    // Create a new Date object with the given year and month (day 0 is the last day of the previous month)
    const endOfMonth = new Date(year, month, 0);

    // Get the last day of the month
    const lastDayOfMonth = endOfMonth.getDate();

    // Format the last day of the month as yyyy-mm-dd
    const endOfMonthString = `${year}-${month
      .toString()
      .padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;

    return endOfMonthString;
  }

  const [value, setValue] = useState([
    new DateObject({
      year: splitDate(DefaultStartDate)[0],
      month: splitDate(DefaultStartDate)[1],
      day: splitDate(DefaultStartDate)[2],
    }),
    new DateObject({
      year: splitDate(DefaultEndDate)[0],
      month: splitDate(DefaultEndDate)[1],
      day: splitDate(DefaultEndDate)[2],
    }),
  ]);

  useEffect(() => {
    setValue([
      new DateObject({
        year: splitDate(DefaultStartDate)[0],
        month: splitDate(DefaultStartDate)[1],
        day: splitDate(DefaultStartDate)[2],
      }),
      new DateObject({
        year: splitDate(DefaultEndDate)[0],
        month: splitDate(DefaultEndDate)[1],
        day: splitDate(DefaultEndDate)[2],
      }),
    ]);
  }, [DefaultEndDate, DefaultStartDate]);

  // console.log("value: ", value);

  function formatDate(date) {
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    return year + "-" + month + "-" + day;
  }

  useEffect(() => {
    setDefaultStartDate(formatDate(new Date(value[0])));
    setDefaultEndDate(getEndOfMonth(formatDate(new Date(value[1]))));
    // value.map((item) => {
    //   console.log("item: ", formatDate(new Date(item)));
    //   return "";
    // });
  }, [value]);

  const inputRef = useRef(null);
  const datePickerRef = useRef(null);

  const handleChange = (value) => {
    setValue(value);
    if (value.length === 2) {
      inputRef.current.blur();
      datePickerRef.current.closeCalendar();
    }
  };

  function CustomInput({ onFocus, value, onChange }) {
    // const inputRef = useRef(null);

    return (
      <div
        className="custom-input-box"
        style={{
          display: "flex",
          border: "1px solid #3e5056",
          borderRadius: "4px",
          alignItems: "center",
          padding: "0 0.4rem",
        }}
      >
        <input
          ref={inputRef}
          onFocus={onFocus}
          value={value}
          onChange={onChange}
          style={{
            backgroundColor: "#1c2427",
            color: "white",
            border: "none",
            outline: "none",
            width: "180px",
          }}
        />
        <CalendarMonthIcon
          sx={{ color: "white", cursor: "pointer", fontSize: "18px" }}
          onClick={() => inputRef.current.focus()}
        />
      </div>
    );
  }

  return (
    <DatePicker
      className="custom-calendar"
      value={value}
      // onChange={setValue}
      onChange={handleChange}
      onlyMonthPicker
      range
      // plugins={[<DatePanel />]}
      dateSeparator=" - "
      render={<CustomInput />}
      minDate={
        new DateObject({
          year: splitDate(startDate)[0],
          month: splitDate(startDate)[1],
          day: splitDate(startDate)[2],
        })
      }
      maxDate={
        new DateObject({
          year: splitDate(endDate)[0],
          month: splitDate(endDate)[1],
          day: splitDate(endDate)[2],
        })
      }
      months={[
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]}
      hideMonth={true}
      ref={datePickerRef}
    />
  );
};

export default Calender;
