import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { setToken } from "../redux/actions/TokenAction";
import { useDispatch } from "react-redux";
import axios from "../axios";
import Loader from "../Components/Loader";
import BaseUrl from "../BaseUrl";
import ReactGA from 'react-ga';

//LOGO
import LogoMark from "../images/logo.png";
// import LogoMark from "../images/wave.png";
import LavenderLogo from "../images/logo-c1.png";
import SapphireLogo from "../images/logo-c3.png";
import TealLogo from "../images/logo-c5.png";
import CyanLogo from "../images/logo-c7.png";
import GoldenrodLogo from "../images/logo-b4.png";


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState({
    Username: "",
    Password: "",
    TermsAndConditions: false,
  });
  const [usernameError, setUsernameError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [termsAndConditionsErr, setTermsAndConditionsErr] = useState();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  const handleValidation = () => {
    setUsernameError("");
    setPasswordError("");
    var flag = true;
    if (users.Username === "") {
      setUsernameError("Please enter username");
      flag = false;
    }
    if (users.Password === "") {
      setPasswordError("Please enter password");
      flag = false;
    }
    if (users.TermsAndConditions === false) {
      setTermsAndConditionsErr("Accept terms and conditions");
      flag = false;
    }
    return flag;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const flag = handleValidation();

    ReactGA.event({
      category: users.Username,
      action: "Login User",
      label: "Login",
      value: users.Password
    })

    if (flag) {
      setLoader(true);
      axios
        .post("login/", {
          username: users.Username,
          password: users.Password,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            localStorage.setItem("TokenSkewb", response.data.data.token);
            localStorage.setItem("NameSkewb", response.data.data.Name);
            localStorage.setItem("is_superuser", response.data.data.is_superuser);
            if (localStorage.getItem('CurrentTheme') === null) {
              localStorage.setItem('CurrentTheme', "Dark")
            }

            dispatch(setToken(response.data.data.token));
            // window.location.replace('http://43.204.176.201/chart/portfolio.html');

            // navigate("/home");
            navigate("/portfolio");
            setLoader(false);
          }
        }).catch((data) => {
          setLoader(false);
        });
    }
  };

  // console.log("image url: ", `${BaseUrl}/logo/logo-light.png?v=${new Date().getTime()}`);
  return (
    <>
      {loader ? <Loader /> : null}
      {/* <ToastContainer theme="colored" /> */}
      {/* <div className="bg-overlay"></div> */}
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'
      }}>
        <div className="wrapper-page my-5">
          <div className="container-fluid p-0 m-0 max-w-100">
            {/* <div className="text-center m-4">
              <div className="m-3 d-flex align-items-center justify-content-center">
                <img src={BaseUrl+"/logo/Brand Logo Main Page.png"} alt="logo" style={{ width: '20rem' }} />
              </div>
            </div> */}
            <div className="card" >
              <div className="card-body card-gradient" >
                <div className="text-center mt-4">
                  <div className="mb-2">
                    <img src={localStorage.getItem("CurrentTheme") !== null && localStorage.getItem("CurrentTheme") !== "null" ? localStorage.getItem("CurrentTheme") === "Dark" ? `${BaseUrl}/logo/logo-light.png?v=${new Date().getTime()}` : BaseUrl + "/logo/logo-dark.png" : BaseUrl + "/logo/logo-light.png"} alt="logo" style={{ width: '20rem' }} />
                  </div>
                  {/* <div className="">
                    <p className="mb-1 text-white">Powered by</p>
                    <img src={Logo} alt="logo" style={{ width: "25%" }} />
                  </div> */}
                </div>
                <div className="p-3">
                  <form className="form-horizontal mt-3" onSubmit={handleSubmit}>
                    <div className="form-group mb-3 row">
                      <div className="col-12">
                        <h3>
                          <label className="login-lable" htmlFor="username">
                            Username
                          </label>
                        </h3>
                        <input
                          className="form-control login-input"
                          type="text"
                          placeholder="Username"
                          name="username"
                          onChange={(e) =>
                            setUsers({ ...users, Username: e.target.value })
                          }
                          value={users.Username}
                        />
                      </div>
                      <p className="error">{usernameError}</p>
                    </div>

                    <div className="form-group mb-3 row">
                      <div className="col-12">
                        <h3>
                          <label className="login-lable">Password</label>
                        </h3>
                        <input
                          className="form-control login-input"
                          placeholder="Password"
                          type="password"
                          name="password"
                          onChange={(e) =>
                            setUsers({ ...users, Password: e.target.value })
                          }
                          value={users.Password}
                        />
                      </div>
                      <p className="error">{passwordError}</p>
                    </div>

                    <div className="form-group mb-3 row">
                      <div className="col-12">
                        <div className="custom-control custom-checkbox d-flex justify-content-center">
                          <input
                            type="checkbox"
                            className="custom-control-input login-checkbox"
                            name="check"
                            checked={users.TermsAndConditions}
                            onChange={(e) =>
                              setUsers({
                                ...users,
                                TermsAndConditions: e.target.checked,
                              })
                            }
                          />
                          <label className="form-label ms-1 login-link">
                            I agree to Terms &amp; Conditions
                          </label>
                        </div>
                        <p className="error" style={{ textAlign: "center" }}>
                          {termsAndConditionsErr}
                        </p>
                      </div>
                    </div>

                    <div className="form-group mb-3 text-center row mt-3 pt-1">
                      <div className="col-12">
                        <button className="btn btn-info" type="submit" >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="text-center">
                  <p className="mb-1 login-footer">Powered by&nbsp; <img src={localStorage.getItem("CurrentTheme") === "Dark" ?
                    LogoMark :
                    localStorage.getItem("ColorCode") === "Lavender" ?
                      LavenderLogo :
                      localStorage.getItem("ColorCode") === "Sapphire" ?
                        SapphireLogo :
                        localStorage.getItem("ColorCode") === "Teal" ?
                          TealLogo :
                          localStorage.getItem("ColorCode") === "Cyan" ?
                            CyanLogo :
                            localStorage.getItem("ColorCode") === "Goldenrod" ?
                              GoldenrodLogo : LogoMark
                  } alt="logo" style={{ width: "25%"}} /></p>
                  {/* <h4 style={{fontWeight: 700, position: "absolute", bottom: 7, left: "50%", transform: "translateX(-50%)"}}>Wavemaker</h4> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
