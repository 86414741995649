import React, { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { useLocation, useNavigate } from "react-router-dom";

import ColumnChart from "../../Charts/ColumnChart";
import LineChart from "../../Charts/LineChart";
import StackChart from "../../Charts/StackChart";
import WaterfallChart from "../../Charts/WaterfallChart";
import BarChart from "../../Charts/BarChart";
import Legends from "./Legends";
import circle from "../../images/c.png";
import tringle from "../../images/t.png";
import PopUp from "../../Components/PopUp";
import { Tooltip } from "react-tooltip";

const OptimiseForm = (props) => {
  const refGraphDiv = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  const [ShowPopup, setShowPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [Brands, setBrands] = useState([]);
  // console.log("Analyse: ", Analyse);

  const [Brand, setBrand] = useState("");
  const [BrandName, setBrandName] = useState("");
  const [KPIAll, setKPIAll] = useState([]);
  const [KPI, setKPI] = useState("");
  const [TimeFrameAll, setTimeFrameAll] = useState([]);
  const [TimeFrame, setTimeFrame] = useState("");
  const [TimePeriodAll, setTimePeriodAll] = useState([]);
  const [TimePeriod, setTimePeriod] = useState("");
  const [FixedBudgetAbsolute, setFixedBudgetAbsolute] = useState("");
  const [TargetGrowthAbsolute, setTargetGrowthAbsolute] = useState("");
  const [TargetGrowthPercentage, setTargetGrowthPercentage] = useState("");
  // console.log("TargetGrowthPercentage: ", TargetGrowthPercentage);
  const [PlanName, setPlanName] = useState("");
  const [OptimizeFor, setOptimizeFor] = useState("budget");
  const [FixedBudgetAbsoluteError, setFixedBudgetAbsoluteError] = useState("");
  const [TargetGrowthAbsoluteError, setTargetGrowthAbsoluteError] =
    useState("");
  const [BrandError, setBrandError] = useState("");
  const [TimeFrameError, setTimeFrameError] = useState("");
  const [KPIError, setKPIError] = useState("");
  const [TimePeriodError, setTimePeriodError] = useState("");
  const [PlanNameError, setPlanNameError] = useState("");
  const [Notes, setNotes] = useState("");
  const [BudgetUnit, setBudgetUnit] = useState("");
  const [SpendUnit, setSpendUnit] = useState("");
  const [Sales, setSales] = useState("");

  const [Toggle, setToggle] = useState(false);
  const [ChartData, setChartData] = useState({});
  const [CaraouselChart, setCaraouselChart] = useState("");
  const size = 3;
  const [Count, setCount] = useState(size);

  const [LineFilter, setLineFilter] = useState("");
  const [LineData, setLineData] = useState({});

  // validations for min and max budget/ growth

  const [min_budget, setMin_budget] = useState("");
  // console.log("min_budget: ", min_budget);
  const [max_budget, setMax_budget] = useState("");
  // console.log("max_budget: ", max_budget);

  const [budgetError, setBudgetError] = useState("");

  const [min_growth, setMin_growth] = useState("");
  // console.log("min_growth: ", min_growth);
  const [max_growth, setMax_growth] = useState("");
  // console.log("max_growth: ", max_growth);

  const [growthError, setGrowthError] = useState("");

  const [Budget, setBudget] = useState("");

  // const [clickedOptmize, setClickedOptimize] = useState(false);

  // useEffect(() => {
  //   setClickedOptimize(false);
  // }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (LineFilter !== "") {
      setLineData(ChartData.data[LineFilter]);
    }
  }, [LineFilter, ChartData]);

  useEffect(() => {
    setFixedBudgetAbsolute("");
    setTargetGrowthAbsolute("");
    setTargetGrowthPercentage("");
  }, [KPI]);

  useEffect(() => {
    if (props.CaraouselChartdict.length > 0) {
      setCaraouselChart(0);
    }
  }, [props.CaraouselChartdict]);

  useEffect(() => {
    if (CaraouselChart !== "") {
      setChartData(props.CaraouselChartdict[CaraouselChart] || {});

      if (
        props.CaraouselChartdict[CaraouselChart] &&
        props.CaraouselChartdict[CaraouselChart].type === "line"
      ) {
        setLineFilter(0);
      }
    }
  }, [CaraouselChart, props.CaraouselChartdict]);

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_optimiser_brand_kpi/", {})
      .then((response) => {
        // console.log(response.data.data)
        setBrands(response.data.data);
        // setBrand(response.data.data[0].brand_short);
        setBrand(Analyse.Brand);
        // response.data.data.map((item) => {
        //   if (item.brand_short === Analyse.Brand) {
        //     setBrand(item.brand_short);
        //     setBrandName(item.brand_name)
        //     setKPIAll(item.kpi);
        //     item.kpi.map(kpi => {
        //       if(kpi.kpi_short_code === Analyse.KPI) {
        //         setKPI(kpi.kpi_short_code);
        //       }
        //       return "";
        //     })
        //   }
        //   return "";
        // });

        setBrandName(response.data.data[0].brand_name);
        setKPIAll(response.data.data[0].kpi);
        // setKPI(response.data.data[0].kpi[0].kpi_short_code);
        setKPI(Analyse.KPI);

        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });

    return () => {};
  }, [Analyse.Brand, Analyse.KPI]);

  // useEffect(() => {
  //   if (Brand) {
  //     setLoader(true);
  //     axios
  //       .post("get_optimiser_kpi/", { "brand": Brand })
  //       .then((response) => {
  //         setKPIAll(response.data.data);
  //         setKPI(response.data.data[0].kpi_short_code);
  //         setLoader(false);
  //       })
  //       .catch((data) => {
  //         setLoader(false);
  //       });
  //   }
  // }, [Brand]);

  const BrandHandler = (e) => {
    const value = e.target.value;
    let brand_name;
    let kpi;
    let kpi_name;
    for (var i = 0; i < Brands.length; i++) {
      if (value === Brands[i].brand_short) {
        // console.log(Brands[i].kpi)
        brand_name = Brands[i].brand_name;
        setKPIAll(Brands[i].kpi);
        // setKPI(Brands[i].kpi[0].kpi_short_code);
        setKPI(Analyse.KPI);
        kpi = Brands[i].kpi[0].kpi_short_code;
        kpi_name = Brands[i].kpi[0].kpi_display_name;
        break;
      }
    }
    setBrandName(brand_name);
    // setBrandName(Analyse.Brand);
    setBrand(value);
    // setBrand(Analyse.Brand);

    const AnalyseNew = {
      KPI: kpi,
      KPIName: kpi_name,
      Brand: value,
      BrandName: brand_name,
      Menubar: Analyse.Menubar,
      AnalyticsTool: Analyse.AnalyticsTool,
    };

    localStorage.setItem("Analyse", JSON.stringify(AnalyseNew));
  };

  const KPIHandler = (value) => {
    setKPI(value);
    // setKPI(Analyse.KPI);

    let kpi_name;
    for (var i = 0; i < KPIAll.length; i++) {
      if (value === KPIAll[i].kpi_short_code) {
        kpi_name = KPIAll[i].kpi_display_name;
      }
    }
    console.log(kpi_name);

    const AnalyseNew = {
      KPI: value,
      KPIName: kpi_name,
      Brand: Brand,
      BrandName: BrandName,
      Menubar: Analyse.Menubar,
      AnalyticsTool: Analyse.AnalyticsTool,
    };

    localStorage.setItem("Analyse", JSON.stringify(AnalyseNew));
  };

  const TimePeriodHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimePeriodAll.length; i++) {
      if (value === TimePeriodAll[i].name) {
        setFixedBudgetAbsolute(TimePeriodAll[i].budget);
        setBudget(TimePeriodAll[i].budget);
        setBudgetUnit(TimePeriodAll[i].budget_unit);
        setSpendUnit(TimePeriodAll[i].sales_unit);
        setSales(TimePeriodAll[i].sales);

        setMin_budget(TimePeriodAll[i].min_budget);
        setMax_budget(TimePeriodAll[i].max_budget);
        setMin_growth(TimePeriodAll[i].min_growth);
        setMax_growth(TimePeriodAll[i].max_growth);
        break;
      }
    }
    setTimePeriod(value);
  };
  const TimeFrameHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFrameAll.length; i++) {
      if (value === TimeFrameAll[i].key) {
        setTimePeriodAll(TimeFrameAll[i].dates);
        setTimePeriod(TimeFrameAll[i].dates[0].name);
        setFixedBudgetAbsolute(TimeFrameAll[i].dates[0].budget);
        setBudget(TimeFrameAll[i].dates[0].budget);
        setBudgetUnit(TimeFrameAll[i].dates[0].budget_unit);
        setSpendUnit(TimeFrameAll[i].dates[0].sales_unit);
        setSales(TimeFrameAll[i].dates[0].sales);

        setMin_budget(TimeFrameAll[i].dates[0].min_budget);
        setMax_budget(TimeFrameAll[i].dates[0].max_budget);
        setMin_growth(TimeFrameAll[i].dates[0].min_growth);
        setMax_growth(TimeFrameAll[i].dates[0].max_growth);
        break;
      }
    }
    setTimeFrame(value);
  };
  useEffect(() => {
    if (Brand && KPI) {
      setLoader(true);
      axios
        .post("get_opt_timeframe/", { brand: Analyse.Brand, kpi: Analyse.KPI })
        .then((response) => {
          // console.log(response.data.data[0].key);
          setTimeFrameAll(response.data.data);
          setTimeFrame(response.data.data[0].key);
          setTimePeriodAll(response.data.data[0].dates);
          setTimePeriod(response.data.data[0].dates[0].name);
          setFixedBudgetAbsolute(response.data.data[0].dates[0].budget);
          setBudget(response.data.data[0].dates[0].budget);
          setBudgetUnit(response.data.data[0].dates[0].budget_unit);
          setSpendUnit(response.data.data[0].dates[0].sales_unit);
          setSales(response.data.data[0].dates[0].sales);
          setNotes(response.data.notes);

          setMin_budget(response.data.data[0].dates[0].min_budget);
          setMax_budget(response.data.data[0].dates[0].max_budget);
          setMin_growth(response.data.data[0].dates[0].min_growth);
          setMax_growth(response.data.data[0].dates[0].max_growth);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [Brand, KPI, Analyse.Brand, Analyse.KPI]);

  const validate = () => {
    setFixedBudgetAbsoluteError("");
    setTargetGrowthAbsoluteError("");
    setPlanNameError("");
    var flag = true;
    if (Brand === "") {
      setBrandError("Please select brand");
      flag = false;
    }

    if (KPI === "") {
      setKPIError("Please select KPI");
      flag = false;
    }

    if (TimePeriod === "") {
      setTimePeriodError("Please select time period");
      flag = false;
    }

    if (TimeFrame === "") {
      setTimeFrameError("Please select timeframe");
      flag = false;
    }
    if (OptimizeFor === "budget") {
      if (FixedBudgetAbsolute === "") {
        setFixedBudgetAbsoluteError("Please enter fixed budget");
        flag = false;
      }
    }

    if (OptimizeFor === "growth" && TargetGrowthPercentage === "") {
      if (TargetGrowthAbsolute === "") {
        setTargetGrowthAbsoluteError("Please enter target growth");
        flag = false;
      }
    }

    // if (PlanName === "") {
    //   setPlanNameError("Please enter plan name");
    //   flag = false;
    // }

    return flag;
  };

  const OptimiseHandler = (e) => {
    e.preventDefault();
    if (validate() && growthError === "" && budgetError === "") {
      const parameter = {
        brand: Brand,
        // brand: Analyse.Brand,
        kpi: KPI,
        // kpi: Analyse.KPI,
        timeframe: TimeFrame,
        timeperiod: TimePeriod,
        optimization_type: OptimizeFor,
        theme: localStorage.getItem("CurrentTheme"),
        budget:
          OptimizeFor === "growth"
            ? TargetGrowthPercentage !== ""
              ? parseFloat(TargetGrowthPercentage)
              : parseFloat(TargetGrowthAbsolute)
            : parseFloat(FixedBudgetAbsolute),
        result_name: PlanName,
      };
      props.OptimizedData(parameter);
      // setClickedOptimize(true);
    }
  };

  const GrowthPercentageHandler = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return false;
    }

    if (Sales) {
      setTargetGrowthAbsolute(((value * Sales) / 100).toFixed(2));
    }
    // if(value < 2 || value > 30) {
    //   console.log("growth error", value);
    // }
    // setTargetGrowthPercentage(value);
    if (value < min_growth || value > max_growth) {
      setGrowthError("Growth is out of range");
      setTargetGrowthPercentage(value);
    } else {
      setTargetGrowthPercentage(value);
      setGrowthError("");
    }
  };

  useEffect(() => {
    if (
      TargetGrowthPercentage < min_growth ||
      TargetGrowthPercentage > max_growth
    ) {
      setGrowthError("Growth is out of range");
    } else {
      setGrowthError("");
    }
  }, [TargetGrowthPercentage, min_growth, max_growth]);

  const GrowthAbsoluteHandler = (e) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return false;
    }
    if (Sales) {
      setTargetGrowthPercentage(((value * 100) / Sales).toFixed(2));
    }
    if (value < min_growth || value > max_growth) {
      setGrowthError("Growth is out of range");
      setTargetGrowthAbsolute(value);
    } else {
      setTargetGrowthAbsolute(value);
      setGrowthError("");
    }
  };

  const [ChartHeight, setChartHeight] = useState("");
  const [ChartWidth, setChartWidth] = useState("");
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
      // console.log(refGraphDiv.current.clientHeight);
      setChartWidth(refGraphDiv.current.clientWidth);
    }
  }, [Toggle]);
  // console.log("ChartHeight: ", ChartHeight);

  return (
    <>
      {loader ? <Loader /> : null}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Skewb Simulator */}
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                          return "/" + item.key === location.pathname
                            ? item.value
                            : null;
                        })
                      : null}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {ShowPopup ? (
            <PopUp setShowPopup={setShowPopup} height="70%">
              {JSON.stringify(ChartData) !== "{}" ? (
                <>
                  {ChartData.type === "column" &&
                  JSON.stringify(ChartData.data) !== "{}" ? (
                    <ColumnChart
                      data={ChartData.data}
                      ChartHeight={"100%"}
                      ChartWidth={"100%"}
                    />
                  ) : null}
                  {ChartData.type === "line" &&
                  JSON.stringify(ChartData.data) !== "{}" ? (
                    <LineChart
                      data={ChartData.data[LineFilter]}
                      ChartHeight={"50%"}
                      ChartWidth={"100%"}
                    />
                  ) : null}
                  {ChartData.type === "stack" &&
                  JSON.stringify(ChartData.data) !== "{}" ? (
                    <StackChart
                      data={ChartData.data}
                      ChartHeight={"100%"}
                      ChartWidth={"100%"}
                    />
                  ) : null}
                  {ChartData.type === "waterfall" &&
                  JSON.stringify(ChartData.data) !== "{}" ? (
                    <WaterfallChart
                      data={ChartData.data}
                      ChartHeight={"90%"}
                      ChartWidth={"100%"}
                    />
                  ) : null}
                  {ChartData.type === "bar" &&
                  JSON.stringify(ChartData.data) !== "{}" ? (
                    <BarChart
                      data={ChartData.data}
                      ChartHeight={"100%"}
                      ChartWidth={"100%"}
                    />
                  ) : null}
                </>
              ) : null}
            </PopUp>
          ) : null}
          <div className="container-fluid main-content-w ">
            <div className="row ">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 ">
                <div className="card mb-0 optimizer-table-height">
                  <div className="card-body">
                    <form method="post" onSubmit={OptimiseHandler}>
                      <div className="simulator-card">
                        <div className="row">
                          <div className="col-sm-12">
                            <p className="card-title-lable text-left pb-24">
                              SELECT YOUR BRAND
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <label className="simulator-card-lable">
                              Select Brand
                            </label>
                            <select
                              className="form-select custom-input-box"
                              // value={Brand}
                              value={Analyse.Brand}
                              onChange={BrandHandler}
                            >
                              {/* {Brands.map((item) => {
                                return (
                                  <option
                                    key={item.brand_short}
                                    value={item.brand_short}
                                  >
                                    {item.brand_name}
                                  </option>
                                );
                              })} */}
                              <option value={Analyse.Brand}>
                                {Analyse.Brand}
                              </option>
                            </select>
                            <span className="error">{BrandError}</span>
                          </div>
                          <div className="col-sm-6">
                            <label className="simulator-card-lable">
                              Select KPI
                            </label>
                            <select
                              className="form-select custom-input-box"
                              onChange={(e) => {
                                KPIHandler(e.target.value);
                              }}
                              // value={KPI}
                              value={Analyse.KPI}
                            >
                              {/* {KPIAll.map((item) => {
                                return (
                                  <option
                                    value={item.kpi_short_code}
                                    key={item.kpi_short_code}
                                  >
                                    {item.kpi_display_name}
                                  </option>
                                );
                              })} */}
                              <option value={Analyse.KPI}>{Analyse.KPI}</option>
                            </select>
                            <span className="error">{KPIError}</span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="simulator-card">
                        <div className="row">
                          <div className="col-sm-12">
                            <p className="card-title-lable text-left pb-24">
                              OPTIMIZATION PARAMETER
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <label className="simulator-card-lable">
                              View Options
                            </label>
                            <select
                              className="form-select custom-input-box"
                              value={TimeFrame}
                              onChange={TimeFrameHandler}
                            >
                              {TimeFrameAll.map((item) => {
                                return (
                                  <option key={item.key} value={item.key}>
                                    {item.value}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="error">{TimeFrameError}</span>
                          </div>
                          <div className="col-sm-6">
                            <label className="simulator-card-lable">
                              Time Period
                            </label>
                            <select
                              className="form-select custom-input-box"
                              value={TimePeriod}
                              onChange={TimePeriodHandler}
                            >
                              {TimePeriodAll.map((item) => {
                                return (
                                  <option key={item.name} value={item.name}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span>{TimePeriodError}</span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="simulator-card">
                        <div className="row">
                          <div className="col-sm-12">
                            <p className="card-title-lable text-left  pb-24">
                              OPTIMISE FOR
                            </p>
                          </div>
                          <div className="col-sm-12 pb-2 pt-0">
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="form-check mb-2 mt-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="OptimizeFor"
                                    value="budget"
                                    checked={OptimizeFor === "budget"}
                                    onChange={(e) => {
                                      setOptimizeFor(e.target.value);
                                      setTargetGrowthAbsolute("");
                                      setTargetGrowthPercentage("");
                                      setGrowthError("");
                                      setTargetGrowthAbsoluteError("");
                                    }}
                                  />
                                  <label
                                    className="form-check-label radio-lable"
                                    htmlFor="OptimizeFor"
                                  >
                                    Fixed Budget
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  disabled={
                                    OptimizeFor === "budget" ? false : true
                                  }
                                  className="form-control custom-input-box"
                                  type="text"
                                  placeholder="Fixed Budget"
                                  value={FixedBudgetAbsolute}
                                  onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                      if (
                                        e.target.value < min_budget ||
                                        e.target.value > max_budget
                                      ) {
                                        setBudgetError(
                                          "Budget is out of range"
                                        );
                                        setFixedBudgetAbsolute(e.target.value);
                                      } else {
                                        setFixedBudgetAbsolute(e.target.value);
                                        setBudgetError("");
                                        setFixedBudgetAbsoluteError("");
                                      }
                                    }
                                  }}
                                />
                                <span className="ms">in {BudgetUnit}</span>
                                <span style={{ color: "red" }}>
                                  {budgetError}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <span className="error">
                              {FixedBudgetAbsoluteError}
                            </span>
                          </div>
                          <div className="col-sm-12 pb-2 pt-2">
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="form-check mb-2 mt-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="OptimizeFor"
                                    value="growth"
                                    checked={OptimizeFor === "growth"}
                                    onChange={(e) => {
                                      setOptimizeFor(e.target.value);
                                      setFixedBudgetAbsolute(Budget);
                                      setBudgetError("");
                                      setFixedBudgetAbsoluteError("");
                                    }}
                                  />
                                  <label
                                    className="form-check-label radio-lable"
                                    htmlFor="OptimizeFor"
                                  >
                                    Target Growth
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  className="form-control custom-input-box"
                                  type="text"
                                  placeholder="Target Growth Percentage"
                                  value={TargetGrowthPercentage}
                                  name="TargetGrowthPercentage"
                                  onChange={GrowthPercentageHandler}
                                  disabled={
                                    OptimizeFor === "growth" ? false : true
                                  }
                                />
                                <span className="ms">%</span>
                                <span style={{ color: "red" }}>
                                  {growthError}
                                </span>
                              </div>
                              <div className="col-sm-1">
                                <p className="card-title-lable mb-2 mt-2">OR</p>
                              </div>
                              <div className="col-sm-4">
                                <input
                                  className="form-control custom-input-box"
                                  type="text"
                                  placeholder="Target Growth Absolute"
                                  value={TargetGrowthAbsolute}
                                  onChange={GrowthAbsoluteHandler}
                                  disabled={
                                    OptimizeFor === "growth" ? false : true
                                  }
                                />
                                <span className="ms">in {SpendUnit}</span>
                                <span style={{ color: "red" }}>
                                  {growthError}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <span className="error">
                              {TargetGrowthAbsoluteError}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <hr /> */}
                      <div
                        className="simulator-card"
                        style={{ display: "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <p className="card-title-lable text-left pb-24">
                              SET PLAN NAME
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <label className="simulator-card-lable">Name</label>
                            <input
                              type="text"
                              className="form-select custom-input-box"
                              value={PlanName}
                              onChange={(e) => setPlanName(e.target.value)}
                            />
                            <span className="error">{PlanNameError}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 pb-1 pt-3 text-center d-flex justify-content-center">
                        <button
                          className="btn simulator-btn-info mx-2 custom-input-box"
                          type="submit"
                        >
                          Optimise
                        </button>
                        {/* <button className="btn simulator-btn-clear mx-2" type="button" ></button> */}
                        <button
                          className="btn simulator-btn-info mx-2 custom-input-box"
                          type="button"
                          onClick={() => {
                            navigate("/optimize");
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {props.clickedOptmize &&
              props.PreviousData.length > 0 &&
              JSON.stringify(props.NewData) !== "{}" &&
              JSON.stringify(props.NewFixedData) !== "{}" ? (
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 ">
                  <div className="card custom-summary optimizer-table-height mb-0">
                    <div className="card-body p-0">
                      <div className="card-3">
                        <div className="custom-header mb-1">
                          <span
                            className="header-title cursor-pointer"
                            onClick={() => setToggle(!Toggle)}
                          >
                            <h3 className="data-spends my-2 px-2">Summary</h3>
                            {Toggle ? (
                              <h5 className="m-0">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                              </h5>
                            ) : (
                              <h5 className="m-0">
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                              </h5>
                            )}
                          </span>
                        </div>

                        <div
                          className={
                            Toggle
                              ? "custom-footer active mb-1"
                              : "custom-footer mb-1"
                          }
                        >
                          <span
                            className="footer-title cursor-pointer"
                            onClick={() => setToggle(!Toggle)}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h3 className="data-spends my-2 px-2">Charts</h3>
                            {!Toggle ? (
                              <h5 className="m-0">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                              </h5>
                            ) : (
                              <h5 className="m-0">
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                              </h5>
                            )}
                          </span>
                        </div>

                        <div
                          className="custom-body py-3"
                          style={{ overflowY: "scroll" }}
                        >
                          {Toggle ? (
                            <>
                              <div className="row">
                                {props.CaraouselChartdict.length > 0 ? (
                                  <>
                                    <div className="col-sm-11 my-1 pb-2 d-flex justify-content-center">
                                      <select
                                        className="form-select custom-input-box"
                                        style={{
                                          border: "1px solid #7D9CA6",
                                          borderRadius: "5px",
                                          fontSize: "16px",
                                          width: "200px",
                                        }}
                                        value={CaraouselChart}
                                        onChange={(e) => {
                                          setLineData({});
                                          setCaraouselChart(e.target.value);
                                        }}
                                      >
                                        {props.CaraouselChartdict.map(
                                          (item, index) => {
                                            return (
                                              <option key={index} value={index}>
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>

                                      {JSON.stringify(ChartData) !== "{}" &&
                                      ChartData.type === "line" ? (
                                        <select
                                          className="form-select custom-input-box"
                                          style={{
                                            border: "1px solid #7D9CA6",
                                            borderRadius: "5px",
                                            fontSize: "16px",
                                            width: "150px",
                                            marginLeft: "5px",
                                          }}
                                          value={LineFilter}
                                          onChange={(e) => {
                                            setLineData({});
                                            setLineFilter(e.target.value);
                                          }}
                                        >
                                          {ChartData.data.map((item, index) => {
                                            return (
                                              <option key={index} value={index}>
                                                {item.filter}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      ) : null}
                                    </div>
                                    <div className="col-sm-1 my-1 pb-2 d-flex justify-content-center">
                                      <span
                                        className="cursor-pointer text-white"
                                        title="Full Screen"
                                        onClick={() => setShowPopup(true)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi-fullscreen"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                                        </svg>
                                      </span>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div
                                    className="custom-chart-height-deepdive"
                                    ref={refGraphDiv}
                                    style={
                                      ChartData.type === "column"
                                        ? { height: ChartHeight - 50 }
                                        : { height: 346 }
                                    }
                                  >
                                    {JSON.stringify(ChartData) !== "{}" ? (
                                      <>
                                        {ChartData.type === "column" &&
                                        JSON.stringify(ChartData.data) !==
                                          "{}" ? (
                                          <ColumnChart
                                            data={ChartData.data}
                                            ChartHeight={ChartHeight}
                                            ChartWidth={ChartWidth}
                                          />
                                        ) : null}
                                        {ChartData.type === "line" &&
                                        JSON.stringify(LineData) !== "{}" ? (
                                          <LineChart
                                            data={LineData}
                                            ChartHeight={ChartHeight}
                                            ChartWidth={ChartWidth}
                                          />
                                        ) : null}
                                        {ChartData.type === "stack" &&
                                        JSON.stringify(ChartData.data) !==
                                          "{}" ? (
                                          <StackChart
                                            data={ChartData.data}
                                            ChartHeight={ChartHeight}
                                            ChartWidth={ChartWidth}
                                          />
                                        ) : null}
                                        {ChartData.type === "waterfall" &&
                                        JSON.stringify(ChartData.data) !==
                                          "{}" ? (
                                          <WaterfallChart
                                            data={ChartData.data}
                                            ChartHeight={ChartHeight}
                                            ChartWidth={ChartWidth}
                                          />
                                        ) : null}
                                        {ChartData.type === "bar" &&
                                        JSON.stringify(ChartData.data) !==
                                          "{}" ? (
                                          <BarChart
                                            data={ChartData.data}
                                            ChartHeight={ChartHeight}
                                            ChartWidth={ChartWidth}
                                          />
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                  {ChartData.type === "column" &&
                                  JSON.stringify(ChartData.data) !== "{}" ? (
                                    <div
                                      style={{
                                        marginTop: "15px",
                                        display: "flex",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {ChartData.data.chartdata.label.map(
                                        (item, index) => {
                                          return (
                                            <Legends
                                              key={index}
                                              Name={item}
                                              Color={
                                                ChartData.data.chartdata.color[
                                                  index
                                                ]
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : null}
                                  {ChartData.type === "line" &&
                                  JSON.stringify(ChartData.data) !== "{}" ? (
                                    <>
                                      <div
                                        className="row ch-movement-legend"
                                        style={{ marginTop: "-15px" }}
                                      >
                                        <div className="col-sm-12">
                                          <ul className="m-0 p-0 text-center">
                                            <li className="px-2">
                                              <div className="d-flex align-items: center">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  class="bi bi-triangle-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"
                                                  />
                                                </svg>
                                                {/* <p className="m-0 legend-title mx-1">Sustenance Point</p> */}
                                                <p className="m-0 legend-title mx-1">
                                                  New Spends
                                                </p>
                                              </div>
                                            </li>
                                            <li className="px-2">
                                              <div className="d-flex">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  class="bi bi-circle-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <circle cx="8" cy="8" r="8" />
                                                </svg>
                                                <p className="m-0 legend-title  mx-1">
                                                  Old Spends
                                                </p>
                                              </div>
                                            </li>
                                            {/* <li className="px-2">
                                            <div className="d-flex">
                                              <img src={tringleCircle} alt="" style={{ width: "20px" }} />
                                              <p className="m-0 legend-title">New &amp; Old Spends</p>
                                            </div>
                                          </li> */}
                                          </ul>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <div className="row justify-content-between">
                                  <div className="col-1 d-flex align-items-center justify-content-center">
                                    {Count > size ? (
                                      <p
                                        onClick={() =>
                                          setCount(Count - size * 2)
                                        }
                                      >
                                        <i
                                          className="fa fa-chevron-left cursor-pointer"
                                          aria-hidden="true"
                                        ></i>
                                      </p>
                                    ) : null}
                                  </div>

                                  {props.CaraouselSummarydict.map(
                                    (item, index) => {
                                      return index % 2 === 0 ? (
                                        index >= Count - size &&
                                        index < Count + size ? (
                                          // (index >= (Count - size)) ?
                                          <div
                                            key={index}
                                            className="col-xs-3 col-sm-3 col-md-4"
                                          >
                                            <div className="col-12">
                                              <div className="card text-center summary-box">
                                                <div
                                                  className={
                                                    parseInt(item.highlight) ===
                                                    1
                                                      ? "card-body card-gradient px-1 active"
                                                      : "card-body card-gradient px-1"
                                                  }
                                                >
                                                  <div className="summary-box-content">
                                                    <h5
                                                      className="py-1 m-0"
                                                      dangerouslySetInnerHTML={{
                                                        __html: item.header,
                                                      }}
                                                      style={{
                                                        fontSize: "13px",
                                                      }}
                                                    ></h5>
                                                    <p className="py-1 m-0 summary-p">
                                                      {item.value}
                                                    </p>
                                                    <p className="p-2 m-0 summary-footnote">
                                                      {item.footnote}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {index <
                                            props.CaraouselSummarydict.length -
                                              1 ? (
                                              <div className="col-12">
                                                {/* {console.log(first)} */}
                                                <div className="card text-center summary-box">
                                                  <div
                                                    className={
                                                      parseInt(
                                                        props
                                                          .CaraouselSummarydict[
                                                          index + 1
                                                        ].highlight
                                                      ) === 1
                                                        ? "card-body card-gradient px-1 active"
                                                        : "card-body card-gradient px-1"
                                                    }
                                                  >
                                                    <div className="summary-box-content">
                                                      <h5
                                                        className="py-1 m-0"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            props
                                                              .CaraouselSummarydict[
                                                              index + 1
                                                            ].header,
                                                        }}
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      ></h5>
                                                      <p className="py-1 m-0 summary-p">
                                                        {
                                                          props
                                                            .CaraouselSummarydict[
                                                            index + 1
                                                          ].value
                                                        }
                                                      </p>
                                                      <p className="p-2 m-0 summary-footnote">
                                                        {
                                                          props
                                                            .CaraouselSummarydict[
                                                            index + 1
                                                          ].footnote
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        ) : null
                                      ) : null;
                                    }
                                  )}

                                  <div className="col-1 d-flex align-items-center justify-content-center">
                                    {props.CaraouselSummarydict.length - size >
                                    Count ? (
                                      <p
                                        onClick={() =>
                                          setCount(Count + size * 2)
                                        }
                                      >
                                        <i
                                          className="fa fa-chevron-right cursor-pointer"
                                          aria-hidden="true"
                                        ></i>
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row">
                            <div className="col-sm-12 pb-1 pt-1 text-center">
                              <button
                                className="btn simulator-btn-info mx-2"
                                type="type"
                                onClick={() => props.setDeepDive(true)}
                              >
                                Deep dive
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {Notes !== "" ? (
                  <>
                    <i
                      className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                      id="clickable"
                    ></i>
                    <Tooltip anchorSelect="#clickable" clickable>
                      {/* <div className="footer-notes">
                          {Notes}
                        </div> */}
                      <div
                        className="footer-notes"
                        dangerouslySetInnerHTML={{ __html: Notes }}
                      ></div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OptimiseForm;
