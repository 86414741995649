import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import accessibility from "highcharts/modules/accessibility";
import configDriven from "../configDriven";

accessibility(Highcharts);

const StackChart2 = (props) => {
  // console.log("stack chart2: ", props.series);

  function getFormattedString(id, obj) {
    if (configDriven.show_display_name) {
      // Split the id by hyphens
      const keys = id.split("-");

      // Map the keys to their corresponding values in the object
      const values = keys.map((key) => obj[key]);

      // Join the values with hyphens and return the result
      return values.join("-");
    } else {
      return id;
    }
  }

  const displayNamesChart = JSON.parse(
    localStorage.getItem("displayNamesChart")
  );

  const chartOptions = {
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    chart: {
      type: "column",
      backgroundColor: "#1c2427",
      height: props.height,
    },
    title: {
      text: props.charttitle,
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },
    xAxis: {
      categories: props.categories,
      title: props.xtitle,
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    yAxis: {
      min: 0,
      // tickInterval: 0.5,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
          fontSize: "14px",
        },
      },
      // stackLabels: {
      //   enabled: true,
      //   style: {
      //     fontWeight: 'bold',
      //     color: 'white'
      //   }
      // },
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    legend: {
      enabled: props.showLegend,
      // enabled: true,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            textOutline: "none", // Remove stroke
          },
        },
        borderWidth: 0,
        // dataLabels: {
        //   enabled: false,
        // },
        point: {
          events: {
            click: function () {
              // console.log("clickedddddddddddd: ", this.series.name);

              // props.DrillDown(this);
              if (!props.NotAllow.includes(this.series.name)) {
                props.DrillDown(this);
              }
            },
            mouseOver: function () {
              // props.NotAllow.map((item) => {
              //   if (this.series.name === item) {
              //     // console.log(this.series.name, item);
              //     this.graphic.element.style.cursor = "not-allowed";
              //   } else {
              //     this.graphic.element.style.cursor = "pointer";
              //   }
              //   return "";
              // });

              if (props.NotAllow.includes(this.series.name)) {
                this.graphic.element.style.cursor = "not-allowed";
              } else {
                this.graphic.element.style.cursor = "pointer";
              }
            },
            mouseOut: function () {
              this.graphic.element.style.cursor = "default";
            },
            // function
          },
        },
      },
    },
    series: props.series,
    tooltip: {
      formatter: function () {
        var series = this.series;
        var spends = series.userOptions.spends[this.point.index];
        var contribution = series.userOptions.contribution[this.point.index];
        // return '<b>' + this.point.category + '</b><br/>' +
        //        series.name + ': ' + this.y + '%<br/>' +
        //        'Spends: ' + spends + '<br>' +
        //        'Contribution: ' + contribution
        return (
          "<b>" +
          this.point.category +
          "</b><br/>" +
          "<b>" +
          getFormattedString(this.series.name, displayNamesChart) +
          "</b>: " +
          this.y +
          "%<br/>" +
          "Spends: " +
          spends +
          "<br>" +
          "Contribution: " +
          contribution
        );
      },
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
  };
  return (
    <div id="trends_chart">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default StackChart2;
