import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more"; // For more chart types
import HighchartsStock from "highcharts/modules/stock"; // For navigator and scrollbar
import Exporting from "highcharts/modules/exporting"; // Import exporting module for download functionality

HighchartsMore(Highcharts);
HighchartsStock(Highcharts);
Exporting(Highcharts); // Initialize exporting module (although we will disable it)

const ChartComponent = ({ data, TrendsHeight }) => {
  const chartRef = useRef(null);
  console.log("data outside tooltip:", data);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      backgroundColor: "#1c2427",
      height: TrendsHeight,
    },
    title: {
      text: "", // Remove "Values" label
    },
    xAxis: {
      type: "datetime",
      labels: {
        style: {
          color: "#ffffff",
        },
        rotation: 0, // Set rotation to 0 to make labels horizontal
        formatter: function () {
          return Highcharts.dateFormat("%Y-%m-%d", this.value);
        },
      },
      gridLineColor: "#ffffff",
      minRange: 24 * 3600 * 1000, // Minimum range of one day
    },
    yAxis: [
      {
        title: {
          text: "", // Remove "Values" label
        },
        labels: {
          style: {
            color: "#ffffff",
          },
        },
        gridLineColor: "#ffffff",
      },
      {
        title: {
          text: "", // Remove "Values" label
        },
        labels: {
          style: {
            color: "#ffffff",
          },
        },
        opposite: true,
        gridLineColor: "#ffffff",
      },
    ],
    tooltip: {
      shared: true,
      backgroundColor: "#ffffff", // Tooltip background color
      style: {
        color: "#000000", // Tooltip content color
      },
      formatter: function () {
        console.log("data inside tooltip:", data);
        let tooltipText =
          "<b>" + Highcharts.dateFormat("%Y-%m-%d", this.x) + "</b><br/>";
        this.points.forEach((point) => {
          // Identify the key for the series name
          let seriesKey = Object.keys(data.chart_config).find(
            (key) => data.chart_config[key].color === point.series.color
          );
          let units = seriesKey ? data.chart_config[seriesKey].units : "";

          tooltipText += `<span style="color:black">${
            point.series.name
          }: </span><b>${Highcharts.numberFormat(
            point.y,
            2
          )} ${units}</b><br/>`;
        });
        return tooltipText;
      },
    },
    legend: {
      itemStyle: {
        color: "#ffffff",
      },
      itemHoverStyle: {
        color: "#d6ff41",
      },
      layout: "horizontal", // Set the legend layout to horizontal
      align: "center", // Center the legend horizontally
      verticalAlign: "top", // Place the legend at the top of the chart
    },
    plotOptions: {
      series: {
        borderWidth: 0, // Remove border for all series
      },
    },
    navigator: {
      enabled: true,
      height: 20,
      xAxis: {
        gridLineColor: "#d6ff41",
      },
      series: {
        data: [], // Initial empty data for the navigator
        color: "#d6ff41",
        lineColor: "#d6ff41",
      },
    },
    exporting: {
      enabled: false, // Disable export functionality
    },
    credits: {
      enabled: false, // Remove Highcharts watermark
    },
    series: [],
  });

  useEffect(() => {
    if (!data || !data.chart_config) {
      console.error("Invalid data or chart_config is missing");
      return;
    }

    // Prepare series data
    const series = [];
    const yAxis0Fields = data.chart_config.yAxis0.split(", ");
    const yAxis1Fields = data.chart_config.yAxis1.split(", ");

    yAxis0Fields.forEach((field) => {
      if (data.chart_config[field]) {
        series.push({
          name: field,
          type: data.chart_config[field].type,
          data: data.data.map((item) => [
            new Date(item.date).getTime(),
            item[field],
          ]),
          yAxis: 0,
          color: data.chart_config[field].color,
          tooltip: {
            pointFormatter: function () {
              return `${this.series.name}: ${Highcharts.numberFormat(
                this.y,
                2
              )}`;
            },
          },
        });
      }
    });

    yAxis1Fields.forEach((field) => {
      if (data.chart_config[field]) {
        series.push({
          name: field,
          type: data.chart_config[field].type,
          data: data.data.map((item) => [
            new Date(item.date).getTime(),
            item[field],
          ]),
          yAxis: 1,
          color: data.chart_config[field].color,
          tooltip: {
            pointFormatter: function () {
              return `${this.series.name}: ${Highcharts.numberFormat(
                this.y,
                2
              )}`;
            },
          },
        });
      }
    });

    // Prepare navigator series data
    const navigatorData = data.data.map((item) => [
      new Date(item.date).getTime(),
      item[data.chart_config.yAxis0.split(", ")[0]],
    ]);

    // Determine xAxis min and max
    const xAxisMin = data.data.length === 1 ? navigatorData[0][0] : undefined;
    const xAxisMax = data.data.length === 1 ? navigatorData[0][0] : undefined;

    // Update chart options
    // console.log("data inside update",data);

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series,
      xAxis: {
        ...prevOptions.xAxis,
        min: xAxisMin,
        max: xAxisMax,
      },
      tooltip: {
        ...prevOptions.tooltip,
        formatter: function () {
          let tooltipText =
            "<b>" + Highcharts.dateFormat("%Y-%m-%d", this.x) + "</b><br/>";
          this.points.forEach((point) => {
            const units = data?.chart_config[point?.series?.name]?.units || "";
            tooltipText += `<span style="color:black">${
              point.series.name
            }: </span><b>${Highcharts.numberFormat(
              point.y,
              2
            )} ${units}</b><br/>`;
          });
          return tooltipText;
        },
      },
      navigator: {
        ...prevOptions.navigator,
        series: {
          ...prevOptions.navigator.series,
          data: navigatorData,
        },
      },
    }));
  }, [data]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      if (chart && data && data.data.length > 0) {
        const navigatorData = data.data.map((item) => [
          new Date(item.date).getTime(),
          item[data.chart_config.yAxis0.split(", ")[0]],
        ]);
        chart.xAxis[0].setExtremes(
          navigatorData[0][0],
          navigatorData[navigatorData.length - 1][0],
          true,
          false
        );
      }
    }
  }, [chartOptions]);

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "60px",
          color: "white",
          backgroundColor: "#1C2427",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data?.chart_config.yAxis0.split(", ").map((field) => (
          <div
            style={{ transform: "rotate(-90deg)", padding: "40px" }}
            key={field}
          >
            {field} {data?.chart_config[field]?.units}
          </div>
        ))}
      </div>
      <div style={{ width: "calc(90%)", height: TrendsHeight, margin: "auto" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      </div>
      <div
        style={{
          width: "60px",
          color: "white",
          backgroundColor: "#1C2427",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data?.chart_config.yAxis1.split(", ").map((field) => (
          <div
            style={{ transform: "rotate(90deg)", padding: "40px" }}
            key={field}
          >
            {field} {data?.chart_config[field]?.units}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartComponent;
