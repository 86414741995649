import { useState, useEffect, useRef } from "react";
import { Modal, Box, Button, Typography,Stack,Grid, FormControlLabel, Checkbox ,IconButton,Tooltip} from '@mui/material';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SingleCascader from './SingleCascader';
import MultiCascaderNew from './MultiCascaderNew';
import CloseIcon from '@mui/icons-material/Close';
import { fetchData } from "../utils/data";
import Loader from "../components/Loader";
import Backdrop from '@mui/material/Backdrop';
import CancelIcon from "@mui/icons-material/Cancel";
import Axios from '../Axios'



import Plot from "./Plot";


function base64ToBlob(base64, mime) {
  const byteChars = atob(base64);
  const byteNumbers = Array.from(byteChars, (char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
}

// React component to display the image
// React component to display the image
const Base64Image = ({ base64, mime }) => {
  const [imageSrc, setImageSrc] = useState("");
 
  
  

  useEffect(() => {
    if (base64 && mime) {
      const blob = base64ToBlob(base64, mime);
      const url = URL.createObjectURL(blob);
      setImageSrc(url);

      // Clean up the object URL when the component unmounts
      return () => URL.revokeObjectURL(url);
    }
  }, [base64, mime]);


  return (
    <div>
      {imageSrc ? (
        <img width={"70%"} src={imageSrc} alt="plotted_data" />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

const Response = ({
  outputs,
  setIsPopUpOpen,
  setFeedbackPayload,
  setPopupId,
  setLogs,
  query,
  tabId,
  fetchDataTab,

}) => {
  // console.log("output: ", output);
  const mimeType = "image/png";
  const [isOpen,setIsOpen]=useState(false);
  const [data, setData] = useState([]);
  const [questionAsk,setQuestionAsk]=useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [kpiData, setKpiData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [channelData, setChannelData] = useState([]);

  useEffect(() => {
    if(tabId!=''){
    const loadData = async () => {
      console.log("loadData",tabId);
      try {
        const result = await fetchData(query,tabId); // Replace 'your-endpoint' with the actual endpoint
        setData(result.data);
      } catch (error) {
        // setError(error.message);
      } finally {
        // setLoading(false);
      }
    };
    loadData();
  }


  }, [tabId]);


  
  const handleSubmit = async() => {
    // Log the data to the console
    setIsLoading(true);
   
    // Prepare the body of the request
    const requestBody = {
        kpi: [kpiData],
        brand: [brandData],
        channels: channelData,
        question: questionAsk,
        tab_id: tabId
    };

    
      console.log("request_body, ",requestBody);
    try {
      

      const response = await Axios.post('gpt_reset/', requestBody, {
        headers: {
          "Content-Type": "application/json"
        }
      });
    
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
      const data = await response.json();
      
        
      console.log("reset_data ", data);
      setIsLoading(false);
      setIsOpen(false); // Close the modal after submission
      setShouldFetch(true);
      fetchDataTab(tabId);
      
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      setIsOpen(false); // Close the modal after submission
      setShouldFetch(true);
      fetchDataTab(tabId);
      // return error;
    }


};

  const handleClose=()=>{
    setIsOpen(false); // Close the modal after submission
  }

  const scrollRef = useRef(null);

  useEffect(() => {
    
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [outputs]);

  return (
    <Stack
      ref={scrollRef}
      sx={{
        overflowY: "scroll",
        height: "85%",
        // flex: 1,
        border: "none",
        "&::-webkit-scrollbar": {
          width: "10px",
        },

        "&::-webkit-scrollbar-track": {
          background: "#1C2427",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray",
          borderRadius: "10px",
        },
      }}
    >
      {outputs?.length > 0 &&
      
        outputs.map((output) => {
          return (
            <Stack gap={1} sx={{ padding: "1rem" }}>
              
              {output?.error === 1 ? (
                <>
                  <Typography sx={{ fontSize: "1rem", color: "red" }}>
                    {output?.error_msg}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", color: "white", fontWeight: 700 }}
                  >
                    {"You"}
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", color: "white" }}>
                    {output?.question}
                  </Typography>
                  
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", color: "white", fontWeight: 700 }}
                  >
                    {"Skewb-GPT"}
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", color: "white" }}>
                    {output?.answer.text}
                  </Typography>
                  
                  <Typography sx={{ fontSize: "1rem", color: "white" }}>
                    {output?.answer.res_human.payload_human}
                  </Typography>
                  
                  <Stack direction={"row"} gap={2}>
                    <ThumbUpIcon
                      onClick={() => {
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 1,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}
                      fontSize="small"
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    <ThumbDownIcon
                      onClick={() => {
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 0,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}
                      fontSize="small"
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    <EmojiObjectsIcon
                      onClick={() => {
                        setIsPopUpOpen(true);
                        setPopupId(2);
                        setLogs(output?.answer.gpt_log);
                      }}
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px",
                        color: "#d6ff41",

                      }}
                    />
                     <RestartAltIcon
                    
                    onClick={() => {{
                      console.log("output qeustions ",output.question);
                      setQuestionAsk(output?.question)
                      setIsOpen(true)
                      // setPopupId(3);
                      
                    }}}
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px",
                      
                    }}
                    />
                    
                  </Stack>
                </>
              ) : (
                
                <Stack gap={1}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", color: "white", fontWeight: 700 }}
                  >
                    {"You"}
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", color: "white" ,float:'right' }}>
                    {output?.question}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", color: "white", fontWeight: 700 }}
                  >
                    {"Skewb-GPT"}
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", color: "white" }}>
                    {output?.answer.text}
                  </Typography>
                  {output?.answer.isImage === true ? (
                    
                    <Plot data={output?.answer.graph} />
                  ) : null}
                   <Typography sx={{ fontSize: "1rem", color: "white" }}>
                   {output?.answer.res_human.payload_human}
                  </Typography>
                  <Stack direction={"row"} gap={2}>
                    <ThumbUpIcon
                      onClick={() => {
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 1,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    <ThumbDownIcon
                      onClick={() => {{
                        
                        setIsPopUpOpen(true);
                        setFeedbackPayload((prevPayload) => ({
                          ...prevPayload,
                          user_query: output.question,
                          thumbs_up: 0,
                          timestamp: output.timestamp,
                        }));
                        setPopupId(1);
                      }}}
                      sx={{ cursor: "pointer", fontSize: "18px" }}
                    />
                    <EmojiObjectsIcon
                      onClick={() => {
                        
                        setIsPopUpOpen(true);
                        setPopupId(2);
                        setLogs(output?.answer.gpt_log);
                      }}
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px",
                        color: "#d6ff41",
                      }}
                    />
                    <RestartAltIcon
                    
                    onClick={() => {{
                      console.log("output qeustions ",output.question);
                      setQuestionAsk(output?.question)
                      setIsOpen(true)
                      // setPopupId(3);
                      
                    }}}
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px",
                      
                    }}
                    />

                       
                  </Stack>
                </Stack>
              )}
            </Stack>
          );
        })}

              
            <Modal 
// keepMounted
      open={isOpen}
      onClose={() => { setIsOpen(false); }}
      // aria-labelledby="modal-title"
      // aria-describedby="modal-description"
      // Backdrop={backgroundColor:'transparent'},
      closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 5,
                  sx: {
                    //Your style here....
                    backgroundColor: 'transparent',
                  },
                },
              }}

              hideBackdrop={true}
      >



      <Box

        sx={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#1f2937',
          color:"black",
          // border:'transparent',
          borderRadius:'8px',
          outline:'none',
          height:'50%' ,
          width:'max-content',
          
          p: 2,
        }}
      >
        
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color:'#d6ff41'
            }}
          >
            <CancelIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        <Typography id="modal-title" variant="h3" component="h2" sx={{color:"white"}}>
          Select Input Data
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={4}
          >
            <Typography variant="subtitle1" sx={{color:"white", margin:'5px' }}>
            <Typography variant="subtitle2" sx={{ color: "white"  }} style={{ fontSize: '40%' }} >
            Select/Search KPI
          </Typography>
              <SingleCascader data={data.kpi_cascader} match={"tea-green-jasmine"} placeholder={"KPI"} setData={setKpiData} />
            </Typography>
            
          </Grid>
          <Grid item xs={4}>
          <Typography variant="subtitle1" sx={{color:"white", margin:'5px' }}>
            <Typography variant="subtitle2" sx={{ color: "white"  }} style={{ fontSize: '40%' }} >
            Select/Search Brand
          </Typography>
            <SingleCascader data={data.brand_cascader} match={"tea-green-jasmine"} placeholder={"Brand"} setData={setBrandData}/>
            </Typography>
            
          </Grid>
          <Grid item xs={4}>
          <Typography variant="subtitle1" sx={{color:"white", margin:'5px' }}>
            <Typography variant="subtitle2" sx={{ color: "white"  }} style={{ fontSize: '40%' }} >
            Select/Search Channels
          </Typography>
              <MultiCascaderNew data={data.channels_cascader} match={"tea-green-jasmine"} placeholder={"Channel"} setChannelData={setChannelData}/>
            </Typography>
            
          </Grid>
        </Grid>
        <Box sx={{ position: 'absolute', bottom: '10px', left: '10px', mt: 1 }}>

        <Tooltip
      title={kpiData.length === 0 || brandData.length === 0 || channelData.length === 0 ? "Please select all three Data" : ""}

      >
      {isLoading && <Loader/> } 
      <span>
      <Button
      onClick={() => { handleSubmit() }}
      variant="outlined"
      disabled={kpiData.length === 0 || brandData.length === 0 || channelData.length === 0}
      sx={{
        fontWeight: '100 !important',
        lineHeight: '.5rem !important',
        padding: '8px !important',
        height: "auto !important",
        backgroundColor: '#1c2427 !important',
        borderRadius: "5px !important",
        borderColor: '#d6ff41 !important',
        color: '#d6ff41 !important',
        textTransform: 'capitalize !important',
        fontSize: '.9rem !important',
        '&:hover': {
            fontWeight: '100 !important',
            borderColor: '#d6ff41 !important',
            color: 'black !important',
            backgroundColor: '#d6ff41 !important',
            fontSize: '.9rem !important',
        },
        '&.Mui-disabled': {
            fontWeight: '100 !important',
            backgroundColor: '#1c2427 !important',
            borderColor: '#d6ff41 !important',
            color: '#d6ff41 !important',
            fontSize: '.9rem !important',
            lineHeight: '.5rem !important',
        }
    }}
      >
      Submit
      </Button>
      </span>
      </Tooltip>


          
          
        </Box>
      </Box>

      </Modal>  

          </Stack>

          
        );
      };

export default Response;
