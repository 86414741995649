import { React, useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import axiosChartDownload from "../../axiosChartDownload";
// import { toast } from "react-toastify";
// import domtoimage from "dom-to-image";

import configDriven from "../configDriven";

const LTSTROI = (props) => {
  // console.log("props", props.Series[0].data);
  const refChart = useRef(null);
  const divRef = useRef(null);

  const displayNamesChart = JSON.parse(
    localStorage.getItem("displayNamesChart")
  );

  const returnDisplayName = (id) => {
    if (
      configDriven.show_display_name &&
      displayNamesChart?.hasOwnProperty(id)
    ) {
      return displayNamesChart[id];
    } else {
      return id;
    }
  };

  // return display names of x axis labels title
  function getFormattedString(id, obj) {
    if (configDriven.show_display_name) {
      // Split the id by hyphens
      const keys = id.split("-");

      // Map the keys to their corresponding values in the object
      const values = keys.map((key) => obj[key]);

      // Join the values with hyphens and return the result
      return values.join("-");
    } else {
      return id;
    }
  }

  // useEffect(() => {
  //   if (props.Downloadchart) {
  //     const chartContainer = document.getElementById("roi_chart");
  //     domtoimage
  //       .toPng(chartContainer)
  //       .then(function (dataUrl) {

  //         var string = "";
  //         for (var i = 0; i < props.BreadCrumbs.length; i++) {
  //           console.log('props.BreadCrumbs::: ', props.BreadCrumbs);
  //           string += props.BreadCrumbs[i];
  //           if (i !== props.BreadCrumbs.length-1) {
  //             string += ",";
  //           }
  //         }

  //       props.setLoader(true);
  //       axiosChartDownload.post("download_chart/create-image/",
  //         {
  //           "title": "Roi",
  //           "filter": props.Brand,
  //           "hierarchy": string,
  //           "timeframe": props.Timeframe,
  //           "start_date": "",
  //           "end_date": "",
  //           "base64_image":  dataUrl.split(",")[1],
  //           theme:localStorage.getItem("CurrentTheme")

  //         }

  //       ).then((response) => {
  //         if (response.data.error === 1) {
  //           toast.error(response.data.erroMsg);
  //           props.setLoader(false);
  //         } else {
  //           var link = document.createElement("a");
  //           link.target = "_blank";
  //           link.href = response.data.data.combined_graph_url;
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //           props.setLoader(false);
  //         }
  //       }).catch((data) => {
  //         props.setLoader(false);
  //       });
  //       })
  //       .catch(function (error) {
  //         props.setLoader(false)
  //         console.error("Error while generating chart image:", error);
  //       });
  //     props.setDownloadchart(false)
  //   }
  // }, [props])

  const calculateWidth = (categories, containerWidth, minColumnWidth) => {
    // by a column
    let converedWidthByColumns = minColumnWidth * categories + categories * 50;

    // console.log("result: ", Math.max(containerWidth, converedWidthByColumns));
    return Math.max(containerWidth, converedWidthByColumns);
  };

  // useEffect(() => {
  //   const chart = refChart.current.chart;
  //   if (chart) chart.reflow();
  // }, [props.Chartdata]);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "rgba(0,0,0,0)",
      // backgroundColor: localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" : "",
      showAxes: true,
      height: props.DivHeight,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.Categories,

      title: {
        text: props.Xtitle,
        style: {
          color: "#FFFFFF",
        },
      },
      crosshair: true,
      labels: {
        useHTML: true,
        formatter: function () {
          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = [];
            function chnnellist(list) {
              if (list.name.split(",")[0] !== "") {
                SeriesList.push(list.name.split(",")[0]);
              }
              if ("parent" in list && list.parent !== undefined) {
                chnnellist(list.parent);
              }
            }
            chnnellist(this.axis.categories[this.pos]);

            const val = this.value.split(",");
            const lebelText = returnDisplayName(val[0]);
            // console.log("lebelText: ", lebelText);
            let title = SeriesList.reverse().join("-");
            title = title.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              } else {
                short =
                  val[0] !== "" && val[0].length > 5
                    ? val[0].slice(0, 5) + ".."
                    : val[0];
              }
            } else {
              short = val[0];
            }
            // console.log("fulltext: ", fulltext);
            return localStorage.getItem("CurrentTheme") === "Dark"
              ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
                  getFormattedString(title, displayNamesChart) +
                  ">" +
                  // short +
                  lebelText +
                  "</div>"
              : '<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title=' +
                  getFormattedString(title, displayNamesChart) +
                  ">" +
                  // short +
                  lebelText +
                  "</div>";
          } else {
            return localStorage.getItem("CurrentTheme") === "Dark"
              ? '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
                  fulltext +
                  ">" +
                  this.value.name.split(",")[0] +
                  "</div>"
              : '<div class="cursor-pointer" style="color:#2f3c41; background-color:white; z-index:99999" title=' +
                  fulltext +
                  ">" +
                  this.value.name.split(",")[0] +
                  "</div>";
          }
        },
        rotation: props.full ? 0 : props.Series[0].data.length > 3 ? -45 : 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            props.DrillUp(this);
          },
        },
      },
      // labels: {
      //   useHTML: true,
      //   formatter: function () {
      //     const getValuePath = (value, list = []) => {
      //       // console.log("value: ", value);
      //       if (value && typeof value === "object") {
      //         list.unshift(value.name);
      //         if (value.parent) {
      //           getValuePath(value.parent, list);
      //         }
      //       } else if (typeof value === "string") {
      //         list.unshift(value);
      //       }
      //       return list;
      //     };

      //     let title = "";

      //     if (typeof this.value === "string") {
      //       const SeriesList = [];
      //       function chnnellist(list) {
      //         // console.log("list: ", list);
      //         if (list.name.split(",")[0] !== "") {
      //           SeriesList.push(list.name.split(",")[0]);
      //         }
      //         if ("parent" in list && list.parent !== undefined) {
      //           chnnellist(list.parent);
      //         }
      //       }
      //       chnnellist(this.axis.categories[this.pos]);

      //       // console.log("SeriesList: ", SeriesList);

      //       title = SeriesList.reverse().join("-");
      //     }

      //     const path = getValuePath(this.value);
      //     // console.log("path: ", path);
      //     const label = path.join("-").split(",")[0];

      //     const title1 = getFormattedString(title, displayNamesChart);

      //     return `<div class="cursor-pointer" title="${title}" style="color: #FFFFFF; background-color: #1C2427; z-index: 99999;">${returnDisplayName(
      //       label
      //     )}</div>`;
      //   },

      //   rotation: props.full ? 0 : props.Series[0].data.length > 3 ? -45 : 0,
      //   style: {
      //     color: "#FFFFFF",
      //   },
      //   events: {
      //     click: function () {
      //       props.DrillUpCamp(this);
      //     },
      //   },
      // },
    },
    yAxis: {
      min: 0,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.Ytitle,
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    // tooltip: {
    //   formatter: function () {
    //     const SeriesList = [];
    //     function chnnellist(list) {
    //       if (list.name.split(",")[0] !== "") {
    //         SeriesList.push(list.name.split(",")[0]);
    //       }
    //       if ("parent" in list && list.parent !== undefined) {
    //         chnnellist(list.parent);
    //       }
    //     }
    //     chnnellist(this.x);

    //     let label = SeriesList.reverse().join("-");
    //     return label + " " + this.y.toFixed(2) + "";
    //   },

    //   shared: true,
    //   useHTML: false,
    //   borderColor: "#ffffff",
    //   backgroundColor: "#ffffff",
    //   borderRadius: 8,
    // },

    tooltip: {
      formatter: function () {
        const SeriesList = [];
        function chnnellist(list) {
          if (list.name.split(",")[0] !== "") {
            SeriesList.push(list.name.split(",")[0]);
          }
          if ("parent" in list && list.parent !== undefined) {
            chnnellist(list.parent);
          }
        }
        chnnellist(this.x);

        let label = SeriesList.reverse().join("-");
        // return label + " " + this.y.toFixed(2) + "";
        const foundLabel = label.split("-").pop();

        var series = this.series;
        var spends = series.userOptions.spends[this.point.index];
        var contribution = series.userOptions.contribution[this.point.index];
        return (
          "<b>" +
          // label +
          getFormattedString(label, displayNamesChart) +
          "</b><br/>" +
          series.name +
          ": " +
          this.y +
          "<br/>" +
          "Spends: " +
          spends +
          "<br/>" +
          "Contribution: " +
          contribution
        );
      },
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },

    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
        },
      },
      series: {
        // cursor: 'pointer',
        point: {
          events: {
            click: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");
              // console.log("label: ", label);

              if (!props.NotAllow.includes(label)) {
                // props.DrillDown(this);
                props.DrillDown(label);
              }
            },

            mouseOver: function () {
              const SeriesList = [];
              function chnnellist(list) {
                if (list.name.split(",")[0] !== "") {
                  SeriesList.push(list.name.split(",")[0]);
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-");

              window.document.getElementsByTagName("body")[0].style.cursor =
                "pointer";
              if (props.NotAllow.includes(label)) {
                window.document.getElementsByTagName("body")[0].style.cursor =
                  "not-allowed";
              }
            },
            mouseOut: function () {
              window.document.getElementsByTagName("body")[0].style.cursor =
                "default";
            },
          },
        },
      },
    },
    legend: {
      enabled: true,
      itemHoverStyle: {
        color:
          localStorage.getItem("CurrentTheme") === "Dark"
            ? "#FFFFFF"
            : "#1C2427",
      },
      itemStyle: {
        color:
          localStorage.getItem("CurrentTheme") === "Dark"
            ? "#FFFFFF"
            : "#1C2427",
      },
      symbolRadius: 0,
    },
    series: props.Series,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: "highcharts-contextbutton",
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
        },
      },
    },
  };

  useEffect(() => {
    Highcharts.chart(divRef.current, {
        ...options
    })

  }, [props.Chartdata, props.full, props.DivHeight])
  return (
    <div
      id="roi_chart"
      key={props.full}
      style={{
        // border: "1px solid red",
        overflowX: "scroll",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        id="container"
        ref={divRef}
        style={{
          width: props.full
            ? calculateWidth(props.Series[0].data.length, props.divWidth, 40)
            : null,
          // border: "1px solid yellow",
          height: "100%",
        }}
      >
        {/* <HighchartsReact
          ref={refChart}
          highcharts={Highcharts}
          options={options}
          {...props}
        /> */}
      </div>
    </div>
  );
};

export default LTSTROI;
