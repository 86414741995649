import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SolidGauge = ({ solidGaugeData, setClickedCatName }) => {
  useEffect(() => {
    // Create root element
    const root = am5.Root.new("chartdiv3");

    root.setThemes([am5themes_Animated.new(root)]);

    root._logo.dispose();

    // Create chart
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        innerRadius: am5.percent(20),
        startAngle: 20,
        endAngle: 360,
      })
    );


    chart.zoomOutButton.setAll({
      background: am5.RoundedRectangle.new(root, {
        fill: am5.color("#d6ff41"), // Set background color to yellow
      }),
      icon: am5.Graphics.new(root, {
        stroke: am5.color(0x000000), // Set icon color to black
        svgPath: "M5 15 H20", // Custom SVG path for minus icon (as an example)
      }),
    });
    // Data
    const data = solidGaugeData;

    // Add cursor
    const cursor = chart.set(
      "cursor",
      am5radar.RadarCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // console.log(cursor);

    // chart.zoomOutButton.set("forceHidden", true);

    // Create axes and their renderers
    const xRenderer = am5radar.AxisRendererCircular.new(root, {});

    xRenderer.labels.template.setAll({
      radius: 10,
    });

    xRenderer.grid.template.setAll({
      forceHidden: true,
    });

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        // numberFormat: "#'%'",
        // tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 20,
    });
    yRenderer.grid.template.setAll({
      stroke: am5.color("#FFFFFF"),
      fill: am5.color("#000000"),
      strokeWidth: 2,
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: "500",
      fontSize: 18,
      templateField: "columnSettings",
      fill: am5.color("#000000"),
    });

    yRenderer.grid.template.setAll({
      forceHidden: true,
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        forceHidden: true,
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 15,
      cursorOverStyle: "pointer",
      fill: am5.color("#ffffff"), // Set label color to red
    });

    yAxis.data.setAll(data);

    // Create series
    const series1 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "full",
        categoryYField: "category",
        // fill: root.interfaceColors.get("alternativeBackground"),
        fill: am5.color("#000000"),
      })
    );

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 1,
      strokeOpacity: 0,
      cornerRadius: 20,
    });

    series1.data.setAll(data);

    const series2 = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        clustered: false,
        valueXField: "value",
        categoryYField: "category",
      })
    );

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      // tooltipText: "{category}: {valueX}%",
      cornerRadius: 20,
      templateField: "columnSettings",
    });

    
    const tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0x000000), // Set the background color to black
      }),
      labelText:
        "{category}: {valueX}% ({dataItem.dataContext.display_value} {units})",
    });



    tooltip.label.setAll({
      fill: am5.color("#ffffff"), // Set tooltip font color to white
    });

    series2.set("tooltip", tooltip);

    series2.data.setAll(data);

    // Add click event listener to columns
    series2.columns.template.events.once("click", function (ev) {
      console.log(
        "Category name",
        ev.target._dataItem.dataContext.category,
        "value",
        ev.target._dataItem.dataContext.value
      );
      setClickedCatName(ev.target._dataItem.dataContext.category);
    });

    // Animate chart and series in
    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);

    // Clean up function
    return () => {
      root.dispose();
    };
  }, [solidGaugeData]);

  return (
    <div
      id="chartdiv3"
      style={{ width: "100%", height: "500px", backgroundColor: "#1c2427" }}
    ></div>
  );
};

export default SolidGauge;
