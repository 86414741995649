// export default BaseURL = 'http://15.206.194.153'
// export default BaseURL = 'http://demo-1.skewb.ai'
// export default BaseURL = 'http://13.126.233.140'

// const BaseURL = 'http://43.204.176.201'
// const BaseURL ='http://13.127.224.137'
// const BaseURL = "http://3.108.210.176";
// const BaseURL = "http://52.66.195.32";
// const BaseURL = "http://newdemo.skewb.ai";
// const BaseURL = "http://me.skewb.ai";
const BaseURL = "https://devdemo.skewb.ai";
// const BaseURL = "http://dove-id.skewb.ai";
// const BaseURL = "https://13.201.188.88";

// const BaseURL = "https://13.232.41.221";

// const BaseURL = window.location.origin;



// const BaseURL = "http://43.205.63.175";
// const BaseURL = "http://glicodev.skewb.ai";
// const BaseURL = "http://devtdc.skewb.ai";
// const BaseURL = "http://brezza.skewb.ai";
// const BaseURL = "http://msxiaomi.skewb.ai";


export default BaseURL;
