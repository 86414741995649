import React from 'react'

import configDriven from './configDriven';
import MultiCascader from "../Components/MultiCascaderC";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';







const AllCampaignFilter = (props) => {
  // console.log("props.CompActiveFilters: ", props.CompActiveFilters);
  // console.log("props.CompAllFilters: ", props.CompAllFilters);

  const bgColor = (node) => {
    if (props.Chartdata?.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (props.Chartdata?.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else {
          if (props.Chartdata[1].hasOwnProperty(node)) {
            if (props.Chartdata[1][node].Filter?.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
  };

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  // const data = JSON.parse(localStorage.getItem("channelFilters"));

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(props.CompActiveFilters);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    props.setCompActiveFilters(newItems);
    // console.log("newItems: ", newItems);
  };
  

  return (
    <div className={props.ActiveChart === "Comparison" ? "col-xl-6" :"col-xl-7"} style={{border: "none"}}>
      <div className={props.ActiveChart !== "Comparison" ? "card my-0 mx-0" : "car my-0 mx-0"}>
        <div className={props.ActiveChart !== "Comparison" ? "card-body allFilter" : "card-bod allFilter"} style={{ position: "relative", border: "none", backgroundColor: "#1c2427", borderRadius: "20px", marginBottom: "0px"}}>
        {/* <div className="row align-items-center" style={{border: "none",}}> */}
          <h6 className="m-0 text-center cm-filter-title" >Channel Filter</h6>
          {/* <ul id="scroller" className="p-1 mx-1">
            {
              props.CompActiveFilters.map((item, index) => {
                return (
                  <li key={index} className={false ? "cm-filter-option-li-filter-active" : "cm-filter-option-li"} onClick={() => props.RemoveCompFilter(item)}>
                    <span className="btn cm-drag-btn p-1 mx-2 active">
                      <p className="m-0 text-left px-1">{returnDisplayName(item.name)}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                )
              })
            }
            {
              props.CompAllFilters.map((item, index) => {
                return (
                  (!props.IsInAllCompFilters(item)) ? <li key={index} className="cm-filter-option-li" onClick={() => props.AddCompFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2">
                      <p className="m-0 text-left px-1">{returnDisplayName(item.name)}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li> : null
                )
              })
            }
          </ul> */}


          {props.CFilters &&
            (props.ActiveChart === undefined ||
              props.ActiveChart === "Measurement" ||
              props.ActiveChart === "Comparison" ||
              props.ActiveChart === "Trend_Analysis") && (
            <div style={{paddingLeft: "0.5rem", paddingBottom: "4px"}}>  
              <MultiCascader
                data={props.CFilters}
                Chartdata={props.Chartdata}
                setChartdata={props.setChartdata}
                setFetchCall={props.setFetchCall}
                selectedCheckboxes={props.selectedCheckboxesC}
                setSelectedCheckboxes={props.setSelectedCheckboxesC}
              />
              </div>
            )}
          <div id="scroller" className="p-1 mx-1 d-flex" style={{overflowX: "auto", maxWidth: "max-content"}}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="changeable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {props.CompActiveFilters.map((item, index) => (
                      <Draggable
                        key={item.name}
                        draggableId={item.name.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <li key={index} className={"cm-filter-option-li"} onClick={() => props.RemoveCompFilter(item)}>
                              <span className="btn cm-drag-btn p-1 mx-2 active">
                                <p className="m-0 text-left px-1">{returnDisplayName(item.name)}</p>
                                <p className="m-0 px-1">
                                  <i className="fa fa-minus" aria-hidden="true"></i>
                                </p>
                              </span>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {
              props.CompAllFilters.map((item, index) => (
                !props.IsInAllCompFilters(item) ? (
                  <li key={index} className="cm-filter-option-li" onClick={() => props.AddCompFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2">
                      <p className="m-0 text-left px-1">{returnDisplayName(item.name)}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                ) : null
              ))
            }
          </div>
          
        </div>
       </div>
      {/* </div> */}
    </div>
  )
}

export default AllCampaignFilter