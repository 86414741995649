import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MultiCascader from "../Components/MultiCascaderH";
import configDriven from './configDriven';
import { Box } from '@mui/material';

const AllHeirarchyFilter = (props) => {

  const bgColor = (node) => {
    if (props.Chartdata.length === 1) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        return props.Chartdata[0][node].Filter?.length > 0;
      }
    } else if (props.Chartdata.length === 2) {
      if (props.Chartdata[0].hasOwnProperty(node)) {
        if (props.Chartdata[0][node].Filter?.length > 0) {
          return true;
        } else if (props.Chartdata[1].hasOwnProperty(node)) {
          return props.Chartdata[1][node].Filter?.length > 0;
        }
      }
    }
    return false;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(props.ActiveFilters);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    props.setActiveFilters(newItems);
    // console.log("newItems: ", newItems);
  };

  // const data = JSON.parse(localStorage.getItem("allBrands"));

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  return (
    <div style={{border: "none"}} className={props.OnlyHeirarchyFilter ? "col-xl-12" : props.ActiveChart === "Comparison" ?"col-xl-6" : "col-xl-5"}>
      <div className={props.ActiveChart !== "Comparison" ? "card my-0 mx-0" : "car my-0 mx-0"} style={{ position: "relative", border: "none", backgroundColor: "#1c2427", borderRadius: "20px"}}>
        <div className={props.ActiveChart !== "Comparison" ? "card-body allFilter" : "card-bod allFilter"} style={{position: "relative", border: "none", marginBottom: "0px"}}>
          {/* <div className="row align-items-center" style={{border: "none"}}> */}
          
          <h6 className="m-0 text-center cm-filter-title">Hierarchy Filter</h6>
          {(props.ActiveChart === undefined ||
              props.ActiveChart === "Measurement" ||
              props.ActiveChart === "Comparison" ||
              props.ActiveChart === "Trend_Analysis") && (
              <div style={{paddingLeft: "0.5rem", paddingBottom: "4px"}}>
                <MultiCascader
                  data={props.HFilters}
                  Chartdata={props.Chartdata}
                  setChartdata={props.setChartdata}
                  setFetchCall={props.setFetchCall}
                  selectedCheckboxes={props.selectedCheckboxes}
                  setSelectedCheckboxes={props.setSelectedCheckboxes}
                  fiexedFilters={props.fiexedFilters}
                  ActiveFilters={props.ActiveFilters}
                />
               </div>
          )}
          <div id="scroller" className="p-1 mx-1 d-flex" style={{overflowX: "auto", maxWidth: "max-content"}}>
            {
              props.Fixed.map((item, index) => (
                <li key={index} className={
                  true
                    ? "cm-filter-option-li-filter-active"
                    : "cm-filter-option-li"
                }>
                  <span className="btn cm-drag-btn p-1 mx-2 active">
                    <p className="m-0 text-left px-1">{returnDisplayName(item.id)}</p>
                    <p className="m-0 px-1">
                      {/* <i className="fa fa-minus" aria-hidden="true"></i> */}
                    </p>
                  </span>
                </li>
              ))
            }
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="changeable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {props.ActiveFilters.map((item, index) => (
                      <Draggable
                        key={item.name}
                        draggableId={item.name.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <li key={index} className={"cm-filter-option-li"} onClick={() => props.RemoveHeirarchyFilter(item)}>
                              <span className="btn cm-drag-btn p-1 mx-2 active">
                                <p className="m-0 text-left px-1">{returnDisplayName(item.name)}</p>
                                <p className="m-0 px-1">
                                  <i className="fa fa-minus" aria-hidden="true"></i>
                                </p>
                              </span>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {
              props.AllFilters.map((item, index) => (
                !props.IsInAllHeirarchyFilters(item) ? (
                  <li key={index} className="cm-filter-option-li" onClick={() => props.AddHeirarchyFilter(index)}>
                    <span className="btn cm-drag-btn p-1 mx-2">
                      <p className="m-0 text-left px-1">{returnDisplayName(item.name)}</p>
                      <p className="m-0 px-1">
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </p>
                    </span>
                  </li>
                ) : null
              ))
            }
          </div>

          {/* {(props.ActiveChart === undefined ||
              props.ActiveChart === "Measurement" ||
              props.ActiveChart === "Trend_Analysis") && (
                // <div className="col-sm-2">
                <MultiCascader
                  data={props.HFilters}
                  Chartdata={props.Chartdata[0]}
                  setChartdata={props.setChartdata}
                  setFetchCall={props.setFetchCall}
                  selectedCheckboxes={props.selectedCheckboxes}
                  setSelectedCheckboxes={props.setSelectedCheckboxes}
                  fiexedFilters={props.fiexedFilters}
                />
                // </div>
          )} */}
        
        {parseInt(props.ShowComp) === 1 &&
          props.SelectedChart === "overview" ? (
          <div className="d-flex align-items-center gap-2" style={{position: "absolute", right: 0, width: 200,}}>
            <input
              type="checkbox"
              className="custom-control-input login-checkbox"
              name="check"
              checked={parseInt(props.ShowCompValue) === 1 ? true : false}
              onChange={(e) => {
                if (parseInt(props.ShowCompValue) === 1) {
                  props.setShowCompValue(0);
                } else {
                  props.setShowCompValue(1);
                }
                props.setFetchCall(true);
              }}
            />
            <div style={{ color: "white"}}>Show competition</div>
          </div>
        ) : null}
        </div>
        </div>
      {/* </div> */}
    </div>
  );
};

export default AllHeirarchyFilter;
