import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import MeasurementSummary from "./MeasurementSummary";
import { toast } from "react-toastify";
import StackChart from "./StackChart";
import { Link } from "react-router-dom";
import Download from "../../images/download-icon.png";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../../images/icons8-info.svg";
import downloadFlag from "../downloadFlag";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import Calender from "../../Components/Calender";

import configDriven from "../configDriven";

const Measurement = (props) => {
  const refGraphDiv = useRef(null);
  const DivRef = useRef(null);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const [ChartHeight, setChartHeight] = useState("");
  const [DivHeight, setDivHeight] = useState("");
  const [Notes, setNotes] = useState("");

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [props]);

  useEffect(() => {
    if (DivRef.current !== null) {
      setDivHeight(DivRef.current.clientHeight);
    }
  }, [props]);

  

  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    // console.log("tab: ", tab);
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);

  const fnDisabledSubmitBtn = () => {
    if (
      props.DefaultStartDate !== "NaN-NaN-NaN" &&
      props.DefaultEndDate !== "NaN-NaN-NaN"
    ) {
      return false;
    } else if (
      props.DefaultStartDate === "NaN-NaN-NaN" ||
      props.DefaultEndDate === "NaN-NaN-NaN"
    ) {
      return true;
    }
  };

  const displayNames = JSON.parse(localStorage.getItem("displayNames"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNames?.hasOwnProperty(id)) {
      return displayNames[id];
    } else {
      return id;
    }
  }

  const [full, setFull] = useState(true);
  const [divWidth, setDivWidth] = useState("");

  const handler = () => {
    setFull(!full);
  };

  // console.log("divWidth: ", divWidth);

  useLayoutEffect(() => {
    const updateDivHeight = () => {
      if (DivRef.current !== null) {
        const newWidth = DivRef.current.clientWidth;
        setDivWidth(newWidth);
      }
    };

    updateDivHeight(); // Initial update
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);


  return (
    <div className="container-fluid main-content-w pt-1">
      <div className="row">
        <div
          className={
            parseInt(props.ShowSummary) !== 1
              ? "col-xl-12  cm-chart"
              : props.ZoomIn
              ? "col-xl-10  cm-chart"
              : "col-xl-8  cm-chart"
          }
        >
          <div className="card card-min-height" ref={DivRef}>
            <div className="card-body brand-card pt-1">
              {/* <div className="row align-items-center justify-content-sm-between pb-1 ef-drivers">
                <div className="col-sm-4">
                </div>
                <div className="col-sm-4">
                  <p className="card-title-lable">
                    EFFECTIVENESS MEASUREMENT OVER TIME
                  </p>
                </div>

              </div> */}
              <div
                className="row align-items-center ce-filter justify-content-between"
                style={{ border: "none" }}
              >
                <div className="col-sm-12 col-md-12 col-lg-2 d-flex">
                  <select
                    className="form-select custom-input-box"
                    value={props.timeframe}
                    onChange={props.onChangeHandler}
                    style={{ width: "115px" }}
                  >
                    {props.TimeFramesAll.map((item) => {
                      return (
                        <option key={item.key} value={item.key}>
                          {item.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-space-between my-1">
                  {props.timeframe !== "Monthly" && (
                    <div
                      className="row align-items-center"
                      style={{ border: "none" }}
                    >
                      <div className="col-sm-3">
                        <h3 className="m-0  custom-input-box">
                          <label className="login-lable brand-menu-label m-0">
                            For time period:
                          </label>
                        </h3>
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select custom-input-box"
                          value={props.DefaultStartDate}
                          onChange={(e) => {
                            props.setDefaultStartDate(e.target.value);
                            if (
                              props.ToDatesAll.indexOf(props.DefaultEndDate) <
                              props.FromDatesAll.indexOf(e.target.value)
                            ) {
                              props.setDefaultEndDate(
                                props.ToDatesAll[
                                  props.FromDatesAll.indexOf(e.target.value)
                                ]
                              );
                            }
                          }}
                        >
                          {props.FromDatesAll.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-sm-1">
                        <h3 className="m-0  custom-input-box">
                          <label className="login-lable brand-menu-label m-0">
                            To
                          </label>
                        </h3>
                      </div>
                      <div className="col-sm-3">
                        <select
                          className="form-select custom-input-box"
                          value={props.DefaultEndDate}
                          onChange={(e) => {
                            if (
                              props.FromDatesAll.indexOf(
                                props.DefaultStartDate
                              ) > props.ToDatesAll.indexOf(e.target.value)
                            ) {
                              // setTimePeriodError('Please enter valid time span')
                              toast.error("Please enter valid time period");
                            } else {
                              // setTimePeriodError('')
                              props.setDefaultEndDate(e.target.value);
                            }
                          }}
                        >
                          {props.ToDatesAll.map((item, index) => {
                            if (
                              props.FromDatesAll.indexOf(
                                props.DefaultStartDate
                              ) <= index
                            ) {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </select>
                      </div>
                      <div className="col-sm-2">
                        <button
                          className="btn brand-btn-info custom-input-box"
                          type="button"
                          onClick={() => {
                            props.setFetchCall(true);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                  {props.timeframe === "Monthly" && (
                    <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center justify-content-space-between gap-2">
                      {props.DefaultStartDate &&
                        props.DefaultEndDate &&
                        props.startdate &&
                        props.enddate && (
                          <Calender
                            setDefaultStartDate={props.setDefaultStartDate}
                            setDefaultEndDate={props.setDefaultEndDate}
                            DefaultStartDate={props.DefaultStartDate}
                            DefaultEndDate={props.DefaultEndDate}
                            startDate={props.startdate}
                            endDate={props.enddate}
                          />
                        )}
                      <div className="col-sm-2">
                        <button
                          disabled={fnDisabledSubmitBtn()}
                          className="btn brand-btn-info custom-input-box eda-submit-btn"
                          type="button"
                          style={{
                            border: fnDisabledSubmitBtn()
                              ? "1px solid #3e5056"
                              : "1px solid #d6ff41",
                          }}
                          onClick={() => {
                            props.setFetchCall(true);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-end my-1">
                  <div
                    className="d-flex align-items-center justify-content-sm-end"
                    style={{ float: "right" }}
                  >
                    {parseInt(props.ShowSummary) === 1 ? (
                      <i
                        style={{
                          float: "right",
                          color:
                            localStorage.getItem("CurrentTheme") === "Dark"
                              ? "#ffffff"
                              : "#1C2427",
                          cursor: "pointer",
                        }}
                        className={
                          !props.ZoomIn
                            ? "fa fa-window-maximize"
                            : "fa fa-window-minimize"
                        }
                        aria-hidden="true"
                        onClick={() => {
                          props.HandleZoom();
                        }}
                        title={props.ZoomIn ? "Minimize" : "Maximize"}
                      ></i>
                    ) : null}
                    {Notes !== "" ? (
                      <>
                        <p
                          className="m-0 px-2"
                          style={{
                            color: "#ffffff",
                          }}
                        >
                          <svg
                            src={InfoIcon}
                            id="notes"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            className="bi bi-info-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </p>

                        <Tooltip anchorSelect="#notes" clickable>
                          {/* <div className="api-notes">
                              {Notes}
                            </div> */}
                          <div
                            className="api-notes"
                            dangerouslySetInnerHTML={{ __html: Notes }}
                          ></div>
                        </Tooltip>
                      </>
                    ) : null}
                    {(downloadFlag.chart ||
                      downloadFlag.data ||
                      downloadFlag.pdf) && (
                      <span className="flex-shrink-0 dropdown">
                        <Link
                          to="#"
                          className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                          id="dropdownUser2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <p
                            className="m-0 px-2"
                            style={{
                              color: "#ffffff",
                            }}
                          >
                            <svg
                              src={Download}
                              title="Download"
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              className="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </p>
                        </Link>
                        <ul
                          className="dropdown-menu text-small shadow"
                          aria-labelledby="dropdownUser2"
                        >
                          {downloadFlag.chart && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.preventDefault();

                                  if (
                                    props.categories.length > 0 &&
                                    props.series.length > 0
                                  ) {
                                    // props.setDownloadchart(true)
                                    // props.setLoader(true)
                                    props.setIsDownloadingChart(true);
                                    props.setChartContainerID("campaign_chart");
                                    props.setDownloadedImageName(
                                      "Campaign Effectiveness"
                                    );
                                    props.setDownloadingChartTitle(
                                      "Campaign Effectiveness"
                                    );
                                    props.setHierarchy(props.BreadCrumbs);
                                    props.setStartDate(props.DefaultStartDate);
                                    props.setEndDate(props.DefaultEndDate);
                                    props.setTimeFrameChart(props.timeframe);
                                  } else {
                                    toast.error("Chartdata is not available");
                                  }
                                }}
                              >
                                Download Chart
                              </Link>
                            </li>
                          )}

                          {downloadFlag.data && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadDataM(e)}
                              >
                                Download Data
                              </Link>
                            </li>
                          )}
                          {downloadFlag.pdf && (
                            <li onClick={(e) => {}}>
                              <Link
                                className="dropdown-item"
                                onClick={(e) => props.downloadPDFM(e)}
                              >
                                Download PDF
                              </Link>
                            </li>
                          )}

                          {/* <li onClick={(e) => { }}>
                          <Link className="dropdown-item">Download data</Link>
                        </li> */}
                        </ul>
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-center my-3">
                                    <button
                                        className="btn brand-btn-info"
                                        type="button"
                                        onClick={() => {
                                            props.ExpandAll()
                                        }}>
                                        Expand All
                                    </button>
                                </div> */}
              </div>
              <div className="row mt-1">
                <div className="col-sm-12">
                  <div className="row align-items-center sorting-btn-ui">
                    <div className="col-sm-3">
                      <ul>
                        <li onClick={props.DrillUpAll} title="Drill Up">
                          <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        </li>
                        <li onClick={props.DrillDownAll} title="Drill Down">
                          <i
                            className="fa fa-arrow-down"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li onClick={props.DrillAll} title="Expand All">
                          <i className="fa fa-sitemap" aria-hidden="true"></i>
                        </li>
                        <li
                          onClick={() => {
                            props.ResetAll();
                            setFull(false);
                          }}
                          title="Reset"
                        >
                          <i className="fa" aria-hidden="true">
                            &#8635;
                          </i>
                        </li>
                        <li>
                          {full ? (
                            // <Tooltip2 title="Zoom Out">
                            <ZoomOutIcon
                              onClick={handler}
                              sx={{ color: "white", cursor: "pointer" }}
                            />
                          ) : (
                            // </Tooltip2>
                            // <Tooltip2 title="Zoom In">
                            <ZoomInIcon
                              onClick={handler}
                              sx={{ color: "white", cursor: "pointer" }}
                            />
                            // </Tooltip2>
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-9 custom-brdcrum">
                      {props.BreadCrumbs.map(item => {
                        return returnDisplayName(item);
                      }).join(" / ")}
                    </div>

                    {/* <div className="col-sm-9 custom-brdcrum">
                      {props.BreadCrumbs.map((item) => {
                        return displayNames[item];
                      }).join(" / ")}
                    </div> */}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div
                    className="campaign-measurement-chart-height"
                    ref={refGraphDiv}
                  >
                    {props.categories.length > 0 && props.series.length > 0 ? (
                      <StackChart
                        Timeframe={props.timeframe}
                        StartDate={props.DefaultStartDate}
                        EndDate={props.DefaultEndDate}
                        ActiveFilters={props.ActiveFilters}
                        Fixed={props.Fixed}
                        categories={props.categories}
                        series={props.series}
                        // series={DATA.data.series}
                        xtitle={props.xtitle}
                        ytitle={props.ytitle}
                        charttitle={props.charttitle}
                        DrillDown={props.DrillDown}
                        DrillUp={props.DrillUp}
                        height={ChartHeight}
                        ZoomIn={props.ZoomIn}
                        NotAllow={props.NotAllow}
                        setDownloadchart={props.setDownloadchart}
                        Downloadchart={props.Downloadchart}
                        setLoader={props.setLoader}
                        BreadCrumbs={props.BreadCrumbs}
                        timeframe={props.timeframe}
                        full={full}
                        divWidth={divWidth - 40}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MeasurementSummary
          Summary={props.Summary}
          ZoomIn={props.ZoomIn}
          ShowSummary={props.ShowSummary}
          DivHeight={DivHeight}
        />
      </div>
      <div className="row custom-note">
        <div className="col-sm-1">
          {props.FooterNotes !== "" ? (
            <>
              <i
                className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                id="clickable"
                style={{
                  fontSize: 24,
                  margin: "15px",
                  padding: "5px",
                  opacity: "1px",
                  position: "relative",
                }}
              ></i>
              <Tooltip anchorSelect="#clickable" clickable>
                {/* <div className="footer-notes">
                    {props.FooterNotes}
                  </div> */}
                <div
                  className="footer-notes"
                  dangerouslySetInnerHTML={{ __html: props.FooterNotes }}
                ></div>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Measurement;
