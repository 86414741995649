import React, { useRef, useEffect, useState } from "react";
import MixChart from "./MixChart";
import MixChart2 from "./MixChart2";
const TrendChart = (props) => {
  const trendsRef = useRef(null);
  const [TrendsHeight, setTrendsHeight] = useState("");
  // console.log("TrendsHeight: ", TrendsHeight);

  useEffect(() => {
    if (trendsRef.current !== null) {
      setTrendsHeight(trendsRef.current.clientHeight);
    }
  }, [props.TrendChartdata, props.fetchMixChart2]);

  // console.log("props.fetchMixChart2.data: ", props.fetchMixChart2);

  return (
    <div
      className="row mt-2"
      style={
        {
          //  border: "2px solid red"
        }
      }
    >
      <div className="col-sm-12">
        {/* <p className="card-title-lable">&nbsp;</p> */}
        <div className="trends-chart-height" id="trends_chart" ref={trendsRef}>
          {JSON.stringify(props.fetchMixChart2) !== "{}" && TrendsHeight &&  (
            // <MixChart
            //   height={TrendsHeight}
            //   TrendChartRef={props.TrendChartRef}
            //   ChartData={props.TrendChartdata}
            //   Downloadchart={props.Downloadchart}
            //   setDownloadchart={props.setDownloadchart}
            //   MixChart={props.mixChartData}
            // />
            <MixChart2 data={props.fetchMixChart2} TrendsHeight={TrendsHeight} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TrendChart;
