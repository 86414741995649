import React, { useState, useEffect } from "react";
import Legends from "./Legends";
import configDriven from "../configDriven";


const RowItem = ({ item, showOpenLevel }) => {
  // console.log("re rendered", showOpenLevel);
  const [expand, setExpand] = useState(item?.hirarchy_level <= showOpenLevel);
  const isLeaf = item?.children.length === 0;


  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    setExpand(item?.hirarchy_level <= showOpenLevel);
  }, [showOpenLevel, item]);

  const displayNamesChart = JSON.parse(localStorage.getItem("displayNamesChart"));

  const returnDisplayName = (id) => {
    if (configDriven.show_display_name && displayNamesChart?.hasOwnProperty(id)) {
      return displayNamesChart[id];
    } else {
      return id;
    }
  }

  return (
    <>
      <tr>
        <td className="channel-name">
          <div
            style={{
              cursor: isLeaf ? "default" : "pointer",
              marginLeft:
                parseInt(item?.hirarchy_level) !== 0
                  ? parseInt(item?.hirarchy_level) * 7 + "px"
                  : "0px",
            }}
            onClick={isLeaf ? null : handleExpand}
          >
            {returnDisplayName(item?.name)}
            {!isLeaf &&
              (expand ? (
                <i className="fas fa-minus ml-1 custom-table-toggle-icon"></i>
              ) : (
                <i className="fas fa-plus ml-1 custom-table-toggle-icon"></i>
              ))}
          </div>
        </td>
        {item?.values.map((data, dataIndex) => (
          <td key={dataIndex} style={{color: data.color}}>{data?.value}</td>
        ))}
      </tr>
      {expand &&
        item.children &&
        item.children.map((childItem) => (
          <RowItem
            key={childItem.id}
            item={childItem}
            showOpenLevel={showOpenLevel}
          />
        ))}
    </>
  );
};



const ItemWise = (props) => {
  

  return (
    <>
      <div className="row my-1 flex-nowrap" style={{ paddingRight: "40px"}}>
        <div className="custom-col-sm-1" style={{ width: "40px" }}>
          <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2"></i> {props.yAxisTitle}</p>
        </div>
        <div className="col-sm-12 px-0">
          {/* icons.....start */}
          <div className="row align-items-center sorting-btn-ui">
              <div className="col-sm-3" style={{display: "flex"}}>
                 {/* <ul style={{border: "1px solid blue"}}> */}
                   <div onClick={() => props.setShowOpenLevel(Infinity)} title="Expand All">
                     <i className="fa fa-sitemap" aria-hidden="true"></i>
                   </div>
                   <div onClick={() => props.setShowOpenLevel(0)} title="Reset">
                     <i className="fa" aria-hidden="true">
                       &#8635;
                     </i>
                   </div>
                 {/* </ul> */}
              </div>
          </div>
          {/* icons.......end */}
          <div className="gr-table-x-title-div ">
            <p className="m-0 gr-table-x-title">{props.xAxisTitle} <i className="ri-arrow-right-line mx-2"></i></p>
          </div>
          {/* <div className="table-responsive gains-risk-table" style={{ maxHeight: props.height - 140 + "px", overflowY: "scroll" }}>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.IndChannels.map((item, i) => {
                    return <th key={i}>{item}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {ChannelData.map((item, i) => {
                  return (
                    <>
                      {item.show_hide !== "hide" ? <tr key={item.ChannelName + i}>
                        <td className="channel-name" key={i}>
                          <div style={{ marginLeft: "" + parseInt(item.level) !== 0 ? parseInt(item.level) * 7 : 0 + "px" }}>
                            {item.yaxis}
                            {parseInt(item.child) > 0 ?

                              item.show_hide === "showing_id" ? <i
                                className="fas fa-minus ml-1 custom-table-toggle-icon"
                                onClick={() => {
                                  HandleHideData(item.id)
                                }}
                              ></i> :
                                <i
                                  className="fas fa-plus ml-1 custom-table-toggle-icon"
                                  onClick={() => {
                                    HandleShowData(item.id)
                                  }}
                                ></i>
                              : null}
                          </div>
                        </td>

                        {item.ChannelData.map((data, index) => {
                          return (
                            <td
                              key={index}
                              style={{ color: data.Color }}
                            >
                              {data.Value}
                            </td>
                          );
                        })}
                      </tr> : null}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div> */}

          {/* New table */}
          <div className="table-responsive gains-risk-table" style={{ maxHeight: props.height - 140 + "px", overflowY: "scroll" }}>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.xAxis?.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                
               {props.yAxis?.map(item => {
                  return <RowItem key={item.id} item ={item} showOpenLevel={props.showOpenLevel} />
               })}

              </tbody>
            </table>
          </div>
          {/* New table */}
        </div>
      </div>
      <div className="row my-3 d-flex justify-content-sm-end">
        <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
          {props.legends?.map((item, index) => {
            return <Legends key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
};

export default ItemWise;
