import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./redux/actions/TokenAction";
import Login from "./Authorization/Login";
import DataLandscape from "./Container/DataLandscape/Main";
import DataLandscapeMulti from "./Container/DataLandscape/MainMulti";

import configDriven from "./Container/configDriven";

import Logout from "./Container/Logout";
import EffectivenessDrivers from "./Container/EffectivenessDriver/Main";
import GainsAndRisks from "./Container/GainsAndRisks/Main";
import CampaignEffectiveness from "./Container/CampaignEffectiveness/Main";
// import CampaignEffectivenessTest from "./Container/CampaignEffectivenessTest/Main";
import ChannnelEfficacy from "./Container/ChannnelEfficacy/Main";
import ChannnelEfficacyAdstock from "./Container/ChannnelEfficacyAdstock/Main";
import SynergyEffects from "./Container/SynergyEffects/Main";
import Prioritisation from "./Container/KPI-Prioritisation/Main";
import SkewbSimulator from "./Container/SkewbSimulator/Main";
import SkewbSimulatorV2 from "./Container/SkewbSimulatorV2/Main";

import SimulatorRedirect from "./Container/SkewbSimulator/SimulatorRedirect";
import SavedResults from "./Container/SavedResults/Main";
import DeleteResult from "./Container/SavedResults/DeleteResultPopup";
import Dashboard from "./Container/Dashboard/Dashboard";
import Validation from "./Container/Validation/Main";
import Users from "./Container/UserManagement/Main";
import axios from "./axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
// import OrganizationChart from "./Container/OrganizationChart/OrganizationChart";
import UserProfile from "./Container/UserManagement/UserProfile";

import Portfolio from "./Container/Portfolio/Portfolio";
import Reload from "./Reload";

//top bar
import Topbar from "./Components/Topbar";
import Menubar from "./Components/Menubar";

import GPT from "./Container/GPT/App";

// mui imports
import { Box, Button, Typography } from "@mui/material";

const TRACKING_ID = "UA-255858069-1"; // Google Analytics TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // });

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => {};
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem("TokenSkewb")) {
      dispatch(setToken(localStorage.getItem("TokenSkewb")));
    }
    return () => {};
  }, [dispatch]);

  const token = useSelector((state) => state.getToken.token);
  // console.log(token);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      setError(error.response.data.erroMsg);
      if (error.response.data.error) {
        navigate("/logout");
      }
      return Promise.reject(error);
    }
  );
  const styleForMuiButton = {
    backgroundColor: "#d6ff41", // theme color
    color: "#1c2427",
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#d6ff41",
      opacity: 0.8,
    },
  };
  

  const ReturnDD = () => {
    if(configDriven.show_multi_chart) {
      return <DataLandscapeMulti />
    } else {
      return <DataLandscape />
    }
  }
  const UserChoiceOptimizer = () => {
    const [ltsAcitve, setLtsActive] = useState(false);
    const [upcomingActive, setUpcomingActive] = useState(false);
    const [userChoiceOpen, setUserChoiceOpen] = useState(true);

    const ltsHandler = () => {
      setUserChoiceOpen(false);
      setLtsActive(true);
      setUpcomingActive(false);
    };
    const upcomingHandler = () => {
      setUserChoiceOpen(false);
      setLtsActive(false);
      setUpcomingActive(true);
    };
    return (
      <>
        {userChoiceOpen && (
          <>
            <Topbar />
            <Menubar />
            <Box
              sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                sx={styleForMuiButton}
                onClick={ltsHandler}
              >
                <Box>
                  <Typography>1.0.1 LTS</Typography>
                  <Typography>Latest Version</Typography>
                </Box>
              </Button>
              <Button
                variant="contained"
                sx={styleForMuiButton}
                onClick={upcomingHandler}
              >
                <Box>
                  <Typography>2.10.0 Current</Typography>
                  <Typography>Latest Features</Typography>
                </Box>
              </Button>
            </Box>
          </>
        )}
        {ltsAcitve && <SkewbSimulator />}
        {upcomingActive && <SkewbSimulatorV2 />}
      </>
    );
  };

  // const [Details, setDetails] = useState({});
  // console.log("Details: ", Details);
  // const [DetailsId, setDetailsId] = useState("");
  // console.log("DetailsId: ", DetailsId);

  var paths = null;
  if (token) {
    paths = (
      <Routes>
        {/* <Route exact path="/portfolio" element={<OrganizationChart />}></Route> */}
        <Route
          exact
          path="/portfolio"
          element={<Portfolio key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/validations"
          element={<Validation key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/eda"
          element={<ReturnDD key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/contri"
          element={<EffectivenessDrivers key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/roi"
          element={<GainsAndRisks key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/grain_contri"
          element={<CampaignEffectiveness key={Date.now()} />}
        ></Route>
        {/* <Route exact path="/grain_contri1" element={<CampaignEffectivenessTest />}></Route> */}
        <Route
          exact
          path="/roc"
          element={<ChannnelEfficacy key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/adstock"
          element={<ChannnelEfficacyAdstock key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/synergy"
          element={<SynergyEffects key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/kpi_prioritization"
          element={<Prioritisation key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/optimize"
          element={<SimulatorRedirect key={Date.now()} />}
        ></Route>
        {/* <Route exact path="/optimizer" element={<SkewbSimulator />}></Route> */}
        <Route
          exact
          path="/optimizer"
          element={<SkewbSimulator key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/optimizer2"
          element={<SkewbSimulatorV2 key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/optimizer/:ResultId"
          element={<SkewbSimulator key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/savedresults"
          element={<SavedResults key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/deleteresult"
          element={<DeleteResult key={Date.now()} />}
        ></Route>
        <Route exact path="/users" element={<Users key={Date.now()} />}></Route>
        <Route
          exact
          path="/user_profile"
          element={<UserProfile key={Date.now()} />}
        ></Route>
        <Route
          exact
          path="/home"
          element={<Dashboard key={Date.now()} />}
        ></Route>
        {/* <Route exact path="*" element={<OrganizationChart />}></Route> */}
        <Route exact path="*" element={<Portfolio key={Date.now()} />}></Route>
        <Route
          exact
          path="/logout"
          element={<Logout key={Date.now()} />}
        ></Route>
         <Route
          exact
          path="/gpt"
          element={<GPT key={Date.now()} />}
        ></Route>
      </Routes>
    );
  } else {
    paths = (
      <Routes>
        <Route exact path="/login" element={<Login key={Date.now()} />}></Route>
        <Route exact path="*" element={<Login key={Date.now()} />}></Route>
      </Routes>
    );
  }

  return (
    <div>
      <Reload />
      <ToastContainer theme="colored" />
      {paths}
    </div>
  );
}

export default App;
