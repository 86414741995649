import React, { useState, useEffect, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import Sunburst from "./Sunburst";
import MediaSunburst from "./MediaSunburst";
import Loader from "../../Components/Loader";
import { useLocation, Link } from "react-router-dom";
import Download from "../../images/download-icon.png";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../../images/icons8-info.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrendChart from "./TrendChart";
import AllHeirarchyFilter from "../AllHeirarchyFilter";
import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";
import axiosChartDownload from "../../axiosChartDownload";
import domtoimage from "dom-to-image";
import LightThemeHexCode from "../LightThemeHexCode";

import useDownloadChart from "../../hooks/useDownloadChart";
import downloadFlag from "../downloadFlag";

import Calender from "../../Components/Calender";
import configDriven from "../configDriven";


const Main = () => {
  const refGraphDiv = useRef(null);
  const TrendChartRef = useRef(null);
  const location = useLocation();
  const refSpends = useRef(null);
  const refSales = useRef(null);
  const [SpendsHeight, setSpendsHeight] = useState("");
  const [SalesHeight, setSalesHeight] = useState("");

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
  const CampaignAnalyticFilter = JSON.parse(
    localStorage.getItem("CampaignAnalyticFilter")
  );
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);
  const [loader, setLoader] = useState(false);
  const [EdaTabs, setEdaTabs] = useState([]);
  // console.log("EdaTabs: ", EdaTabs);
  const [SunburstData, setSunburstData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [MediaSunburstData, setMediaSunburstData] = useState([]);
  const [DecimalPoint, setDecimalPoint] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [FromDatesAll, setFromDatesAll] = useState([]);
  const [ToDatesAll, setToDatesAll] = useState([]);
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");
  const [sb1_name, setSb1_name] = useState("");
  const [sb2_name, setSb2_name] = useState("");
  const [sb1_tag, setSb1_tag] = useState("");
  const [sb2_tag, setSb2_tag] = useState("");
  const [Legends, setLegends] = useState([]);
  const [FooterNotes, setFooterNotes] = useState("");
  // console.log("FooterNotes: ", FooterNotes);
  const [FetchCall, setFetchCall] = useState(false);
  const [FetchTrendsFilters, setFetchTrendsFilters] = useState(false);

  const [SelectionAll, setSelectionAll] = useState([]);
  const [Selection1, setSelection1] = useState("");
  const [Selection2, setSelection2] = useState("");
  const [TrendChartdata, setTrendChartdata] = useState({});

  const [SelectedChart, setSelectedChart] = useState("");
  // const [TimePeriodError, setTimePeriodError] = useState('');
  const [KpiPerc, setKpiPerc] = useState("");
  const [MediaPerc, setMediaPerc] = useState("");
  const [ShowComp, setShowComp] = useState(0);
  const [ShowCompValue, setShowCompValue] = useState(0);

  //Analytical filters state
  const [AllFilters, setAllFilters] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const Fixed = localStorage.getItem("AnalyticFilter")
    ? AnalyticFilter.Fixed
    : [];

  const [Chartdata, setChartdata] = useState({});
  console.log("Chartdata: ", Chartdata);
  // const [DownloadSpendschart, setDownloadSpendschart] = useState(false);
  // const [DownloadSaleschart, setDownloadSaleschart] = useState(false);
  // const [DownloadTrendschart, setDownloadTrendschart] = useState(false);

  const [GetTabFlag, setGetTabFlag] = useState(true);
  const [FilterTabShow, setFilterTabShow] = useState(false);
  const [ShowSummary, setShowSummary] = useState(0);
  const [ChartHeight, setChartHeight] = useState("");
  const [Notes, setNotes] = useState("");
  const [ResetFilterFlag, setResetFilterFlag] = useState(false);

  // chart download states for hook
  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const [isChartdataReady, setIsChartdataReady] = useState(false);

  const userName = localStorage.getItem("NameSkewb");

  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const [fiexedFilters, setFixedFilters] = useState({});
  // console.log("fiexedFilters: ", fiexedFilters);

  // console.log(sb1_name, sb2_name);
  useEffect(() => {
    const url = window.location.href;
    const text = url.split("/");
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse]);


  const fnDisabledSubmitBtn = () => {
    if (
      DefaultStartDate !== "NaN-NaN-NaN" &&
      DefaultEndDate !== "NaN-NaN-NaN"
    ) {
      return false;
    } else if (
      DefaultStartDate === "NaN-NaN-NaN" ||
      DefaultEndDate === "NaN-NaN-NaN"
    ) {
      return true;
    }
  };

  // useEffect(() => {
  //   if (DownloadTrendschart) {

  //     const chartContainer = document.getElementById("trends_chart");
  //     domtoimage
  //       .toPng(chartContainer)
  //       .then(function (dataUrl) {
  //         var string = "";
  //         for (var i = 0; i < Fixed.length; i++) {
  //           string += Fixed[i].id;
  //           if (ActiveFilters.length > 0) {
  //             string += ",";
  //           }
  //         }

  //         for (var j = 0; j < ActiveFilters.length; j++) {
  //           string += ActiveFilters[j].name;
  //           if (ActiveFilters.length - 1 !== j) {
  //             string += ","
  //           }
  //         }

  //         setLoader(true);
  //         axiosChartDownload.post("download_chart/create-image/",
  //           {
  //             "title": "Buisness Landscape : Trends",
  //             "filter": Analyse.brand,
  //             "hierarchy": string,
  //             "timeframe": timeframe,
  //             "start_date": DefaultStartDate,
  //             "end_date": DefaultEndDate,
  //             "base64_image": dataUrl.split(",")[1],
  //             theme: localStorage.getItem("CurrentTheme"),
  //             LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

  //           }

  //         ).then((response) => {
  //           if (response.data.error === 1) {
  //             toast.error(response.data.erroMsg);
  //             setLoader(false);
  //           } else {
  //             // console.log("response.data: ", response.data);
  //             var link = document.createElement("a");
  //             link.target = "_blank";
  //             link.href = response.data.data.combined_graph_url;
  //             console.log("link.href: ", link.href);
  //             downloadImage(link.href);
  //             // document.body.appendChild(link);
  //             // link.click();
  //             // document.body.removeChild(link);
  //             setLoader(false);
  //           }
  //         }).catch((data) => {
  //           setLoader(false);
  //         });
  //       })
  //       .catch(function (error) {
  //         setLoader(false)
  //         console.error("Error while generating chart image:", error);
  //       });

  //     setDownloadTrendschart(false)

  //   }
  // }, [DownloadTrendschart, DefaultStartDate, DefaultEndDate, Analyse.brand, ActiveFilters, timeframe])

  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Fixed,
    ActiveFilters,
    setLoader,
    Analyse.Brand,
    timeframe,
    DefaultStartDate,
    DefaultEndDate,
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    if (AnalyticFilterFlag && AnalyticFilter && CampaignAnalyticFilter) {
      setAllFilters(AnalyticFilter.AllFilters);
      setActiveFilters(AnalyticFilter.ActiveFilters);
      setAnalyticFilterFlag(false);
    }
  }, [AnalyticFilter, CampaignAnalyticFilter, AnalyticFilterFlag]);

  useEffect(() => {
    if (refSpends.current !== null) {
      setSpendsHeight(refSpends.current.clientHeight);
    }
    if (refSales.current !== null) {
      setSalesHeight(refSales.current.clientHeight);
    }
  }, [MediaSunburstData, SunburstData]);

  const [Test, setTest] = useState(false);
  useEffect(() => {
    if (Test) {
      setTest(false);
    }
  }, [Test]);

  useEffect(() => {
    const controller = new AbortController();

    if (GetTabFlag) {
      setLoader(true);
      axios
        .post("get_eda_tabs/", { brand: Analyse.Brand, kpi: Analyse.KPI }, {
          signal: controller.signal
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setEdaTabs(response.data.data.tabs);
            setSelectedChart(response.data.data.tabs[0].tab_short);
            setLoader(false);
          }
          setShowComp(response.data.data.show_compare);
          setGetTabFlag(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }

    return () => {
      controller.abort();
    };
  }, [Analyse.Brand, Analyse.KPI, GetTabFlag]);
  // console.log(ShowComp, "ShowComp")
  useEffect(() => {
    setLoader(true);

    let page = "";
    if (SelectedChart === "overview") {
      page = "eda";
    }
    if (SelectedChart === "trends") {
      page = "trends";
    }
    if (page !== "") {
      axios
        .post("get_timeframes/", {
          page: page,
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTimeFramesAll(response.data.data);
            setTimeframe(response.data.data[0].key);
            setFromDatesAll(response.data.data[0].fromdates);
            setToDatesAll(response.data.data[0].todates);
            setDefaultStartDate(response.data.data[0].default_startdate);
            setDefaultEndDate(response.data.data[0].default_enddate);
            setStartdate(response.data.data[0].start_date);
            setEnddate(response.data.data[0].end_date);
            setLoader(false);
            setFetchCall(true);
            setFetchTrendsFilters(true);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [SelectedChart, Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    const controller = new AbortController();

    if (
      FetchTrendsFilters &&
      timeframe !== "" &&
      DefaultStartDate !== "" &&
      DefaultEndDate !== "" &&
      SelectedChart === "trends"
    ) {
      if (
        FromDatesAll.indexOf(DefaultStartDate) >
        ToDatesAll.indexOf(DefaultEndDate)
      ) {
        toast.error("Please enter valid time period");
      } else {
        axios
          .post("get_trends_filters/", {
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: timeframe,
            starttime: DefaultStartDate,
            endtime: DefaultEndDate,
          },
          {
            signal: controller.signal
          })
          .then((response) => {
            if (response.data.error === 1) {
              toast.error(response.data.erroMsg);
              setLoader(false);
            } else {
              setSelectionAll(response.data.data);
              setSelection1(response.data.data[0].value);
              setFetchTrendsFilters(false);
              setLoader(false);
            }
          })
          .catch((data) => {
            setLoader(false);
          });
      }
    }

    return () => {
      controller.abort();
    };
  }, [
    Analyse,
    timeframe,
    DefaultStartDate,
    DefaultEndDate,
    FromDatesAll,
    ToDatesAll,
    SelectedChart,
    FetchTrendsFilters,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    // console.log("useeffect called...............................!");
    if (FetchCall && SelectedChart === "overview") {
      // console.log("FetchCall: ", FetchCall);
      // console.log("SelectedChart: ", SelectedChart);
      // console.log("timeframe: ", timeframe);
      // console.log("DefaultStartDate: ", DefaultStartDate);
      // console.log("DefaultEndDate: ", DefaultEndDate);
      // console.log("Chartdata inside useeffect: ", Chartdata);
      if (
        timeframe !== "" &&
        DefaultStartDate !== "" &&
        DefaultEndDate !== "" &&
        // JSON.stringify(Chartdata) !== "{}"
        isChartdataReady
      ) {
        // if (FromDatesAll.indexOf(DefaultStartDate) > ToDatesAll.indexOf(DefaultEndDate)) {
        //   toast.error('Please enter valid time period');
        //   setFetchCall(false)
        // } else {
        setLoader(true);
        
        setSunburstData([]);
        setMediaSunburstData([]);
        axios
          .post("get_sunburst/", {
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: timeframe,
            starttime: DefaultStartDate,
            endtime: DefaultEndDate,
            chartdata: Chartdata,
            compkey: ShowCompValue,
            theme: localStorage.getItem("CurrentTheme"),
            LightThemeColorCode: [
              localStorage.getItem("ColorCode"),
              LightThemeHexCode[localStorage.getItem("ColorCode")],
            ],
          },
          {
            signal: controller.signal
          }
          )
          .then((response) => {
            if (response.data.error === 1) {
              // toast.error(response.data.erroMsg);
              setLoader(false);
            } else {
              setSunburstData(response.data.chart.kpi);
              setMediaSunburstData(response.data.chart.media);
              setDecimalPoint(response.data.chart.decimal);
              setMediaPerc(response.data.chart.mediaper);
              setKpiPerc(response.data.chart.kpiper);
              setTest(true);
              setSummary(response.data.chart.summary);
              setShowSummary(response.data.chart.show_summary);
              setLegends(response.data.chart.Legends);
              setSb1_name(response.data.chart.sb1_name);
              setSb2_name(response.data.chart.sb2_name);
              setSb1_tag(response.data.chart.sb1_tag);
              setSb2_tag(response.data.chart.sb2_tag);
              setFooterNotes(response.data.notes);
              setLoader(false);
            }
            setFetchCall(false);
            // setIsChartdataReady(false);
          })
          .catch((data) => {
            setLoader(false);
          });
        // }
      }
    }
    return () => {
      controller.abort();
    };
  }, [
    Analyse.Brand,
    Analyse.KPI,
    DefaultStartDate,
    DefaultEndDate,
    timeframe,
    FetchCall,
    FromDatesAll,
    ToDatesAll,
    SelectedChart,
    Chartdata,
    ShowCompValue,
    isChartdataReady
  ]);





  useEffect(() => {
    if (
      FetchCall &&
      SelectedChart === "trends" &&
      JSON.stringify(Chartdata) !== "{}"
    ) {
      if (
        timeframe !== "" &&
        DefaultStartDate !== "" &&
        DefaultEndDate !== "" &&
        Selection1 !== ""
      ) {
        // if (FromDatesAll.indexOf(DefaultStartDate) > ToDatesAll.indexOf(DefaultEndDate)) {
        //   toast.error('Please enter valid time period');
        //   setFetchCall(false)
        // } else {
        setLoader(true);
        setSunburstData([]);
        setMediaSunburstData([]);
        axios
          .post("get_trends/", {
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            timeframe: timeframe,
            starttime: DefaultStartDate,
            endtime: DefaultEndDate,
            selection: [Selection1, Selection2],
            chartdata: Chartdata,
            theme: localStorage.getItem("CurrentTheme"),
            LightThemeColorCode: [
              localStorage.getItem("ColorCode"),
              LightThemeHexCode[localStorage.getItem("ColorCode")],
            ],
          })
          .then((response) => {
            if (response.data.error === 1) {
              toast.error(response.data.erroMsg);
              setLoader(false);
            } else {
              // console.log(response.data)
              // setTest(true)
              setTrendChartdata(response.data.data.chartdata);
              setSummary(response.data.data.summary);
              setShowSummary(response.data.data.show_summary);
              setFooterNotes(response.data.notes);
              setLoader(false);
            }
            setFetchCall(false);
          })
          .catch((data) => {
            setLoader(false);
          });
        // }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    DefaultStartDate,
    DefaultEndDate,
    timeframe,
    FetchCall,
    FromDatesAll,
    ToDatesAll,
    Selection1,
    Selection2,
    SelectedChart,
    Chartdata,
  ]);

  useEffect(() => {
    setFetchCall(true);
    setFetchTrendsFilters(true);
    setGetTabFlag(true);
    setSelection1("");
    setSelection2("");
  }, [Analyse.Brand, Analyse.KPI]);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAll.length; i++) {
      if (TimeFramesAll[i].key === value) {
        setFromDatesAll(TimeFramesAll[i].fromdates);
        setToDatesAll(TimeFramesAll[i].todates);
        setDefaultStartDate(TimeFramesAll[i].default_startdate); // added this line by Naeem
        setDefaultEndDate(TimeFramesAll[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframe(value);
    setFetchCall(true);
  };

  const DownloadData1 = () => {
    setLoader(true);
    axiosDownload
      .post("eda/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        starttime: DefaultStartDate,
        endtime: DefaultEndDate,
        selection: [sb1_name, sb2_name],
        // chartdata: {"H0":{"selection":[],"Filter":["loreal"]},"H1":{"selection":[],"Filter":[]}},
        chartdata: Chartdata,
        theme: localStorage.getItem("CurrentTheme"),
        compkey: ShowCompValue,
        download_type: "",
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const DownloadData2 = () => {
    // console.log("DownloadData2");
    setLoader(true);
    axiosDownload
      .post("trends/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        starttime: DefaultStartDate,
        endtime: DefaultEndDate,
        selection: [Selection1, Selection2],
        // chartdata: {"H0":{"selection":[],"Filter":["loreal"]},"H1":{"selection":[],"Filter":[]}},
        chartdata: Chartdata,
        theme: localStorage.getItem("CurrentTheme"),
        compkey: ShowCompValue,
        download_type: "",
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadData = () => {
    if (SelectedChart === "overview") {
      DownloadData1();
    } else if (SelectedChart === "trends") {
      DownloadData2();
    }
  };

  // const downloadPDF = () => {
  //   const chartContainer = document.getElementById("sunburst_chart");
  //   if (chartContainer) {
  //     domtoimage
  //       .toPng(chartContainer)
  //       .then(function (dataUrl) {
  //         // console.log(dataUrl);
  //         setLoader(true);
  //         axiosDownload
  //           .post("eda/", {
  //             brand: Analyse.Brand,
  //             kpi: Analyse.KPI,
  //             timeframe: timeframe,
  //             starttime: DefaultStartDate,
  //             endtime: DefaultEndDate,
  //             selection: [sb1_name, sb2_name],
  //             chartdata: Chartdata,
  //             theme: localStorage.getItem("CurrentTheme"),
  //             compkey: ShowCompValue,
  //             download_type: "PDF",
  //           })
  //           .then((response) => {
  //             setLoader(false);
  //             console.log("excel: ", response.data);
  //             // download pdf.....start
  //             axiosChartDownload
  //             .post("download_pdf/create/", {
  //               images: [dataUrl.split(",")[1]],
  //               document: {
  //               table1_data: response.data.table1_data,
  //               title: "ROI Chart",
  //               textcontent: "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
  //               header_content: "Loreal Haircolor Garnier- Sales",
  //               footer_content: "Private & Confidential",
  //               date:"Jan 2019-Dec 2019",
  //               table_heading1:"Comparitive ROI",
  //               image_heading1:"ROI chart for image",
  //               table_heading2:"Comparitive ROI2",
  //               image_heading2:"ROI chart for image2",
  //               username:"Skewb Admin",
  //               timeframe:"",
  //               start_date:"",
  //               end_date:"",
  //               titlename:"Overview",
  //               category:"Overall",
  //               subcategory:"Overall"
  //               }
  //             })
  //             .then((response) => {
  //               if (response.data.error === 1) {
  //                 toast.error(response.data.erroMsg);
  //                 setLoader(false);
  //               } else {
  //                 console.log("response pdf: ", response.data);
  //                 var link = document.createElement("a");
  //                 link.target = "_blank";
  //                 link.href = response.data.pdf_url;
  //                 document.body.appendChild(link);
  //                 link.click();
  //                 document.body.removeChild(link);
  //               }
  //             })
  //             .catch((data) => {
  //               setLoader(false);
  //             });
  //             // download pdf end.............
  //           })
  //           .catch((data) => {
  //             setLoader(false);
  //           })
  //       })
  //       .catch(function (error) {
  //         setLoader(false);
  //         console.error("Error while generating pdf:", error);
  //       });

  //   }

  // }

  // When Activefilter change : prepare chartdata for input of get api call

  const downloadPDF1 = async () => {
    setLoader(true);
    const chartContainers = [
      document.getElementById("media_sunburst_chart"),
      document.getElementById("sunburst_chart"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("eda/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
          selection: [sb1_name, sb2_name],
          chartdata: Chartdata,
          theme: localStorage.getItem("CurrentTheme"),
          compkey: ShowCompValue,
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              table2_data: response.data.table2_data,
              // title: "Business Landscape",
              // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
              // header_content: "Loreal Haircolor Garnier- Sales",
              footer_content: "Private & Confidential",
              // date: "Jan 2019-Dec 2019",
              table_heading1: "Spends",
              image_heading1: "Spends",
              table_heading2: "Sales",
              image_heading2: "Sales",
              username: userName,
              timeframe: timeframe,
              start_date: DefaultStartDate,
              end_date: DefaultEndDate,
              titlename: "Business Landscape",
              category: "",
              // subcategory: "Overall",
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              tabname: "eda",
              chartdata: Chartdata,
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadPDF2 = async () => {
    setLoader(true);
    const chartContainers = [document.getElementById("trends_chart")];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("trends/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
          selection: [Selection1, Selection2],
          chartdata: Chartdata,
          theme: localStorage.getItem("CurrentTheme"),
          compkey: ShowCompValue,
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post(
          "download_pdf/create/",
          {
            images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
            document: {
              table1_data: response.data.table1_data,
              // title: "ROI Chart",
              // textcontent:
              // "We take digital marketing analytics one step further with unparalleled prescriptive deep-dive. Our innovative end-to-end analytics solution gives you relevant data & helps in strategy formulation & planning at the lowest cuts.",
              // header_content: "Loreal Haircolor Garnier- Sales",
              titlename: "Business Landscape",
              footer_content: "Private & Confidential",
              table_heading1: "Trend Analysis",
              image_heading1: "Trend Analysis",
              username: userName,
              timeframe: timeframe,
              start_date: DefaultStartDate,
              end_date: DefaultEndDate,
              category: "",
              chartdata: Chartdata,
              tabname: "eda",
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
            },
          }
        );
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadPDF = () => {
    if (SelectedChart === "overview") {
      downloadPDF1();
    } else if (SelectedChart === "trends") {
      downloadPDF2();
    }
  };

  useEffect(() => {
    const chartdata = {};
    if (Fixed.length !== 0) {
      for (var r = 0; r < Fixed.length; r++) {
        const Filter = [];
        Filter.push(Fixed[r].value);
        chartdata[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };
      }
    }

    if (ActiveFilters.length !== 0) {
      for (var i = 0; i < ActiveFilters.length; i++) {
        const selection = [];
        if (ActiveFilters[i]["Selected"].length > 0) {
          setResetFilterFlag(true);
        } else {
          setResetFilterFlag(false);
        }

        if ("Selected" in ActiveFilters[i]) {
          for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
            var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
            selection.push(temp_arr[temp_arr.length - 1]);
          }
        }

        // chartdata[ActiveFilters[i].name] = {
        //   selection:
        //     typeof Chartdata[ActiveFilters[i].name] !== "undefined"
        //       ? Chartdata[ActiveFilters[i].name].selection
        //       : [],
        //   // Filter: selection,
        //   Filter: 
        //     typeof Chartdata[ActiveFilters[i].name] !== "undefined"
        //       ? Chartdata[ActiveFilters[i].name].Filter
        //       : [],
        // };

        chartdata[ActiveFilters[i].name] = {
          selection:
           
               [],
          // Filter: selection,
          Filter: 
               [],
        };
      }
    }

    setChartdata(chartdata);
    setIsChartdataReady(true);
    setFetchCall(true);
    setSelectedCheckboxes([]);
  }, [ActiveFilters]);

  //HEIRARCHY FILTERS
  const GetOption = (current_level, selection, destination_index) => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown_options/", {
        hirarchy: Analyse.Brand,
        current_level: current_level,
        old_selection: selection !== "all" ? selection : "",
      })
      .then((response) => {
        const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
        var sort_arr = [...AllFilters];
        for (var i = 0; i < sort_arr.length; i++) {
          if (parseInt(sort_arr[i].level) === current_level) {
            var obj = sort_arr[i];
            obj["filters"] = response.data.data.options;
            obj["Selected"] = [];

            const temp_index = sort_arr.indexOf(sort_arr[i]);
            if (temp_index > -1) {
              // only splice array when item is found
              sort_arr.splice(temp_index, 1); // 2nd parameter means remove one item only
            }

            ActiveFiltersTemp.splice(destination_index, 0, obj);
          }
        }
        let data = [...ActiveFiltersTemp];
        for (let k = 0; k < ActiveFiltersTemp.length; k++) {
          if (destination_index < k) {
            data[k]["filters"] = [];
            data[k]["Selected"] = [];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data,
        };

        localStorage.setItem(
          "AnalyticFilter",
          JSON.stringify(AnalyticFilterTemp)
        );

        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const AddHeirarchyFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let selection_value = [];
    var i = ActiveFiltersCompTemp.length - 1;
    while (i >= 0) {
      if (ActiveFilters[i]["Selected"].length !== 0) {
        for (var j = 0; j < ActiveFilters[i]["Selected"].length; j++) {
          selection_value.push(
            ActiveFilters[i]["Selected"][j].value === "all"
              ? ""
              : ActiveFilters[i]["Selected"][j].value
          );
        }
      }
      i--;
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    GetOption(
      parseInt(AllFilters[index].level),
      selection,
      ActiveFiltersCompTemp.length
    );

    let filter = AllFilters[index];
    filter.Selected = [];

    // setActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);
  };

  const onChangeFilter = (selected, index, data) => {
    const ActiveFiltersTemp = data
      ? data
      : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = selected;

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        selection: selection !== "all" ? selection : "",
        next_level: next_level,
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp];
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {
          if (response.data.data[data[i].level]) {
            data[i]["Selected"] = [];
            data[i]["filters"] = response.data.data[data[i].level];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data,
        };

        localStorage.setItem(
          "AnalyticFilter",
          JSON.stringify(AnalyticFilterTemp)
        );

        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {
      let selection_value = [];

      var i = result.destination.index - 1;
      while (i >= 0) {
        if (ActiveFilters[i]["Selected"].length !== 0) {
          for (var j = 0; j < ActiveFilters[i]["Selected"].length; j++) {
            selection_value.push(
              ActiveFilters[i]["Selected"][j].value === "all"
                ? ""
                : ActiveFilters[i]["Selected"][j].value
            );
          }
        }
        i--;
      }
      const selection = selection_value.toString().replaceAll(",", ";");
      GetOption(
        parseInt(result.draggableId),
        selection,
        result.destination.index
      );
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);

      // //Set analytical filters data in localstorage
      // const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter"));
      // const AnalyticFilterTemp = {
      //   AllFilters: AllFiltersTemp,
      //   Fixed: AnalyticFilter.Fixed,
      //   ActiveFilters: ActiveFiltersTemp
      // };
      // localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));

      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );

      onChangeFilter(
        ActiveFiltersTemp[result.destination.index].Selected,
        result.destination.index,
        ActiveFiltersTemp
      );
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }
  };

  // click on active hierarchy filter
  const RemoveHeirarchyFilter = (item) => {
    // console.log("clicked: ", item);
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
  };


  // h0, h1, h2 filter click..........
  const IsInAllHeirarchyFilters = (item) => {
    // console.log("clicked: ", item);
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true;
      }
    }
    return false;
  };

  const ResetFilters = () => {
    var active_filter = [];
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      obj["Selected"] = [];
      active_filter.push(obj);
    }

    setResetFilterFlag(false);
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter,
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));
    setActiveFilters(active_filter);
  };

  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [SelectedChart, FetchCall]);

  // filters code start

  const [HFilters, setHFilters] = useState([]);
  // console.log("HFilters: ", HFilters);
  // console.log("Chartdata: ", Object.keys(Chartdata));

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  // console.log("selectedCheckboxes: ", selectedCheckboxes);


  useEffect(() => {
    const fetchHierarchyFilters = async () => {
    if(JSON.stringify(Chartdata) !== "{}") {
        setHFilters([]);
      try {
        const response = await axios.post(
          "hieracrhy_data/",
          { hierarchy_list: Object.keys(Chartdata) }
        );
  
        const data = response.data;
        // console.log(data);
        setHFilters(data.data);
        setFixedFilters(data.filter);
        // localStorage.setItem("hierarchyFilters", JSON.stringify(data.data));
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };
  };
    fetchHierarchyFilters();
  }, [Chartdata])

  return (
    <>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w pt-0">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Data Landscape */}
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                          return "/" + item.key === location.pathname
                            ? item.value
                            : null;
                        })
                      : null}
                  </h1>
                </div>
              </div>
              {/* change 2 class add */}
              <div className="row pr-0 mt-1" style={{ alignItems: "center", }}>
                <AllHeirarchyFilter
                  key={Chartdata}
                  OnlyHeirarchyFilter={true}
                  Fixed={Fixed}
                  ActiveFilters={ActiveFilters}
                  setActiveFilters={setActiveFilters}
                  AllFilters={AllFilters}
                  AddHeirarchyFilter={AddHeirarchyFilter}
                  RemoveHeirarchyFilter={RemoveHeirarchyFilter}
                  IsInAllHeirarchyFilters={IsInAllHeirarchyFilters}
                  Chartdata={Chartdata}
                  setChartdata={setChartdata} 
                  setFetchCall={setFetchCall}
                  HFilters={HFilters}
                  selectedCheckboxes={selectedCheckboxes}
                  setSelectedCheckboxes={setSelectedCheckboxes}
                  SelectedChart={SelectedChart}
                  ShowCompValue={ShowCompValue}
                  setShowCompValue={setShowCompValue}
                  ShowComp={ShowComp}
                />
                {false && <div className="col-xl-2 pr-0">
                  <div className="card my-1">
                    {/* change 1 class + css */}
                    <div className="card-body d-flex align-items-center justify-content-center custom-selected-filter">
                      <h6
                        className="mx-3 m-0 text-center primary-text cursor-pointer"
                        onClick={() => {
                          setFilterTabShow(!FilterTabShow);
                        }}
                      >
                        <i className="fa fa-filter" aria-hidden="true"></i>{" "}
                        Selected Filter
                      </h6>

                      {ResetFilterFlag ? (
                        <h6 className="m-0 text-center primary-text cursor-pointer custom-reset">
                          <p
                            className="mx-2 m-0"
                            title="Reset Filter"
                            onClick={() => {
                              ResetFilters();
                            }}
                          >
                            {" "}
                            &#8635;{" "}
                          </p>
                        </h6>
                      ) : null}
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>

          <div className="container-fluid main-content-w pt-1">
            <div className="row">
              {false ? ( // FilterTabShow
                <ActiveHeirarchyAndCampaignFilter
                  SelectedChart={SelectedChart}
                  setFetchCall={setFetchCall}
                  ShowCompValue={ShowCompValue}
                  setShowCompValue={setShowCompValue}
                  ShowComp={ShowComp}
                  OnlyHeirarchyFilter={true}
                  onChangeFilter={onChangeFilter}
                  onDragEnd={onDragEnd}
                  ActiveFilters={ActiveFilters}
                  setFilterTabShow={setFilterTabShow}
                  Fixed={Fixed}
                />
              ) : null}
              <div
                className={
                  parseInt(ShowSummary) === 1 ? "col-xl-9" : "col-xl-12"
                }
              >
                {/* <TestSun /> */}
                <div
                  className="card card-min-height optimizer-table-height"
                  ref={refGraphDiv}
                >
                  <div className="card-body brand-card pt-1" >
                    <div className="row align-items-center ce-filter justify-content-between" >
                      <div className="col-sm-12 col-md-2 col-lg-2 d-flex">
                        <select
                          style={{ width: "115px" }}
                          className="form-select custom-input-box"
                          value={timeframe}
                          onChange={onChangeHandler}
                        >
                          {TimeFramesAll.map((item, i) => {
                            return (
                              <option key={i} value={item.value}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center justify-content-center">
                        <div className="row align-items-center">
                          <div className="col-sm-3">
                            <h3 className="m-0 custom-input-box">
                              <label className="login-lable brand-menu-label m-0">
                                For time period :
                              </label>
                            </h3>
                          </div>
                          <div className="col-sm-3">
                            <select
                              className="form-select custom-input-box"
                              value={DefaultStartDate}
                              onChange={(e) => {
                                setDefaultStartDate(e.target.value);
                                if (
                                  ToDatesAll.indexOf(DefaultEndDate) <
                                  FromDatesAll.indexOf(e.target.value)
                                ) {
                                  setDefaultEndDate(
                                    ToDatesAll[
                                      FromDatesAll.indexOf(e.target.value)
                                    ]
                                  );
                                }
                              }}
                            >
                              {FromDatesAll.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-1">
                            <h3 className="m-0 custom-input-box">
                              <label className="login-lable brand-menu-label m-0">
                                To
                              </label>
                            </h3>
                          </div>
                          <div className="col-sm-3">
                            <select
                              className="form-select custom-input-box"
                              value={DefaultEndDate}
                              onChange={(e) => {
                                setDefaultEndDate(e.target.value);
                              }}
                            >
                              {ToDatesAll.map((item, index) => {
                                if (
                                  FromDatesAll.indexOf(DefaultStartDate) <=
                                  index
                                ) {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </select>
                          </div>
                          <div className="col-sm-2">
                            <button
                              className="btn brand-btn-info custom-input-box eda-submit-btn"
                              type="button"
                              onClick={() => {
                                setFetchCall(true);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div> */}
                      {/* new code start */}
                      {timeframe !== "Monthly" ? (
                        <div className="col-sm-6 col-md-10 col-lg-5 d-flex align-items-center justify-content-center">
                          <div className="row align-items-center">
                            <div className="col-sm-3">
                              <h3 className="m-0 custom-input-box">
                                <label className="login-lable brand-menu-label m-0">
                                  For time period :
                                </label>
                              </h3>
                            </div>
                            <div className="col-sm-3">
                              <select
                                className="form-select custom-input-box"
                                value={DefaultStartDate}
                                onChange={(e) => {
                                  setDefaultStartDate(e.target.value);
                                  if (
                                    ToDatesAll.indexOf(DefaultEndDate) <
                                    FromDatesAll.indexOf(e.target.value)
                                  ) {
                                    setDefaultEndDate(
                                      ToDatesAll[
                                        FromDatesAll.indexOf(e.target.value)
                                      ]
                                    );
                                  }
                                }}
                              >
                                {FromDatesAll.map((item, index) => {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-sm-1">
                              <h3 className="m-0 custom-input-box">
                                <label className="login-lable brand-menu-label m-0">
                                  To
                                </label>
                              </h3>
                            </div>
                            <div className="col-sm-3">
                              <select
                                className="form-select custom-input-box"
                                value={DefaultEndDate}
                                onChange={(e) => {
                                  setDefaultEndDate(e.target.value);
                                }}
                              >
                                {ToDatesAll.map((item, index) => {
                                  if (
                                    FromDatesAll.indexOf(DefaultStartDate) <=
                                    index
                                  ) {
                                    return (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </select>
                            </div>
                            <div className="col-sm-2">
                              <button
                                className="btn brand-btn-info custom-input-box eda-submit-btn"
                                type="button"
                                onClick={() => {
                                  setFetchCall(true);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {timeframe === "Monthly" ? (
                        <div
                          className="col-sm-3 col-md-10 col-lg-2 d-flex align-items-center justify-content-space-between gap-2"
                          style={{ border: "none" }}
                        >
                          {DefaultStartDate &&
                            DefaultEndDate &&
                            startdate &&
                            enddate && (
                              <Calender
                                setDefaultStartDate={setDefaultStartDate}
                                setDefaultEndDate={setDefaultEndDate}
                                DefaultStartDate={DefaultStartDate}
                                DefaultEndDate={DefaultEndDate}
                                startDate={startdate}
                                endDate={enddate}
                              />
                            )}
                          <div className="col-sm-2">
                            <button
                              disabled={fnDisabledSubmitBtn()}
                              className="btn brand-btn-info custom-input-box eda-submit-btn"
                              type="button"
                              style={{
                                border: fnDisabledSubmitBtn()
                                  ? "1px solid #3e5056"
                                  : "1px solid #d6ff41",
                              }}
                              onClick={() => {
                                setFetchCall(true);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : null}

                      {/* new code end */}
                      <div className="custom-col col-sm-12 col-md-6 col-lg-3 d-flex align-items-center justify-content-sm-end my-2"
                        style={{ border: "none" }}>
                        {EdaTabs.map((item, index) => {
                          return (
                            <p
                              key={index}
                              className={
                                SelectedChart === item.tab_short
                                  ? "m-0 px-2 mx-2 cursor-pointer text-white custom-input-box lt-st"
                                  : "m-0 px-2 mx-2 cursor-pointer text-lable text-white custom-input-box"
                              }
                              onClick={() => {
                                setSelectedChart(item.tab_short);
                              }}
                            >
                              {item.tab_name}
                            </p>
                          );
                        })}
                        {/* <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', display: 'none' }} title="Download" onClick={() => DownloadData()} /> */}

                        {/* <Tooltip /> */}
                        {Notes !== "" ? (
                          <>
                            {/* <img src={InfoIcon} className="mx-2" alt=""  style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '25px'
                              }} /> */}
                            <p className="m-0 px-2">
                              <svg
                                src={InfoIcon}
                                id="notes"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                className="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                              </svg>
                            </p>

                            <Tooltip anchorSelect="#notes" clickable>
                              {/* <div className="api-notes">{Notes}</div> */}
                              <div
                                className="api-notes"
                                dangerouslySetInnerHTML={{ __html: Notes }}
                              ></div>
                            </Tooltip>
                          </>
                        ) : null}

                        {(downloadFlag.chart ||
                          downloadFlag.data ||
                          downloadFlag.pdf) && (
                          <span className="flex-shrink-0 dropdown">
                            <Link
                              to="#"
                              className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                              id="dropdownUser2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {/* <p className="m-0 px-2">
                              {" "}

                              <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', width: '25px', height: '25px' }} title="Download" />
                            </p> */}

                              <p className="m-0 px-2">
                                <svg
                                  src={Download}
                                  title="Download"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  className="bi bi-download"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                              </p>
                            </Link>
                            <ul
                              className="dropdown-menu text-small shadow"
                              aria-labelledby="dropdownUser2"
                            >
                              {SelectedChart !== "trends" &&
                                downloadFlag.chart && (
                                  <li onClick={(e) => {}}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (MediaSunburstData.length > 0) {
                                          // setDownloadSpendschart(true);
                                          setIsDownloadingChart(true);
                                          setChartContainerID(
                                            "media_sunburst_chart"
                                          );
                                          setDownloadedImageName("Spend Chart");
                                          setDownloadingChartTitle(
                                            "Buisness Landscape : Spends"
                                          );
                                        } else {
                                          toast.error(
                                            "Chartdata is not available."
                                          );
                                        }
                                      }}
                                    >
                                      Download Spends Chart
                                    </Link>
                                  </li>
                                )}

                              {SelectedChart !== "trends" &&
                                downloadFlag.chart && (
                                  <li onClick={(e) => {}}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (SunburstData.length > 0) {
                                          // setDownloadSaleschart(true);
                                          setIsDownloadingChart(true);
                                          setChartContainerID("sunburst_chart");
                                          setDownloadedImageName("Sales Chart");
                                          setDownloadingChartTitle(
                                            "Buisness Landscape : Sales"
                                          );
                                        } else {
                                          toast.error(
                                            "Chartdata is not available."
                                          );
                                        }
                                      }}
                                    >
                                      Download Sales Chart
                                    </Link>
                                  </li>
                                )}

                              {SelectedChart !== "overview" &&
                                downloadFlag.chart && (
                                  <li onClick={(e) => {}}>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (SelectedChart === "trends") {
                                          // setDownloadTrendschart(true);
                                          setIsDownloadingChart(true);
                                          setChartContainerID("trends_chart");
                                          setDownloadedImageName(
                                            "Trends Chart"
                                          );
                                          setDownloadingChartTitle(
                                            "Buisness Landscape : Trends"
                                          );
                                        } else {
                                          toast.error(
                                            "Chartdata is not available."
                                          );
                                        }
                                      }}
                                    >
                                      Download Trends Chart
                                    </Link>
                                  </li>
                                )}

                              {downloadFlag.data && (
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      downloadData()
                                    }
                                    }
                                  >
                                    Download Data
                                  </Link>
                                </li>
                              )}
                              {downloadFlag.pdf && (
                                <li onClick={(e) => {}}>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      downloadPDF()
                                    }}
                                  >
                                    Download PDF
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </span>
                        )}
                      </div>
                    </div>
                    {SelectedChart === "trends" ? (
                      <>
                        <div className="row justify-content-center ce-filter mt-1">
                          <div className="col-sm-4 d-flex align-items-center">
                            <p className="m-0 px-3 text-white text-nowrap">
                              Selection 1:
                            </p>
                            <select
                              className="form-select w-100"
                              value={Selection1}
                              onChange={(e) => {
                                setSelection1(e.target.value);
                                setFetchCall(true);
                              }}
                            >
                              {SelectionAll.map((item, i) => {
                                return Selection2 === item.value ? null : (
                                  <option key={i} value={item.value}>
                                    {item.value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-4 d-flex align-items-center">
                            <p className="m-0 px-3 text-white text-nowrap">
                              Selection 2:
                            </p>
                            <select
                              className="form-select w-100"
                              value={Selection2}
                              onChange={(e) => {
                                setSelection2(e.target.value);
                                setFetchCall(true);
                              }}
                            >
                              <option value={""}></option>
                              {SelectionAll.map((item, i) => {
                                return Selection1 === item.value ? null : (
                                  <option key={i} value={item.value}>
                                    {item.value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        {JSON.stringify(TrendChartdata) !== "{}" ? (
                          <TrendChart
                            TrendChartdata={TrendChartdata}
                            height={SpendsHeight}
                            TrendChartRef={TrendChartRef}
                          />
                        ) : null}
                      </>
                    ) : null}
                    {SelectedChart === "overview" ? (
                      <>
                        <div className="row align-items-center custom-chart-title">
                          <div className="col-sm-6">
                            <div className="d-flex align-items-center px-1">
                              <h3 className="data-spends m-0">{sb1_name}</h3>
                              <p
                                className="data-spends-values text-white px-2 m-0 pb-0"
                                style={{ fontSize: "0.75rem" }}
                              >
                                [{sb1_tag}]
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="d-flex align-items-center px-1">
                              <h3 className="data-spends m-0">{sb2_name}</h3>
                              <p
                                className="data-spends-values text-white px-2 m-0 pb-0"
                                style={{ fontSize: "0.75rem" }}
                              >
                                [{sb2_tag}]
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row align-items-center pt-3"
                          id="overview_chart"
                        >
                          <div className="col-sm-6">
                            <div
                              className="sunburst-chart-height"
                              ref={refSpends}
                            >
                              {MediaSunburstData.length > 0 ? (
                                <MediaSunburst
                                  Fixed={Fixed}
                                  ActiveFilters={ActiveFilters}
                                  Brand={Analyse.brand}
                                  Timeframe={timeframe}
                                  StartDate={DefaultStartDate}
                                  EndDate={DefaultEndDate}
                                  Kpi={Analyse.KPI}
                                  setLoader={setLoader}
                                  // Downloadchart={DownloadSpendschart}
                                  // setDownloadchart={setDownloadSpendschart}
                                  MediaSunburstData={MediaSunburstData}
                                  name={"Spends"}
                                  height={SpendsHeight}
                                  DecimalPoint={DecimalPoint}
                                  MediaPerc={MediaPerc}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div
                              className="sunburst-chart-height"
                              ref={refSales}
                            >
                              {SunburstData.length > 0 ? (
                                <Sunburst
                                  Fixed={Fixed}
                                  ActiveFilters={ActiveFilters}
                                  Brand={Analyse.brand}
                                  Timeframe={timeframe}
                                  StartDate={DefaultStartDate}
                                  EndDate={DefaultEndDate}
                                  Kpi={Analyse.KPI}
                                  setLoader={setLoader}
                                  // setDownloadchart={setDownloadSaleschart}
                                  // Downloadchart={DownloadSaleschart}
                                  SunburstData={SunburstData}
                                  name={"Sales"}
                                  height={SalesHeight}
                                  KpiPerc={KpiPerc}
                                />
                              ) : null}
                            </div>
                          </div>
                          {Legends.length > 0 ? (
                            <div className="col-sm-12 mt-0">
                              <div
                                className="row justify-content-center"
                                style={{
                                  height: "70px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                }}
                              >
                                {Legends.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="col-md-3 col-lg-2 text-center text-white mb-3 charts-legends"
                                    >
                                      <span
                                        style={{
                                          height: "16px",
                                          width: "16px",
                                          display: "inline-block",
                                          marginRight: "5px",
                                          backgroundColor: item.Color,
                                        }}
                                      ></span>
                                      {item.Name}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {parseInt(ShowSummary) === 1 ? (
                <div className="col-xl-3">
                  <div
                    className="card card-min-height"
                    style={{ height: ChartHeight + "px" }}
                  >
                    <div className="card-body brand-card">
                      <div className="row align-items-center justify-content-sm-between">
                        <h3 className="data-spends my-2">Summary</h3>
                        <div className="spends-list">
                          <ol>
                            {Summary.map((item, i) => {
                              return (
                                <li key={i} className="spends-list-li">
                                  <p>{item}</p>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row custom-note">
              <div className="col-sm-1">
                {FooterNotes !== "" ? (
                  <>
                    <i
                      className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                      id="clickable"
                    ></i>
                    <Tooltip anchorSelect="#clickable" clickable>
                      {/* <div className="footer-notes">{FooterNotes}</div> */}
                      <div
                        className="footer-notes"
                        dangerouslySetInnerHTML={{ __html: FooterNotes }}
                      ></div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
