import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";

const CheckboxDropdown = ({
  name,
  selectedOptions,
  selectedOther,
  setSelectedOptions,
  jsonData,
  setFetchCall,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const jsonData = [
  //     "Option 1",
  //     "Option 2",
  //     "Option 3",
  //     "Option 4",
  //     "Option 5",
  //     "Option 6",
  //   ];

  //   const [selectedOptions, setSelectedOptions] = useState([]);

  const dropdownRef = useRef(null);

  console.log("selected option", selectedOptions);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    if (selectedOptions.includes(value)) {
      if (selectedOptions.length === 1 && selectedOther.length == 0) {
        toast.error("Atlest select one KPI");
        return null;
      }
      setFetchCall(true);
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setFetchCall(true);
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <div
        onClick={toggleDropdown}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          border: "1px solid #3e5056",
          padding: "2px",
          borderRadius: "4px",
          width: "150px",
          justifyContent: "space-between",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ color: "white", padding: "0.1rem" }}> {name} </div>
          <div style={{ padding: "0.1rem" }}>{isOpen ? "▲" : "▼"}</div>
        </div>
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            backgroundColor: "#1C2427",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            zIndex: 1,
            marginTop: "5px",
            overflowY: "scroll",
            maxHeight: "200px",
            width: "150px", // Added width for consistency
            color: "white",
            padding: "2px",

            border: "1px solid #3e5056",
          }}
        >
          {jsonData.length === 0
            ? <p>No KPI</p>
            : jsonData.map((option) => (
                <div
                  key={option.value}
                  style={{ display: "block", padding: ".3rem" }}
                >
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => handleOptionClick(option.value)}
                    style={{ marginRight: "5px" }}
                  />
                  {option.value}
                </div>
              ))}
          {/* } */}
        </div>
      )}
    </div>
  );
};

export default CheckboxDropdown;
