import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// const radarData = [
//   {
//     category: "3roses",
//     value: 42.1,
//     sliceSettings: {
//       fill: "#d6ff41",
//     },
//     subData: [
//       {
//         category: "3roses-meta",
//         value: 0.29000000000000004,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "3roses-ooh",
//         value: 1.95,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "3roses-ott",
//         value: 2.2,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "3roses-tv",
//         value: 31.02,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "3roses-yt",
//         value: 6.640000000000001,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//     ],
//   },
//   {
//     category: "lipton",
//     value: 107.35000000000002,
//     sliceSettings: {
//       fill: "#d6ff41",
//     },
//     subData: [
//       {
//         category: "lipton-meta",
//         value: 0.41000000000000003,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "lipton-ooh",
//         value: 2.3899999999999997,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "lipton-ott",
//         value: 1.1400000000000001,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "lipton-tv",
//         value: 89.57000000000002,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "lipton-yt",
//         value: 13.84,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//     ],
//   },
//   {
//     category: "redlabel",
//     value: 124.32999999999998,
//     color: "#FF0000",
//     sliceSettings: {
//       fill: "#d6ff41",
//     },
//     subData: [
//       {
//         category: "redlabel-meta",
//         value: 0.0,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "redlabel-ooh",
//         value: 1.22,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "redlabel-ott",
//         value: 2.8000000000000003,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "redlabel-tv",
//         value: 97.14999999999999,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "redlabel-yt",
//         value: 23.16,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//     ],
//   },
//   {
//     category: "taaza",
//     value: 54.349999999999994,
//     sliceSettings: {
//       fill: "#d6ff41",
//     },
//     subData: [
//       {
//         category: "taaza-tv",
//         value: 54.349999999999994,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "taaza-ooh",
//         value: 0.0,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "taaza-ott",
//         value: 0.0,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "taaza-yt",
//         value: 0.0,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "taaza-meta",
//         value: 0.0,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//     ],
//   },
//   {
//     category: "tajmahal",
//     value: 81.01000000000002,
//     sliceSettings: {
//       fill: "#d6ff41",
//     },
//     subData: [
//       {
//         category: "tajmahal-meta",
//         value: 0.4,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "tajmahal-ooh",
//         value: 0.8300000000000001,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "tajmahal-ott",
//         value: 1.7000000000000002,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "tajmahal-tv",
//         value: 72.41000000000001,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//       {
//         category: "tajmahal-yt",
//         value: 5.669999999999999,
//         sliceSettings: {
//           fill: "#d6ff41",
//         },
//       },
//     ],
//   },
// ];


// const radarData = [
//   {
//       "category": "3roses",
//       "value": 0,
//       "sliceSettings": {
//           "fill": "#FF1493"
//       },
//       "subData": [],
//       "units": "Cr"
//   },
//   {
//       "category": "lipton",
//       "value": 9.65,
//       "sliceSettings": {
//           "fill": "#FFFF00"
//       },
//       "subData": [
//           {
//               "category": "meta",
//               "value": 0.2,
//               "sliceSettings": {
//                   "fill": "#fa5d28"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "ooh",
//               "value": 0.4,
//               "sliceSettings": {
//                   "fill": "#ec4094"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "ott",
//               "value": 0.33,
//               "sliceSettings": {
//                   "fill": "#39c5f8"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "tv",
//               "value": 7.3,
//               "sliceSettings": {
//                   "fill": "#d6ff41"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "yt",
//               "value": 1.42,
//               "sliceSettings": {
//                   "fill": "#1cf347"
//               },
//               "units": "Cr"
//           }
//       ],
//       "units": "Cr"
//   },
//   {
//       "category": "redlabel",
//       "value": 6.2,
//       "sliceSettings": {
//           "fill": "#FF0000"
//       },
//       "subData": [
//           {
//               "category": "ooh",
//               "value": 0.02,
//               "sliceSettings": {
//                   "fill": "#ec4094"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "ott",
//               "value": 0.28,
//               "sliceSettings": {
//                   "fill": "#39c5f8"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "tv",
//               "value": 2.61,
//               "sliceSettings": {
//                   "fill": "#d6ff41"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "yt",
//               "value": 3.29,
//               "sliceSettings": {
//                   "fill": "#1cf347"
//               },
//               "units": "Cr"
//           }
//       ],
//       "units": "Cr"
//   },
//   {
//       "category": "taaza",
//       "value": 3.16,
//       "sliceSettings": {
//           "fill": "#1fd655"
//       },
//       "subData": [
//           {
//               "category": "tv",
//               "value": 3.16,
//               "sliceSettings": {
//                   "fill": "#d6ff41"
//               },
//               "units": "Cr"
//           }
//       ],
//       "units": "Cr"
//   },
//   {
//       "category": "tajmahal",
//       "value": 5.15,
//       "sliceSettings": {
//           "fill": "#0000ff"
//       },
//       "subData": [
//           {
//               "category": "ooh",
//               "value": 0.01,
//               "sliceSettings": {
//                   "fill": "#ec4094"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "ott",
//               "value": 0.28,
//               "sliceSettings": {
//                   "fill": "#39c5f8"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "tv",
//               "value": 3.56,
//               "sliceSettings": {
//                   "fill": "#d6ff41"
//               },
//               "units": "Cr"
//           },
//           {
//               "category": "yt",
//               "value": 1.3,
//               "sliceSettings": {
//                   "fill": "#1cf347"
//               },
//               "units": "Cr"
//           }
//       ],
//       "units": "Cr"
//   }
// ]


const PieChart = ({  radarData }) => {
  useEffect(() => {
    // Create root element
    const root = am5.Root.new("chartdivpie");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);
    root._logo.dispose();

    const container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layout: root.horizontalLayout,
      })
    );

    // Create main chart
    const chart = container.children.push(
      am5percent.PieChart.new(root, {
        tooltip: am5.Tooltip.new(root, {
          getFillFromSprite: false,
          background: am5.Rectangle.new(root, {
            fill: am5.color(0x000000), // Set the background color to black
          }),
        }),
      })
    );

    

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
        radius: am5.percent(89),
      })
    );

    // Adjust label placement to avoid overlap
    series.labels.template.setAll({
      // textType: "circular",
      text: "{category}",
      radius: -50,
      fill: am5.color("#000000"),
      maxWidth: 100, // Adjust this value as needed
      oversizedBehavior: "wrap",
      centerX: am5.p50,
      centerY: am5.p50,
      inside: false, // Keep labels inside the slices
    });

    series.labels.template.setup = function(label, dataItem) {
      label.events.on("dataitemchanged", function(ev) {
        label.set("background", am5.RoundedRectangle.new(root, {
          // fill: ev.target.dataItem.get("slice").get("fill")
          fill: am5.color("#000000"),
        }));
      });
    }

    
    // Hide labels for very small slices
    series.labels.template.adapters.add("visible", (visible, target) => {
      return target.dataItem.get("valuePercentTotal") >= 2; // Hide labels for slices less than 2%
    });

    series.ticks.template.set("visible", false);
    series.slices.template.set("toggleKey", "none");

    // Add events
    series.slices.template.events.on("click", (e) => {
      selectSlice(e.target);
    });

    // Create sub chart
    const subChart = container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(50),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    series.slices.template.set(
      "tooltipText",
      "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value} {units})"
    );

    // Create sub series
    const subSeries = subChart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        tooltip: am5.Tooltip.new(root, {
          // Add this tooltip configuration
          getFillFromSprite: false,
          background: am5.Rectangle.new(root, {
            fill: am5.color(0x000000), // Set the background color to black
          }),
        }),
      })
    );

    series.labels.template.setAll({
      visible: true,
      fill: am5.color("#ffffff"),
      text: "{category}",
      // radius: am5.percent(50),
    });

    subSeries.labels.template.setAll({
      visible: true,
      fill: am5.color("#ffffff"),
      text: "{category}",
      // radius: am5.percent(50),
    });

    subSeries.ticks.template.setAll({
      visible: true, // Hide sub-series ticks
      stroke: am5.color("#ffffff"),
    });

    let selectedSlice;

    series.on("startAngle", () => {
      updateLines();
    });

    container.events.on("boundschanged", () => {
      root.events.once("frameended", () => {
        updateLines();
      });
    });

    const updateLines = () => {
      if (selectedSlice) {
        const startAngle = selectedSlice.get("startAngle");
        const arc = selectedSlice.get("arc");
        const radius = selectedSlice.get("radius");

        const x00 = radius * am5.math.cos(startAngle);
        const y00 = radius * am5.math.sin(startAngle);

        const x10 = radius * am5.math.cos(startAngle + arc);
        const y10 = radius * am5.math.sin(startAngle + arc);

        const subRadius = subSeries.slices.getIndex(0).get("radius");
        const x01 = 0;
        const y01 = -subRadius;

        const x11 = 0;
        const y11 = subRadius;

        const point00 = series.toGlobal({ x: x00, y: y00 });
        const point10 = series.toGlobal({ x: x10, y: y10 });

        const point01 = subSeries.toGlobal({ x: x01, y: y01 });
        const point11 = subSeries.toGlobal({ x: x11, y: y11 });

        line0.set("points", [point00, point01]);
        line1.set("points", [point10, point11]);
      }
    };

    // lines
    const line0 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: am5.color(0xffffff),
        strokeDasharray: [2, 2],
      })
    );
    const line1 = container.children.push(
      am5.Line.new(root, {
        position: "absolute",
        stroke: am5.color(0xffffff),
        strokeDasharray: [2, 2],
      })
    );

    // Set data
    series.slices.template.setAll({
      templateField: "sliceSettings",
    });

    series.slices.template.setAll({
      stroke: am5.color("#808080"), // Set stroke color to white
      strokeWidth: 1, // Set stroke width
    });

    subSeries.slices.template.setAll({
      stroke: am5.color("#808080"), // Set stroke color to white
      strokeWidth: 1, // Set stroke width
    });

    // series.slices.template.setAll({
    //   stroke: null,
    //   strokeWidth: 0,
    // });

    // subSeries.slices.template.setAll({
    //   stroke: null,
    //   strokeWidth: 0,
    // });

    subSeries.slices.template.setAll({
      templateField: "sliceSettings",
    });
    series.data.setAll(radarData);

    subSeries.slices.template.set(
      "tooltipText",
      "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value} {units})"
    );

    const selectSlice = (slice) => {
      selectedSlice = slice;
      const dataItem = slice.dataItem;
      const dataContext = dataItem.dataContext;

      if (dataContext && dataContext.subData) {
        subSeries.data.setAll(dataContext.subData);
      } else {
        subSeries.data.clear();
      }

      const middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
      const firstAngle = series.dataItems[0].get("slice").get("startAngle");

      series.animate({
        key: "startAngle",
        to: firstAngle - middleAngle,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic),
      });
      series.animate({
        key: "endAngle",
        to: firstAngle - middleAngle + 360,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic),
      });
    };

    container.appear(1000, 10);

    series.events.on("datavalidated", () => {
      selectSlice(series.slices.getIndex(0));
    });

    // Set colors for subSeries slices

    // Define colors for series and subseries slices

    return () => {
      root.dispose();
    };
  }, [radarData]);

  return (
    <div
      id="chartdivpie"
      style={{ width: "100%", height: "500px", backgroundColor: "#1c2427" }}
    ></div>
  );
};

export default PieChart;
