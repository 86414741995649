// Base URL for the API
// const BASE_URL = 'http://prajval.skewb.ai:8001'; // Replace with your actual API URL

import Axios from "../Axios";
// Function to fetch data from the API
export const fetchData = async (query, tabId) => {
  // console.log("fetch call", query, "  ", tabId);
  const formData = new FormData();

  if (tabId) {
    const payload = {
      tab_id: tabId,
      question: query,
    };

    try {
      console.log("FormData ", formData);

      const response = await Axios.post("gpt_cascader/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("fetchData ", response.data.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return error;
    }
  }
};
