import { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"; // Import the theme

const ZRadar = ({
  radarChartData,
  setClickedCatName,
  setClickedSeriesName,
}) => {
  useEffect(() => {
    const data = radarChartData;

    // Create root element
    const root = am5.Root.new("chartdiv");

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    root._logo.dispose();

    // Create chart
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        innerRadius: am5.percent(20),
        startAngle: 0,
        endAngle: 360,
        tooltip: am5.Tooltip.new(root, {
          getFillFromSprite: false,
          autoTextColor: false,
          background: am5.Rectangle.new(root, {
            fill: am5.color(0x000000), // Set the background color to black
          }),
        }),
      })
    );

    // chart.zoomOutButton.set("forceHidden", true);

    //Add cursor
    const cursor = chart.set(
      "cursor",
      am5radar.RadarCursor.new(root, {
        behavior: "zoomX",
      })
    );

    chart.zoomOutButton.setAll({
      background: am5.RoundedRectangle.new(root, {
        fill: am5.color("#d6ff41"), // Set background color to yellow
      }),

      icon: am5.Graphics.new(root, {
        stroke: am5.color(0x000000), // Set icon color to black
        svgPath: "M5 15 H20", // Custom SVG path for minus icon (as an example)
      }),
    });

    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // const yAxis = chart.yAxes.push(
    //   am5xy.ValueAxis.new(root, {
    //     renderer: am5radar.AxisRendererRadial.new(root, {
    //       forceHidden: true,
    //     }),
    //   })
    // );

    const yRenderer = am5radar.AxisRendererRadial.new(root, {});
    yRenderer.grid.template.setAll({
      stroke: am5.color("#FFFFFF"),
      fill: am5.color("#000000"),
      strokeWidth: 2,
    });
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
        forceHidden: true,
      })
    );

    // Create axes
    // Create axes

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5radar.AxisRendererCircular.new(root, {
          minGridDistance: 1,
        }),
      })
    );

    // Set color of category name labels to red and adjust other properties
    xAxis.get("renderer").labels.template.setAll({
      fontSize: 15,
      cursorOverStyle: "pointer",
      fill: am5.color("#ffffff"), // Set label color to red
      autoWrap: true,
    });

    var xRenderer = am5radar.AxisRendererCircular.new(root, {});
    xRenderer.labels.template.setAll({
      radius: 10,
    });

    xRenderer.grid.template.setAll({
      stroke: am5.color("#FFFFFF"), // Set circular grid lines to white
    });

    // Event binding using 'click' event
    chart.seriesContainer.children.each((child) => {
      if (child.get("type") === "AxisLabel") {
        child.events.on("click", (ev) => {
          console.log("Label clicked:", ev.target.text);
        });
      }
    });

    // Function to create series dynamically
    function createSeries(name, valueField, colorField, unit) {
      const series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: valueField,
          categoryXField: "category",
        })
      );

      series.columns.template.setAll({
        tooltipText: `{name}: {valueY} ${unit}`,
        fill: am5.color(data[0][colorField]),
        stroke: am5.color(data[0][colorField]),
      });
      return series;
    }

    // Determine series fields excluding 'category'
    const seriesFields = Object.keys(data[0])?.filter(
      (key) => key !== "category"
    );

    // Create series for each field
    // Create series for each field
    // Create series for each field
    seriesFields.forEach((field) => {
      // console.log("field: ", field);
      const seriesName = data[0][`${field}_name`]; // Get the series name from the data
      const colorField = `color${field.match(/\d+/)[0]}`; // Get the corresponding color field
      const unitField = data[0][`units${field.match(/\d+/)[0]}`]; // Get the corresponding color field

      // Add click event listener to columns
      // Add click event listener to columns

      const newSeries = createSeries(seriesName, field, colorField, unitField);

      newSeries.columns.template.events.once("click", function (ev) {
        const category = ev.target.dataItem.dataContext.category;
        const value = ev.target.dataItem.dataContext[field];
        const seriesName = ev.target.dataItem.dataContext[`${field}_name`]; // Get the series name corresponding to the clicked series
        console.log(
          "category name:",
          category,
          ", series name:",
          seriesName,
          ", value:",
          value
        );
        setClickedCatName(category);
        setClickedSeriesName(seriesName);

        // Change the color of the category name label to red
        ev?.target?.dataItem?.label?.set("fill", "red");

        // Modify the tooltip content for the click event
        ev.target.dataItem.tooltipText = `${seriesName}: {${field}}`; // Customize tooltip text here
        // Redraw the series to update the tooltip
        console.log("");
        ev?.target?.dataItem?.series?.invalidate();
      });
    });

    // Set data
    xAxis.data.setAll(data);
    chart.series.each((series) => {
      series.data.setAll(data);
    });

    // Initial animation
    chart.appear(1000, 100);

    // Clean up function
    return () => {
      root.dispose();
    };
  }, [radarChartData]);

  return (
    <div
      id="chartdiv"
      style={{ width: "100%", height: "500px", backgroundColor: "#1c2427" }}
    ></div>
  );
};

export default ZRadar;
