import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import CustomEvents from "highcharts-custom-events";
import axiosChartDownload from "../../axiosChartDownload";
import { toast } from "react-toastify";
import domtoimage from "dom-to-image";

HighchartsSunburst(Highcharts);
CustomEvents(Highcharts);

// Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
//   var path = [
//     // Arrow stem
//     "M",
//     x + w * 0.5,
//     y,
//     "L",
//     x + w * 0.5,
//     y + h * 0.7,
//     // Arrow head
//     "M",
//     x + w * 0.3,
//     y + h * 0.5,
//     "L",
//     x + w * 0.5,
//     y + h * 0.7,
//     "L",
//     x + w * 0.7,
//     y + h * 0.5,
//     // Box
//     "M",
//     x,
//     y + h * 0.9,
//     "L",
//     x,
//     y + h,
//     "L",
//     x + w,
//     y + h,
//     "L",
//     x + w,
//     y + h * 0.9,
//   ];
//   return path;
// };

const MediaSunburst = (props) => {
  const refChart = useRef();

  // useEffect(() => {
  //   if (props.Downloadchart) {
  //       const chartContainer = document.getElementById("media_sunburst_chart");
  //       domtoimage
  //         .toPng(chartContainer)
  //         .then(function (dataUrl) {
  //           var string = "";
  //           for (var i = 0; i < props.Fixed.length; i++) {
  //             string += props.Fixed[i].id;
  //             if (props.ActiveFilters.length > 0) {
  //               string += ",";
  //             }
  //           }

  //           for (var j = 0; j < props.ActiveFilters.length; j++) {
  //             string += props.ActiveFilters[j].name;
  //             if (props.ActiveFilters.length - 1 !== j) {
  //               string += ","
  //             }
  //           }

  //           props.setLoader(true);
  //           axiosChartDownload.post("download_chart/create-image/",
  //             {
  //               "title": "Business Landscape : Spends",
  //               "filter": props.Brand,
  //               "hierarchy": string,
  //               "timeframe": props.Timeframe,
  //               "start_date":props.StartDate,
  //               "end_date": props.EndDate,
  //               "base64_image": dataUrl.split(",")[1],
  //               theme:localStorage.getItem("CurrentTheme")
  //             }

  //           ).then((response) => {
  //             if (response.data.error === 1) {
  //               toast.error(response.data.erroMsg);
  //               props.setLoader(false);
  //             } else {
  //               var link = document.createElement("a");
  //               link.target = "_blank";
  //               link.href = response.data.data.combined_graph_url;
  //               document.body.appendChild(link);
  //               link.click();
  //               document.body.removeChild(link);
  //               props.setLoader(false);
  //             }
  //           }).catch((data) => {
  //             props.setLoader(false);
  //           });
  //         })
  //         .catch(function (error) {
  //           props.setLoader(false)
  //           console.error("Error while generating chart image:", error);
  //         });
  //     props.setDownloadchart(false)
  //   }
  // }, [props])

  let RootNade = "";
  let options = {
    chart: {
      backgroundColor: "rgba(0,0,0,0)",
      // backgroundColor: localStorage.getItem('CurrentTheme') === "Dark" ? "#1c2427" : "",
      height: props.height,
      className: "highchart-class",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },

    series: [
      {
        type: "sunburst",
        data: props.MediaSunburstData,
        name: props.name,
        allowDrillToNode: true,
        allowTraversingTree: true,
        // allowPointSelect: true,
        cursor: "pointer",
        point: {
          events: {
            click: function (e) {
              // console.log(RootNade)
              // console.log(e.point.id)
              var series = refChart.current.chart.series[0];
              if (RootNade === e.point.id) {
                RootNade = e.point.parent;
                series.setRootNode(e.point.node.parentNode.id, true, {
                  trigger: "click",
                });
              } else {
                RootNade = e.point.id;
                series.setRootNode(e.point.id, true, { trigger: "click" });
              }
              // console.log(e.point)
              // return
              // if (e.point.drillId === e.point.id) {
              //   console.log(e.point.drillId)
              //   console.log(e.point.id)

              //   series.onClickDrillToNode({ point: e.point })
              //   // console.log(series.points)
              //   // for (var i = 0; i < series.points.length; i++) {
              //   //   if (e.point.drillId === series.points[i].drillId) {
              //   //     series.onClickDrillToNode({ point: series.points[i] })
              //   //     break
              //   //   }
              //   // }
              //   console.log('if')
              // } else {
              //   console.log('else')
              //   // for (var i = 0; i < series.points.length; i++) {
              //   //     if (e.point.drillId === series.points[i].id) {
              //   //       series.onClickDrillToNode({ point: series.points[i] })
              //   //       break
              //   //     }
              //   //   }

              // }
            },
          },
        },
        dataLabels: {
          format: "{point.name} ({point.value})",
          filter: { property: "innerArcLength", operator: ">", value: 16 },
          style: {
            textShadow: false,
            textOutline: null,
            fontStyle: "normal",
            color: "contrast",
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Inter",
            lineHeight: "16px",
          },

          // fontSize: "12px",
          //   fontFamily: "Inter",
          //   fontStyle: "normal",
          //   fontWeight: "600",
          //   lineHeight: "16px"
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: { property: "outerArcLength", operator: ">", value: 64 },
            },
            colorByPoint: true,
          },
          // { level: 2, colorVariation: { key: "brightness", to: -0.3 } },
          // { level: 3, colorVariation: { key: "brightness", to: -0.3 } },
        ],
      },
    ],
    tooltip: {
      // headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;">',
      // pointFormat: '{point.name}:{point.value:.1f}',
      // footerFormat: "</div>",
      formatter: function () {
        var perc;
        if ("point" in this) {
          if (props.MediaPerc === 1) {
            perc =
              (
                (this.point.node.val / this.point.node.parentNode.val) *
                100
              ).toFixed(1) + "%";
          } else {
            perc = "";
          }
          return (
            this.point.name +
            " (" +
            this.point.value.toFixed(props.DecimalPoint) +
            ") " +
            perc
          );
        } else {
        }
      },
      shared: true,
      useHTML: true,
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
    
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: "highcharts-contextbutton",
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
        },
      },
    },
    // breadcrumbs: {
    //   enabled: true,
    //   floating: false,
    //   format: '{point.name}',
    //   style: {
    //     color: '#FFFFFF',
    //     fontSize: '12px',
    //   },
    //   buttonTheme: {
    //     fill: '#f7f7f7',
    //     padding: 8,
    //     stroke: '#d6ff41',
    //     'stroke-width': 1,
    //     style: {
    //       color: '#d6ff41', // Ensuring text color contrast
    //     },
    //   },
    // }
    
  };
  return (
    <div id="media_sunburst_chart" style={{border: "none"}}>
      <HighchartsReact
        ref={refChart}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default MediaSunburst;
